import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {APIURL} from '../services/http/api';
import {HttpService} from '../services/http/http.service';
import {HandleAPIParams} from '../helpers/HandleAPIParams';
import {CourseType} from '../Screens/Courses/interfaces/ICourses';

export type categories = {
  id: number;
  name: string;
};
export type data = {
  data: {[key: string]: any};
  load: boolean | null;
};

export interface CoursesState {
  allCourses: CourseType[];
  page: number;
  lastPage: number;
  loading: boolean;
  categories: categories[];
  loadingCategories: boolean;
}

export const insertCourseList = createAsyncThunk(
  'courses/getCourses',
  async ({data}: data) => {
    const http = new HttpService();
    const api = APIURL.courses.getCoursesList;
    let finalApi = HandleAPIParams(api + '?', data);
    try {
      const [err, resp] = await http.get(finalApi);
      return resp;
    } catch (error) {}
  },
);
export const insertCategoryList = createAsyncThunk(
  'categories/getCategories',
  async () => {
    const http = new HttpService();
    const api = APIURL.courses.getCategories;
    try {
      const [err, resp] = await http.get(api);
      return resp;
    } catch (error) {}
  },
);

const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    allCourses: [],
    page: 1,
    lastPage: 1,
    loading: false,
    categories: [],
    loadingCategories: false,
  } as CoursesState,
  reducers: {},
  extraReducers: {
    [`${insertCourseList.pending}`]: (
      state: CoursesState,
      action: PayloadAction<any>,
    ) => {
      state.loading = true;
    },
    [`${insertCourseList.fulfilled}`]: (
      state: CoursesState,
      action: PayloadAction<any>,
    ) => {
      state.allCourses = action.payload.data.data;
      state.page = action.payload.data.meta.current_page;
      state.lastPage = action.payload.data.meta.last_page;
      state.loading = false;
    },
    [`${insertCourseList.rejected}`]: (
      state: CoursesState,
      action: PayloadAction<any>,
    ) => {
      state.loading = false;
    },
    [`${insertCategoryList.pending}`]: (
      state: CoursesState,
      action: PayloadAction<any>,
    ) => {
      state.loadingCategories = true;
    },
    [`${insertCategoryList.fulfilled}`]: (
      state: CoursesState,
      action: PayloadAction<any>,
    ) => {
      state.categories = action.payload.data.data;
      state.loadingCategories = false;
    },
    [`${insertCategoryList.rejected}`]: (
      state: CoursesState,
      action: PayloadAction<any>,
    ) => {
      state.loadingCategories = false;
    },
  },
});

export default coursesSlice.reducer;

import {Col, Container, Row} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/SignUpEmail.module.css';
import {useState} from 'react';
import {sendOtp} from './Auth.service';
import {useNavigate} from 'react-router-dom';

const SignUpEmail = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [errorMail, setErrorMail] = useState<string>('');

  const [verifyAccountLink, setVerifyAccountLink] = useState<string>('/verify');

  const check = async () => {
    let y = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setErrorMail('');
    if (email == '') {
      setErrorMail('Please enter a valid email');
    } else if (y.test(email) == false) {
      setErrorMail('Please enter a valid email');
    } else {
      let data = new FormData();
      data.append('email', email);

      let result = await sendOtp(data);
      console.log('result.response', result.response);

      if (result.response.status) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        const type = urlParams.get('type')
        const modelId = urlParams.get('model_id')
        const invitationId = urlParams.get('invitation_id')
        
        if(  type && modelId ){
          if( type == 'Initiative' ){ 
            navigate(`/verify?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`, {state: {email: email}});
          }
          else if( type == 'InitiativePackage' ){
            navigate(`/verify?type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`, {state: {email: email}});
          }
          
        }
        else{
          navigate('/verify', {state: {email: email}});
        }

        
      } else {
        setErrorMail(result.response.msg);
      }
    }
  };
  return (
    <div>
      <Container className={classes.FormContainer}>
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={6} >
            <div className={classes.FormPadding}>
              {/* <h3 className={`fontWeight-bold ${classes.HeaderTitle} `}>Sign Up</h3>
                          <p className={`${classes.HeaderPargraph}`}>Enter Your Email Address To Confirm</p> */}

              <div className="mt-3 mb-3">
                <label className="mb-2">Please enter your email</label>
                <input
                  type="email"
                  className={`form-control m-0 ${classes.Input}`}
                  required
                  placeholder="Email"
                  onChange={e => setEmail(e.target.value)}
                />
                <div className={classes.error}>{errorMail}</div>
              </div>

              <div className="mt-4 mb-2">
                <MainButton text="Sign Up" onClick={check} />
              </div>
            </div>
          </Col>

          <Col xs={12} lg={6}>
            <img
              src="/login.png"
              alt="img"
              className={classes.SignUpBackground}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUpEmail;

import './style/CoursesList.css';
import CourseItem from './components/CourseItem';
import {Fragment} from 'react';
import Pagination from '../../UI/pagination/ItemsPagination';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {CourseListProps} from './interfaces/ICourses';
import {useAppSelector} from '../../hooks/hooks';
import SearchInput from '../../components/search-input/SearchInput';
import CourseCard from '../../UI/CourseCard';
import { Col, Container, Row } from 'react-bootstrap';
import ListCourseCard from '../../UI/ListCourseCard';

const CoursesList = ({setPage, page, submitTitle}: CourseListProps) => {
  const course = useAppSelector(state => state.courses);

  
  const handleSearch = (searchTerm: any) => {
    submitTitle(searchTerm);
    setPage(1);
  };
  

  return (
    <Fragment>
      {/*
      <div className="row courses-top-head mb-md-3 justify-content-between align-items-center">
        <SearchInput onSearch={handleSearch} />
      </div>
      */}
      {course.loading ? (
        <LoadingSpinner />
      ) : (
        <div className="">
          
          <Row>

          
          {course.allCourses.length > 0 ? (
            course.allCourses.map(course => {
              //return <CourseItem course={course} />;
              return ( <Col lg="4" className='card-item-container'> 
                      <ListCourseCard course={course} />
                    </Col>
                    )
            })
          ) : (
            <div className='text-center'>
              <img src="/logo.png" alt="img" width={70} />
              <h3 className="text-center mt-3">Sorry, there is no data found.</h3>
            </div>
            
          )}
          </Row>
          
        </div>
      )}
      {!course.loading && course.allCourses.length != 0 && (
        <div className="pagination-container">
          <Pagination
            pages={course.lastPage}
            current={page}
            changePage={page => {
              setPage(page);
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default CoursesList;

import {insertCourseList} from '../../store/coursesSlice';
import store from '../../store';
import {GetCoursesListOptions} from './interfaces/ICourses.service';
import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const getCoursesList = async ({
  learnType,
  page,
  load,
  language,
  title,
  categories,
  courseProvider,
  price,
  status,
}: GetCoursesListOptions): Promise<void> => {
  let data: {[key: string]: any} = {};
  data.page = page;
  if (learnType?.length === 1) {
    data.is_live = learnType.includes('live') ? 1 : 0;
  }
  language &&
    language.map((i, index) => {
      data[`language_id[${index}]`] = i;
    });

  if (title) {
    data.title = title;
  }
  if (status) {
    data.status = status;
  }

  categories &&
    categories.map((i, index) => {
      data[`category_id[${index}]`] = i;
    });
  courseProvider &&
    courseProvider.map(i => {
      i === 0
        ? (data.by_nurabi = 1)
        : i === 1
        ? (data.by_consultant = 1)
        : i === 2
        ? (data.by_teacher = 1)
        : (data.by_organization = 1);
    });

  if (price?.length === 1) {
    data.free = price.includes('free') ? 1 : 0;
  }
  store.dispatch(insertCourseList({data, load}));
};

export const getCourseDetails = async (id: number | string) => {
  const api = APIURL.courses.getCourseDetails;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getFeedBacks = async (id: number | string) => {
  const api = APIURL.courses.getCourseFeedBacks;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const enrollFree = async (data: FormData) => {
  const api = APIURL.courses.enrollFree;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const submitPracticalTask = async (data: any) => {
  const api = APIURL.courses.submitPracticalTask;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const addCart = async (data: any) => {
  const api = APIURL.cart.add;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const finishLesson = async (data: FormData) => {
  const api = APIURL.courses.finishLesson;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const sendReview = async (data: FormData) => {
  const api = APIURL.courses.sendReview;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};


export const seenCongratsScreen = async (id:any) => {
  const api = APIURL.courses.seenCongrats;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.patch(finalApi);

  return {error: err, response: resp?.data};
};

export const getQuestionsReviewCourse = async (id:any) => {
  const api = APIURL.courses.getQuestionsReviewCourse;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const sendQestionsReview = async (data: FormData) => {
  const api = APIURL.courses.sendQestionsReview;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import './NavigationHeaderV2.css';
import classes from './NavigationHeaderV2.module.css';
import {FaCartArrowDown, FaEye, FaFacebookMessenger, FaGlobe} from 'react-icons/fa';
import {useAppSelector} from '../../hooks/hooks';
import {isUserLoggedIn, getUserAvatar} from '../../auth/utiles';
import {useEffect, useState} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {getLanguage, setLanguage} from './getLang';
import MainButton from '../../UI/main-button/MainButton';
import Notifications from '../../Screens/Notifications/Notifications';
import { colors } from '../../constants/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsThreeDots } from "react-icons/bs";
import { Form, Modal } from 'react-bootstrap';
import loginClasses from '../../Screens/Auth/style/login.module.css'
import { resetPassword } from '../../Screens/Consultants/Consultants.service';

const NavigationHeaderV2 = () => {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const consStatus = useAppSelector(state => state.user.consStatus);
  const chatNumber = useAppSelector(state => state.user.notificationNumber);
  console.log('consStatus', consStatus);

  const Logged = isUserLoggedIn();
  const [isLoggedIn, setIsLoggedIn] = useState<string | null>(null);
  const [switchStatus, setSwitchStatus] = useState<string | null | undefined>(
    localStorage.getItem('switch') ?? '0',
  );
  const location = useLocation();
  const navigate = useNavigate();
  const isCons = localStorage.getItem('isCons');
  const email = localStorage.getItem('email');
  const hasPassword = localStorage.getItem('hasPassword');
  const is_social = localStorage.getItem('is_social');
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('')
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  

  useEffect(() => {
    getLanguage();
    setIsLoggedIn(Logged);
  }, []);

  

  const userAvatar = getUserAvatar();

  const cartLength = useAppSelector(state => state.cart.totalProducts);

  const queryParams = new URLSearchParams(location.search);
  
  

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  const handleDropdownToggle = (isOpen:any) => {
    setIsOpen(isOpen);
  };

  const handleDropdownSelect = (eventKey:any) => {
    setIsOpen(false);
  };

  const resetPasswordToRequestConsultant = async () => {
    

    if ( passwordInput == confirmPasswordInput ) {
      let data = new FormData();
      data.append('email', String(email));
      data.append('new_password', passwordInput);
      let result = await resetPassword(data);
      if (result?.response?.status) {
        setShowResetPassword(false)
        localStorage.setItem('hasPassword', '1');
        navigate('/user/consultation/request')
      }
    }else{
      
      toast.error('password and confirm password must be same')
    }
  };

  return (
    <>
    <Navbar expanded={expanded} expand="lg">
      <Toaster position="top-right" />
      <Container className={`NavContainer ${classes.NavBar}`}>
        {switchStatus == '1' && (
          <Navbar.Brand
            href="/consultants/availablilty"
            className={`mt-2 mb-2 mr-md-5 ml-md-5 ${classes.Logo}`}>
            <img src="/logo.png" alt="img" />
          </Navbar.Brand>
        )}
        {switchStatus == '0' && (
          <Navbar.Brand
            href="/"
            className={`mt-2 mb-2 mr-md-5 ml-md-5 ${classes.Logo}`}>
            <img src="/logo.png" alt="img" />
          </Navbar.Brand>
        )}
        { isLoggedIn ? <div className='top-user-icons'><Notifications /></div> : '' }
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Dropdown className='top-user-icons' show={isOpen} onToggle={handleDropdownToggle} onSelect={handleDropdownSelect}>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
          <BsThreeDots size={35} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
          <Dropdown.Item eventKey="1">
            {isLoggedIn ? (
              <>
                {switchStatus == '0' && (
                  <NavLink
                  onClick={handleNavItemClick}
                    to="/cart"
                    className={`m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
                    style={{
                      background: '#eaedff',
                      borderRadius: '5px',
                      padding: '10px 12px 10px 10px',
                      position: 'relative',
                    }}>
                    {' '}
                    <span
                      className="cart-items-number"
                      style={{
                        background:colors.chamSecColor,
                        position: 'absolute',
                        color: '#fff',
                        top: '-10px',
                        right: '-5px',
                        padding: '0px 7px',
                        borderRadius: '50%',
                        fontSize: '.9rem',
                      }}>
                      {cartLength}
                    </span>{' '}
                    <FaCartArrowDown size={25} color="#0576e3" />{' '}
                  </NavLink>
                )}

                <NavLink
                  onClick={handleNavItemClick}
                  to="/chatlist"
                  className={`m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
                  style={{
                    background: '#eaedff',
                    borderRadius: '5px',
                    padding: '10px 12px 10px 10px',
                    position: 'relative',
                  }}>
                  {' '}
                  <span
                    className="cart-items-number"
                    style={{
                      background:colors.chamSecColor,
                      position: 'absolute',
                      color: '#fff',
                      top: '-10px',
                      right: '-5px',
                      padding: '0px 7px',
                      borderRadius: '50%',
                      fontSize: '.9rem',
                    }}>
                    {chatNumber}
                  </span>{' '}
                  {/*<FaFacebookMessenger size={25} color="#0576e3" />{' '}*/}
                  <img src="/chat-bubble.png" width={25} alt="chat" />
                </NavLink>

                

                <img
                  onClick={() => {
                    if (isCons !== 'false' && isCons !== null) {
                      if (switchStatus == '0') {
                        navigate('/show/user/profile');
                      } else {
                        navigate('/consultant/profile');
                      }
                    } else {
                      navigate('/show/user/profile');
                    }
                    handleNavItemClick()
                  }}
                  src={
                    userAvatar == 'null' ? `/icons/manHolder.png` : userAvatar!
                  }
                  alt="avatar"
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    padding: '2px',
                    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.75)',
                    marginLeft: '2rem',
                    cursor: 'pointer',
                  }}
                />
              </>
            ) : 
            <>
            <NavLink
              onClick={handleNavItemClick}
                to="/login"
                className={`login-register m-2 ${classes.MainNavHeaderLink}`}>
                Login
              </NavLink>
            
            <NavLink
              onClick={handleNavItemClick}
                to="/sign-up"
                className={`login-register-2 m-2 ${classes.MainNavHeaderLink}`}>
                Register
              </NavLink>
              </>
            }

          </Dropdown.Item>
            
          </Dropdown.Menu>
        </Dropdown>

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between">
          <Nav className="m-md-auto">

            {/*
            {switchStatus == '0' && (
            
              <NavLink
                onClick={handleNavItemClick}
                to="/user/initiatives/"
                className={({isActive}) => `m-2 ${classes.MainNavHeaderLink}`}>
                {t('Challenges')}
              </NavLink>
            )}
          
            {switchStatus == '0' && (
              <NavLink
                onClick={handleNavItemClick}
                to="/consultants/get-teachers"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Mentors')}
              </NavLink>
            )}
            */}  

            {/*
            {switchStatus == '0' && (
              <NavLink
                onClick={handleNavItemClick}
                to="/consultants/get-consultants"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Consultations')}
              </NavLink>
            )}
            */}

            
            {/*  
            {switchStatus == '0' && (
              <NavLink
                onClick={handleNavItemClick}
                to="/user/trackings/list/"
                className={({isActive}) => `m-2 ${classes.MainNavHeaderLink}`}>
                {t('Follow-up Systems')}
              </NavLink>
            )}
            */}

            {/* 
            {switchStatus == '0' && (
              <NavLink
                onClick={handleNavItemClick}
                to="/courses"
                className={({isActive}) => `m-2 ${classes.MainNavHeaderLink}`}>
                {t('Courses')}
              </NavLink>
            )}
            */}
            

            {/*
            {switchStatus == '0' && (
              <NavLink
                onClick={handleNavItemClick}
                to="/bank-questions"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Bank Questions')}
              </NavLink>
            )}
            */}

            {switchStatus == '1' && isLoggedIn && (
              <NavLink
                onClick={handleNavItemClick}
                to="/consultants/availablilty"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Schedule')}
              </NavLink>
            )}

            {switchStatus == '1' && isLoggedIn && (
              <NavLink
                onClick={handleNavItemClick}
                to="/consultant/booked-sessions"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Booked Sessions')}
              </NavLink>
            )}

            {switchStatus == '1' && isLoggedIn && (
              <NavLink
                onClick={handleNavItemClick}
                to="/private/sessions/list"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Private Sessions')}
              </NavLink>
            )}
          </Nav>

          <Nav className="ml-md-auto">
            {((isCons !== 'false' && isCons !== null) ||
              consStatus == 'accepted') && (
              <img
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (switchStatus == '0') {
                    localStorage.setItem('switch', '1');
                    setSwitchStatus('1');
                    navigate('/consultants/availablilty');
                  } else {
                    localStorage.setItem('switch', '0');
                    setSwitchStatus('0');
                    navigate('/');
                  }
                }}
                className={`${classes.Switcher}`}
                src={`/icons/transfer.png`}
              />
            )}
            {consStatus == 'pending' && (
              <img
                style={{cursor: 'pointer'}}
                onClick={() => {
                  toast.error(t('reqPending'));
                }}
                className={`${classes.Switcher}`}
                src={`/icons/deadline.png`}
              />
            )}
            {(isCons !== 'true' || isCons == null || !isCons) &&
              isLoggedIn &&
              (consStatus == null || consStatus == 'rejected') && (
                <div
                onClick={ () => {
                  if( hasPassword == 'false' &&  is_social == '1' ){
                    setShowResetPassword(true)
                  }else{
                    handleNavItemClick()
                    navigate('/user/consultation/request')
                  }
                } }
                  
                  className={`join-link m-2 ${classes.MainNavHeaderLink}`}>
                  {t('join')}
                </div>
              )}

                  
            {isLoggedIn ? (
              <>
                {switchStatus == '0' && (
                  <NavLink
                    onClick={handleNavItemClick}
                    to="/cart"
                    className={`top-web-icon m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
                    style={{
                      background: '#eaedff',
                      borderRadius: '5px',
                      padding: '10px 12px 10px 10px',
                      position: 'relative',
                    }}>
                    {' '}
                    <span
                      className="cart-items-number"
                      style={{
                        background:colors.chamSecColor,
                        position: 'absolute',
                        color: '#fff',
                        top: '-10px',
                        right: '-5px',
                        padding: '0px 7px',
                        borderRadius: '50%',
                        fontSize: '.9rem',
                      }}>
                      {cartLength}
                    </span>{' '}
                    <FaCartArrowDown size={25} color="#0576e3" />{' '}
                  </NavLink>
                )}

                <NavLink
                  onClick={handleNavItemClick}
                  to="/chatlist"
                  className={`top-web-icon m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
                  style={{
                    background: '#eaedff',
                    borderRadius: '5px',
                    padding: '10px 12px 10px 10px',
                    position: 'relative',
                  }}>
                  {' '}
                  <span
                    className="cart-items-number"
                    style={{
                      background:colors.chamSecColor,
                      position: 'absolute',
                      color: '#fff',
                      top: '-10px',
                      right: '-5px',
                      padding: '0px 7px',
                      borderRadius: '50%',
                      fontSize: '.9rem',
                    }}>
                    {chatNumber}
                  </span>{' '}
                  {/*<FaFacebookMessenger size={25} color="#0576e3" />{' '}*/}
                  <img src="/chat-bubble.png" width={25} alt="chat" />
                </NavLink>

                <span className='top-web-icon'>
                  <Notifications />
                </span>

                <img
                  className='top-web-icon'
                  onClick={() => {
                    if (isCons !== 'false' && isCons !== null) {
                      if (switchStatus == '0') {
                        navigate('/show/user/profile');
                      } else {
                        navigate('/consultant/profile');
                      }
                    } else {
                      navigate('/show/user/profile');
                    }
                    handleNavItemClick()
                  }}
                  src={
                    userAvatar == 'null' ? `/icons/manHolder.png` : userAvatar!
                  }
                  alt="avatar"
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    padding: '2px',
                    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.75)',
                    marginLeft: '2rem',
                    cursor: 'pointer',
                  }}
                />
              </>
            ) : (
              <>
              <NavLink
              onClick={handleNavItemClick}
                to="/login"
                className={`m-2 ${classes.MainNavHeaderLink}`}>
                login
              </NavLink>
              <NavLink
              onClick={handleNavItemClick}
                to="/sign-up"
                className={`login-register m-2 ${classes.MainNavHeaderLink}`}>
                {t('Login / Register')}
              </NavLink>
              </>
            )}

            {/*
            <NavLink
              onClick={ () => {
                if( i18n.resolvedLanguage == 'en' ){
                  localStorage.setItem('lang','ar')
                  i18n.changeLanguage('ar')
                }else{
                  localStorage.setItem('lang','en')
                  i18n.changeLanguage('en')
                }
                window.location.reload();
              } }
              to="#"
              className={({isActive}) =>
                isActive
                  ? classes.active
                  : `m-2 ${classes.MainNavHeaderLink} lang`
              }
              style={{color: '#0576e3 !important'}}>
              {' '}
              <FaGlobe /> { i18n.resolvedLanguage == 'en' ? 'العربية' : 'English' } 
            </NavLink>
            */}
          </Nav>
        </Navbar.Collapse>

        
      </Container>
    </Navbar>

    <Modal show={showResetPassword} onHide={() => setShowResetPassword(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center p-4">
            
            <h5>Reset Password</h5>
            <div className="mt-3 mb-3" style={{position: 'relative'}}>
                <div
                  className={loginClasses.togglePassword}
                  onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? (
                    <img src="/eye.png" alt="img" width={25} />
                  ) : (
                    <FaEye color="#08cad7" size={20} />
                  )}
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control m-0 ${classes.PasswordInput}`}
                  placeholder="Password"
                  required
                  onChange={e => setPasswordInput(e.target.value)}
                />    
            </div>

            <div className="mt-3 mb-3" style={{position: 'relative'}}>
                <div
                  className={loginClasses.togglePassword}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {!showConfirmPassword ? (
                    <img src="/eye.png" alt="img" width={25} />
                  ) : (
                    <FaEye color="#08cad7" size={20} />
                  )}
                </div>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={`form-control m-0 ${classes.PasswordInput}`}
                  placeholder="Confirm Password"
                  required
                  onChange={e => setConfirmPasswordInput(e.target.value)}
                />    
            </div>
            <div
              onClick={() => {resetPasswordToRequestConsultant()}}>
              <MainButton text={'Send'} disabled={passwordInput == '' && confirmPasswordInput == '' } />
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default NavigationHeaderV2;

import { Col, Container, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './style/UserTrackingSystems.module.css'
import TrackingCard from './components/TrackingCard';
import { useAppDispatch } from '../../hooks/hooks';
import { useEffect, useState } from 'react';
import { setLoading } from '../../store/userSlice';
import { getTracksMembers } from './Trackings.service';
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import { useParams } from "react-router-dom"


const MultiUsersEnrolledTrackingSystems = () => {
    

    const dispatch = useAppDispatch();

    const {id} = useParams();

    const [trackings, setTrackings] = useState([]);
    const [lastPage, SetLastPage] = useState<number>(1);
    const [page, setPage] = useState<number>(1);
    const [tab, setTab] = useState<number>(0);

    useEffect(() => {
        getData(tab);
    }, [page]);

    const getData = async (tab:number) => {
        setPage(1)
        dispatch(setLoading(true));
        let result = await getTracksMembers(id , tab , page);
        console.log('in progress data' , result.response )
        if (result.response) {
            setTrackings(result.response.data);
            SetLastPage(result.response.meta.last_page);
        }

        dispatch(setLoading(false));
    };

    const handleSelect = (key: string | null) => {
        if (key == 'inProgress') {
            getData(0)
        }else{
            getData(1)
        }
        
      };
  
  return (
  
        <Container>
            <h1 className="page-title">Follow-up Systems</h1>
            
            {/* User Courses Tabs */} 
            <Tabs
            defaultActiveKey="inProgress"
            id="uncontrolled-tab-example"
            className="custom-web-tabs mb-5"
            onSelect={(key: string | null) => handleSelect(key)}
            >
            <Tab
            eventKey="inProgress"
            title="In Progress"
            >
                
                <Row>
                    {trackings.map(tracking => {
                    return <Col lg={6} >
                                <TrackingCard tracking={tracking} showProgress={true} />
                            </Col>;
                    })}  
                </Row>

                
                <div className="pagination-container">
                    <ItemsPagination
                        pages={lastPage}
                        current={page}
                        changePage={page => {
                            
                        setPage(page);
                        }}
                    />
                </div>  

            </Tab>
            <Tab
            eventKey="completed"
            title="Completed"
            >
                <Row>
                {trackings.map(tracking => {
                return <Col lg={6} >
                            <TrackingCard tracking={tracking}/>
                        </Col>;
                })}
                    
                    
                </Row>

                
                <div className="pagination-container">
                    <ItemsPagination
                        pages={lastPage}
                        current={page}
                        changePage={page => {
                        setPage(page);
                        }}
                    />
                </div>
                
            </Tab>
            
            
            </Tabs>
        </Container>
    
  );
}

export default MultiUsersEnrolledTrackingSystems;
import { NavLink } from "react-router-dom";
import { useTranslation} from 'react-i18next';

const ComeSoon = () => {
  const { t } = useTranslation();
 
  return (
    <div className="text-center pt-5 pb-5 comming-soon">
    <h1 className="font-bold"
    style={{
        fontSize: '90px',
        fontWeight: 'bold',
        color: '#0576e3',
    }}
    >{t('Comming Soon')}</h1>
    
    <div className="main-head mt-5">
        <p className="m-0 our-owesome"
        style={{
            fontSize: '40px',
            fontWeight: '700',
            lineHeight: '44px',
            color: '#020334',
            paddingBottom: '10px',
        }}
        >{t('Our Awesome App')}</p>
        <p style={{
            
                fontSize: '20px',
                color: 'rgb(2, 3, 52)',
                fontWeight: '500',
        }}>
            {t('downloadApp')}
        </p>
    </div>
    
    <div className="store-icons">
        <NavLink to={'https://apps.apple.com/eg/app/nurabi-app/id1593607736'}>
        <img
            
            src="/icons/app-store.png"
            className="mb-1"
            style={{maxWidth: '170px' , margin : '10px' , borderRadius : '5px' }}
        />
        </NavLink>
        <NavLink to={'https://play.google.com/store/apps/details?id=com.nurabi'}>
        <img
            
            src="/icons/play-store.png"
            className="mb-1"
            style={{maxWidth: '170px', margin : '10px' , borderRadius : '5px' }}
        />
        </NavLink>
    </div>
    
    </div>
    
      
  );
};

export default ComeSoon;


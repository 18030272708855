import {Col, Container, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserInitiativesList from './UserInitiativesList';
import UserInitiativesDiscover from './UserInitiativesDiscover';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UserTrackingList from './UserTrackingList';
import UserTrackingsDiscover from './UserTrackingsDiscover';
const UserTrackings = () => {

  const location = useLocation();
  
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(queryParams.get('tab') || 'userTackingSystems');

  const handleSelect = (selectedTab:any) => {
    //setActiveTab(selectedTab);
    window.location.href = `?tab=${selectedTab}`
  };

  useEffect(() => {
    const tabFromQueryParam = queryParams.get('tab');
    if (tabFromQueryParam) {
      setActiveTab(tabFromQueryParam);
    }

  }, [location.search]);

  

  return (
    <div>
      <div className="contact-header d-flex justify-content-center align-items-center">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col xs={6} className="d-flex justify-content-center align-items-center">
              <div className="breadcrumb d-flex justify-content-center">
                <h3 className="breadcrumb-header">Follow-up Systems</h3>
                <div className="breadcrumb-details text-center">
                  <span>Home</span> <span> - </span>{' '}
                  <span>Follow-up Systems</span>
                </div>
              </div>
            </Col>

            
          </Row>
        </Container>
      </div>
      <Container>
        {/* User Courses Tabs */}
        <Tabs
        
          id="uncontrolled-tab-example"
          className="custom-web-tabs mb-5"
          activeKey={activeTab} onSelect={handleSelect}
          >
          <Tab eventKey="userTackingSystems" title="My Follow-up Systems">
          <UserTrackingList />
          </Tab>
          <Tab eventKey="discoverTrackingSystems" title="Discover Systems">
            <UserTrackingsDiscover />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default UserTrackings;

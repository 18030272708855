import {Fragment, useRef, useState} from 'react';

import {Col, Container, Row} from 'react-bootstrap';

import './Home.css';

import {useLocation, useNavigate} from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
const PopUpAds = (props) => {

  const navigate = useNavigate();

  return (
    <Fragment>
     <div className='home-banner pop-up'>
      { props?.ads.length ? 
      <Carousel >
      {props?.ads.map(i => (
        <Carousel.Item>
          <img
            className="d-block w-100 cursor-pointer"
            src={i.image}
            alt="slide"
            onClick={()  => {
              if(i.model_name == 'Consultant' ){
                navigate(`/consultants/teacher/${i.model_id}`)
              }else if( i.model_name == 'InitiativePackage' ){
                navigate(`/user/initiatives/${i.model_id}/package/levels?discover=1`);
              }
              
            }}
          />
        
        </Carousel.Item>
        ))}
      </Carousel>
      : ''}
      </div>
     
    </Fragment>
  );
};

export default PopUpAds;

import {APIURL} from '../../services/http/api';
import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const sendOtp = async (data: FormData) => {
  const api = APIURL.auth.sendOtp;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const ResendOtp = async (data: FormData) => {
  const api = APIURL.auth.ResendOtp;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const VerifyOtp = async (data: FormData) => {
  const api = APIURL.auth.Verify;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const RegisterAccount = async (data: FormData) => {
  const api = APIURL.auth.Register;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const LoginAccount = async (data: FormData) => {
  const api = APIURL.auth.login;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const ForgetPassAccount = async (data: FormData) => {
  const api = APIURL.auth.Forget;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const ResetPassAccount = async (data: FormData) => {
  const api = APIURL.auth.resetPassword;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getUserData = async () => {
  const api = APIURL.auth.userData;
  const [err, resp] = await http.get(api);
  return {error: err, response: resp?.data};
};
export const getReferalCode = async () => {
  const api = APIURL.auth.refCode;
  const [err, resp] = await http.get(api);
  return {error: err, response: resp?.data};
};

export const SocialLogin = async (data: FormData) => {
  const api = APIURL.auth.socialLogin;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const editProfile = async (data: FormData) => {
  const id = localStorage.getItem('id');
  const api = APIURL.auth.editProfile;
  const finalApi = api + id;
  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const deleteAccount = async () => {
  const api = APIURL.auth.deleteAccount;
  const [err, resp] = await http.delete(api);

  return {error: err, response: resp?.data};
};

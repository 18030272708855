import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {APIURL} from '../services/http/api';
import {HttpService} from '../services/http/http.service';

export const getBalance = createAsyncThunk('user/getBalance', async () => {
  const http = new HttpService();
  const api = APIURL.general.getBalance;
  try {
    const [err, resp] = await http.get(api);
    return resp;
  } catch (error) {}
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    balance: 0 as number,
    loading: false as boolean,
    consStatus: '' as string,
    notificationNumber: '0' as string,
    showNotificationDropDown: false as boolean,
  },
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setConsStatus: (state, action: PayloadAction<string>) => {
      state.consStatus = action.payload;
    },
    changeNotificationNumber: (state, action: PayloadAction<string>) => {
      state.notificationNumber = action.payload;
    },
    setShowNotificationDropDown: (state, action: PayloadAction<boolean>) => {
      state.showNotificationDropDown = action.payload;
    },
  },
  extraReducers: {
    [`${getBalance.pending}`]: (state, action: PayloadAction<any>) => {},
    [`${getBalance.fulfilled}`]: (state, action: PayloadAction<any>) => {
      console.log('action.payload.dataBB', action.payload.data);

      state.balance = action.payload.data.data;
    },
    [`${getBalance.rejected}`]: (state, action: PayloadAction<any>) => {},
  },
});

export default userSlice.reducer;
export const {setLoading, setConsStatus, changeNotificationNumber , setShowNotificationDropDown } =
  userSlice.actions;

import { Col, Container, Row } from "react-bootstrap"
import classes from './TermsAndConditions.module.css'


const TermsAndConditions = () => {
    return (
        <Container className="main-margin-top">
            <Row>
                <Col md={12} className="d-flex align-items-center">
                    <div className={classes.TermsDetails}>
                        <h1 className="main-title text-center mb-5 pb-5">Terms And Conditions</h1>
                        <p className={classes.mainParagraph}>Contents:</p>
                        <ol>
                            <li>Overview of “Nualim”</li>
                            <li>Terms and Conditions for Registration and Using the Platform.</li>
                            <li>Terms and Conditions for Services Provided.</li>
                            <li>Policy of Dealing with the Services’ Providers.</li>
                            <li>Terms of Subscriptions and Fees.</li>
                            <li>Training Courses.</li>
                            <li>Refund Policy and Procedures.</li>
                            <li>Intellectual Property Rights.</li>
                            <li>User Account Waiver and Termination.</li>
                            <li>Legal Liability under Canadian Law.</li>
                            <li>Damage Indemnity Policy.</li>
                            <li>General Terms.</li>
                            <li>Privacy Policy.</li>            
                        </ol>

                        <p className={classes.mainParagraph}>Platform’s Overview:</p>
                        <p className={classes.mainParagraph}>Nualim’s Objective</p>
                        <p>
                        <b>Nualim</b> is a Canadian Platform by (Nurabi Platform Inc.), designed specifically for Teachers. The Platform facilitates easy communication and interaction between Teachers from various disciplines and fields with their Students. It also equips Teachers with effective tools to manage their schedules and income, receive payments, send and receive assignments and educational tasks, and provide recorded and live educational lectures and courses through a secure and efficient electronic system. It also provides a comprehensive follow-up system and the ability to convert curricula into challenges and competitions that motivate students, as well as; providing individual and group lessons through text-based conversations and virtual audio and video conference rooms.
                        </p>

                        <p className={classes.mainParagraph}>Introduction:</p>

                        <p>
                        <b>Nualim</b> welcomes you and informs you that the following Terms and Conditions govern your use of the Platform and all legal effects arising therefrom. Please read these Terms and Conditions carefully before using the Platform. Your agreement and acceptance of use is a confirmation of your commitment to its systems and to what is mentioned therein. In case of non-acceptance, you will not be able to join the Platform. We inform you that the Platform may be a (Website, Mobile Application, or Electronic Platform) and this agreement is effective and enforceable once you agree to it and start registering under the provisions of the law.
                        </p>

                        <p>
                        The Platform may change the Terms of Use and Privacy Policy from time to time without the need to contact you or notify you personally. The changes will be announced on the Platform in general; therefore, you must check these Terms regularly. Your use of the Platform is an explicit agreement to the Terms in effect at that time.
                        </p>

                        <p className={classes.mainParagraph}>Article 1: Terms and Conditions for Registration and Using the Platform.</p>

                        <p>
                        By accessing and using the Platform, you agree to be bound by these Terms and Conditions. You are solely responsible for understanding and complying with all applicable laws, regulations, rules, and policies related to your use of the Platform. Upon accessing the Platform, you agree to:
                        </p>

                        <p>
                        Article 1: Terms and Conditions for Registration and Using the Platform.
                        </p>

                        <p>
                        By accessing and using the Platform, you agree to be bound by these Terms and Conditions. You are solely responsible for understanding and complying with all applicable laws, regulations, rules, and policies related to your use of the Platform.
                        Upon accessing the Platform, you agree to:
                        </p>

                        <p>
                        1.1 Assume full legal responsibility for publishing, uploading, writing, or promoting anything through the Platform, Website, or Mobile Application, whether you do so personally or through a third party affiliated with you. The Platform bears no responsibility for any illegal act or behavior, which is represented in the following forms:
                        </p>

                        <div className="px-3">
                            <p>
                            1.1.1 Doing anything that affects the operation or security of the Platform or Mobile Application, or causes disruption or disturbance to any other users or our employees.
                            </p>
                            <p>
                            1.1.2 Transferring your account (including the page or application you operate) to any other person without obtaining a prior written permission from us.
                            </p>

                            <p>
                            1.1.3 Applying any reverse engineering software used in the Platform's or Application’s infrastructure and operations. 
                            </p>

                            <p>
                            1.1.4 Violating the privacy of other users or collecting any data or information about other users without their explicit consent, whether this is done manually, or by using any software, mechanism, hack tracker, Platform search, restoring system, or using any other devices or mechanisms to hack the Website and/or the main system, restoring index information, and/or extracting data.
                            </p>

                            <p>
                            1.1.5 Collecting personal or private information belonging to a third party, including but not limited to, family names, addresses, phone numbers, email addresses, and/or credit card numbers.
                            </p>

                            <p>
                            1.1.6 Transmitting viruses, malware, or other files that cause damage, disruption, sabotage or deletion.
                            </p>

                            <p>
                            1.1.7 Submitting any content or link to content that may expose us or our users to harm or legal liability in any way (in our sole judgment).
                            </p>

                            <p>
                            1.1.8 Submitting illegal, defamatory content or offensive content that targets any religious group, or unethical, pornographic or indecent materials, or materials that contain harassment, threats, violation of privacy, violence, fraudulence, or unaccepted in any way.
                            </p>

                            <p>
                            1.1.9 Submitting content that constitutes a criminal offense, encourages or instructs on how to commit it, violates the rights of any party, causes any form of legal liability or violates any local or international laws.
                            </p>

                            <p>
                            1.1.10 Publishing content that you do not personally own without legal permission from the legal owner of such content.
                            </p>

                            <p>
                            1.1.11 Publishing content that impersonates any natural or legal person, or consequently causes a false claim; in any way, with that natural or legal person, including "Nualim".
                            </p>

                            <p>
                            1.1.12 Publishing/Submitting promotional materials, political campaigns, advertisements, competitions, lotteries, or unsolicited shows.
                            </p>
                        </div>

                        <p>
                        1.2. If you have a complaint about: content posted by a user, caused by a user to be posted, stored, found, sent, broadcast, or promoted through "Nualim" Website/Application directly, or through a third-party’s website; You agree to direct your complaint against the user only and not against us and waive any complaint against us.
                        </p>

                        <p>
                        1.3. You are responsible for using "Nualim" Platform/Application in any way that leads to the destruction or exceeding the capacity of the server devices of our network or any networks connected to any of our servers, including but not limited to; performing any action that would impose a disproportionate burden on the available infrastructure or bandwidth of "Nualim" Website or Application.
                        </p>

                        <p>
                        1.4. Registration Conditions:
                        </p>

                        <div className="px-3">
                            <p>
                            1.4.1 Users must be at least 18 years old (Gregorian calendar) to register for "Nualim" program, either through the Platform (Website) or the Mobile Application. They must have full legal capacity according to the law of the country in which they currently reside; and the Platform is not responsible for any incorrect data entered to conceal the user's true age.
                            </p>
                            <p>
                            1.4.2 Any person under 18 years old (Gregorian calendar) who wishes to use "Nualim" Website or Application needs to obtain the consent of their guardian or legal representative. They need to send the email address of the guardian or legal representative so that the Platform can verify their approval for the minor to use the Platform (whether the Website or the Application). Besides, guardians or legal representatives are responsible for monitoring the activities and actions of minors until they reach the age of 18 years (Gregorian calendar) or the legal age according to the country in which the minor resides.
                            </p>

                            <p>
                            1.4.3 If it is discovered that the user's age is below the legal age to create an account on "Nualim", the account will be temporarily suspended until the consent of the guardian or legal representative is received in accordance with the previous clause, or the account will be deleted from the Platform without any consequences or responsibility on the part of the Platform.
                            </p>
                            
                        </div>

                        
                        <p className={classes.mainParagraph}>
                        Article 2: Terms and Conditions Regarding Services Provided by the Platform.
                        </p>

                        <p>
                        2.1 Service content refers to the content found on the Website (www.nualim.net), or any other websites owned by "Nualim" or "Nualim" Mobile Application. This content includes information related to "Nualim" and the services it offers, including instructional, behavioral and educational information.
                        </p>

                        <p>
                        2.2. In any case, any mental health content on the Platform is not considered a suggestion or an offer to provide any mental health services to users as the Platform doesn’t provide any mental health services that require a license or a permit to practice. Nothing contained on the Platform can be considered reliable educational information, or for medical diagnosis or treatment, and the information found through the Website or the Application and all Websites related to “Nualim” are not intended to replace or diminish the importance of professional medical advice by an expert. It is intended only for general media and educational purposes, and under no circumstances should any user rely on such information or services for the purposes of treatment and/or medical advice.
                        </p>

                        <p>
                        2.3. Individuals seeking educational or instructional advice or medical treatment should consult with their physicians or other educational, psychological and behavioral specialists or other qualified mental healthcare professionals to get proper diagnosis and appropriate treatment for any health concerns or medical conditions. Medical advice or treatment are not permitted to be done through the Platform.
                        </p>

                        <p>
                        2.4 “Nualim” provides technical tools that help teachers organize their work accurately, professionally and electronically, while providing Students with access to Teachers in various fields on the Platform. Teachers bear full responsibility for any information, content, or services provided through the Platform, Website, Mobile Application, or any related sites. In case of a complaint filed by a service recipient, the Platform will thoroughly investigate the matter. If the complaint is verified, the Platform will support the complainant as a witness in taking legal and disciplinary action against the accused Teacher and users have no right to recourse against the Platform in any legal dispute. This includes any financial liabilities or litigation costs as the Teacher is solely responsible for their actions and the services they offer through the Platform.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 3: Policies for Dealing with Service Providers.
                        </p>

                        <p>
                        Teachers who are using the Platform as specialists in their fields to provide services to users have self-identified as possessing adequate and appropriate training, qualifications, experience, skills, certifications, and academic background in their areas of expertise.
                        </p>

                        <p>
                        3.1 When utilizing the services of a Teacher, you have the option to provide a rating from 1 to 5 and offer any feedback or comments based on your experience with the Teacher.
                        </p>

                        <p>
                        3.2 The Platform exclusively relies on the offers presented by Teachers based on the Terms and Conditions of their service provision, as well as users’ feedback for the purposes of evaluating these Teachers.
                        </p>

                        <p>
                        3.3 The Platform does not encourage or endorse any particular Teacher over another, either directly or indirectly. The Platform's role is limited to providing a profile link of the Teacher, and the user chooses - at his own discretion - to engage or not engage with any of them or those registered on the Platform.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 4: Subscription and Fees Provisions
                        </p>

                        <p>
                        4.1 Free Access:
                        </p>

                        <div className="px-3">
                            <p>
                            4.1.1 Users can access and explore Nualim’s free services and get benefit of them through the Website or Mobile Application without the need of registering for any paid services.
                            </p>

                            <p>
                            4.1.2 "Nualim" may offer free materials, trials, or free subscriptions, at its sole discretion. if access to a service or material is provided to you for free, for trial purposes, or for promotional reasons, such access is subject to these Terms and Conditions. "Nualim" reserves the right, at its sole discretion, to terminate free or trial access at any time before or during the free period without prior notice and without any liability to you as a user.
                            </p>

                            <p>
                            4.1.3 To continue using the service beyond the free or trial period, you have to pay for services you get.
                            </p>

                            <p>
                            4.2 Payment Methods:
                            </p>

                            <p>
                            Subscriptions are processed through electronic payment gateways “Nualim” provides, such as (PayPal, Stripe, …… and others).
                            </p>

                            <p>
                            4.3 Fees Changes:
                            </p>

                            <p>
                            "Nualim" reserves the right to modify service fees, including fees for accessing or purchasing materials and content, at any time. Existing subscribers will be notified of any changes in case of automatic service renewals. "Nualim" shall not be liable to you or any third party for any such modifications, at its sole discretion.
                            </p>

                            
                            
                        </div>


                        <p className={classes.mainParagraph}>
                        Article 5: Training Courses
                        </p>

                        <p>
                        Upon enrollment in a training course, subscribers gain unrestricted access to the course materials and content, unless subscribers’ notification of time restrictions on access to specific course content or a notification of a change in this article.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 6: Refund Policy and Procedure
                        </p>

                        <p>
                        6.1 Refund Policy:
                        </p>

                        <div className="px-3">
                            <p>
                            6.1.1 In case that audio or video lessons are canceled by the client 12 hours or more before the scheduled time, the client is entitled to a full refund of the paid amount. (This refund is subject to the general refund processing guidelines outlined in Section 6.2).
                            </p>

                            <p>
                            6.1.2 In case that audio or video lessons are canceled by the client 12 hours or less before the scheduled time, the client is not entitled to any refund of the paid amount.
                            </p>

                            <p>
                            6.1.3 In the event that a scheduled session is canceled by the Teacher, the user is entitled to an alternative session or a full refund of the service fee. (This refund is subject to the general refund processing guidelines outlined in Section 6.2. of refund policy).
                            </p>

                            <p>
                            6.1.4 If a user encounters technical difficulties arising from the Platform’s side that prevent him from accessing training course material or content and informs us about the case within 5 days of purchase, "Nualim" reserves the right to assess the situation and, at its sole discretion, may choose to replace the defective content (if possible) or provide a partial or full refund of the applicable fees.
                            </p>

                            <p>
                            6.1.5 Materials and content that have already been downloaded by the user are not eligible for a refund of the paid fees.
                            </p>

                            <p>
                            6.1.6 In case that "Nualim" discovers users to be abusing the refund policy –at its sole discretion-  as they repeatedly cancel lessons 12 hours or more before the scheduled time (as mentioned in Sections 6.1.1), the user may be restricted from receiving future refunds and/or his account may be temporarily or permanently suspended.
                            </p>
                            
                        </div>

                        <p>
                        6.2 Refund Processing Method:
                        </p>

                        <p>
                        Approved refunds are credited to the agent’s/user's wallet on the Platform. Users can then utilize these refunded amounts to book new lessons or enroll in training courses available on the Platform.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 7: Intellectual Property Rights
                        </p>

                        <p>
                        7.1 "Nualim" refers that current and future Platform content (Website and Mobile Application), including but not limited to, source code, software, specifications, images, logos, audio files are protected by the law of copyrights, trademarks, and other applicable intellectual property laws in Canada. This means it’s prohibited to create any derivative works or modifications without prior written consent from "Nualim". It’s not allowed also, by any way, to alter or copy the content of “Nualim” without a prior written consent. It’s not allowed to use illustrative elements, images, videos, audio sequences, or graphics separately from the accompanying text without a prior written consent from “Nualim”, as well.
                        </p>

                        <p>
                        In case that you (the user) download or print Platform materials without authorized access by paying for them, it’s considered a violation of these Terms of Use. In such 
                        </p>

                        <p>
                        7.2 Users can download certain materials and content purchased through the Platform. However, this purchase does not grant the user the right to sell or redistribute the downloaded materials or content.
                        </p>

                        <p>
                        7.3 Platform users are obligated to respect the intellectual property rights of others. "Nualim" reserves the right to remove any material that it deems, at its sole discretion, to be in violation of another party's intellectual property rights. If a user believes that his/her copyrighted material is being used on the Platform without authorization, they can report it to "Nualim" by providing the following information:
                        </p>

                        <ul className="px-3">
                            <li>
                            A detailed description of the allegedly infringing material, including sufficient information to locate it, such as the URL where it is hosted.
                            </li>

                            <li>
                            Documentation or proof of ownership of the copyrighted material.
                            </li>

                            <li>
                            Name, post address, email, phone number.
                            </li>
                        </ul>

                        <p>
                        7.4 When a user submits any content to "Nualim’s" public interactive pages by email, or by other means, "Nualim" has the right to use that content in any way it deems appropriate. The user warrants that the submitted material is suitable for publication and agrees to indemnify "Nualim" for any damages arising from third-party’s actions against us related to the user-provided content.
                        </p>

                        <p>
                        7.5 Any feedback, comments, reviews, ideas, or suggestions regarding the Platform and/or its services that a user provides to "Nualim" will be the sole and exclusive property of "Nualim" and You ‘the user’ irrevocably waives all rights, ownership, interests and notes, and "Nualim" has the authorization to use these comments in any way without any obligation to the user. The user also waives the right to bring any claims against "Nualim" related to the use of such feedback, including any claims related to "moral rights".
                        </p>

                        <p>
                        7.6 Generally, by using the Platform, you (whether as a Teacher or an Expert) grant “Nualim” a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, transferable, and sublicensable (through multiple levels) license to store, use, reproduce, perform, make publicly available, publish, distribute, and display your uploaded content across the Platform. This includes visual, audio, and textual content, social media profile information (including your “Nualim” profile), and any other content you share with us through the Platform (collectively referred to as "User Content"). This license is granted for the purposes of providing and promoting the service, as well as for other purposes as defined in these Terms.
                        </p>

                        <p>
                        If you publish or share your User Content with others through the service, or if your User Content is shared through any social media platforms (including our own social media platforms), you acknowledge and agree that your User Content may remain visible and accessible on those platforms and consequently other users may retain, display, reproduce, share, or incorporate your User Content in any other way, even if you remove the content from your account with us.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 8: Account Assignment or Termination
                        </p>

                        <p>
                        8.1 You cannot assign or transfer these Terms and Conditions (or the rights and licenses granted under them). For example, if you register an account in a company as a customer, you cannot transfer your account to another customer; you agree that your account is non-transferable and non-assignable, and that all rights to your account and other rights under these Terms terminate only upon your death.
                        </p>

                        <p>
                        8.2 We have the right to assign these Terms and Conditions (or the rights and licenses granted under them) to another company or person without restriction.
                        </p>

                        <p>
                        8.3 If you want to review or change any information on your account or terminate your account any time, you can:
                        </p>

                        <ul className="px-3">
                            <li>
                            Sign in to your account settings and update your user account.
                            </li>
                            <li>
                            Contact us using the contact information provided.
                            </li>
                            <li>
                            Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our archives for fraud prevention, troubleshooting, assistance with investigations, and enforcement of our Terms of Use.
                            </li>

                        </ul>

                        <p className={classes.mainParagraph}>
                        Article 9: Liability under Canadian Law.
                        </p>

                        <p>
                        9.1 To the fullest extent permitted by Canadian Law, we, our directors, employees, agents, and contractors shall not be liable to you for any losses, damages, liabilities, and claims (including but not limited to loss of data, revenue, profits, opportunities, personal injury, or death), as well as, costs (including but not limited to legal fees, defense costs, or settlement costs), by any way, either directly or indirectly; which arising out of or in connection with: your access to or use of "Nualim" Website (www.nualim.net) or Mobile Application, or your reliance on the information or services provided through the Platform or posted by any third party. In addition, we’re not responsible for any harm caused by any Teacher, regardless of whether we or our authorized representative knew or have known of the possibility of such loss or damage. This limitation of liability applies to all claims, whether based on contract, warranty, tort, or any other theory of liability.
                        </p>

                        <p>
                        9.2 “Nualim” is committed to provide a secure environment for its Website and Mobile Application. However; we have to mention that no online environment; including the internet itself, can be guaranteed to be 100% secure, but we will exert our efforts to protect your personal information. Users have to know that transferring information from or to the Platform is on their own liability and we strive to help you get your services through a safe environment.
                        </p>

                        <p>
                        9.3 Users, customers, Teachers, and experts… are obliged to utilize the Platform (Website and Mobile Application) both solely in parallel to get the service and pay for the services by the payment methods “Nualim” set according to its policy.
                        </p>

                        <p>
                        All users of the Platform are strictly prohibited from communicating with each other about the provision of services, providing or receiving the services, or for any financial transaction to take place between them outside the framework of the Platform, otherwise it is considered a violation of the Trms and Conditions of using “Nualim” services. Consequently, “Nualim” has the right to suspend the account of any user who violates these Terms, temporarily or permanently, and “Nualim” reserves the right to take any legal action or claims deem appropriate against that user, and has the right to demand any compensation it deems appropriate. 
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 10: compensation
                        </p>

                        <p>
                        You agree, immediately or upon request, to indemnify us and/or our subsidiaries for all claims, liabilities, losses, damages, costs, and expenses, including legal fees arising out of:
                        </p>

                        <p>
                        (a) Your content that you post or transmit. 
                        </p>

                        <p>
                        (b) Your use of the Platform. 
                        </p>

                        <p>
                        (c) Your violation of these Terms. 
                        </p>

                        <p>
                        (d) Your violation of any rights of a third party.
                        </p>

                        <p>
                        And your obligation to indemnify us will remain in effect even after you terminate these Terms and stop using the Platform.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 11: General Provisions.
                        </p>

                        <p>
                        11.1 If any court or regulatory institution decides that any provision, clause or condition of these Terms is held to be completely invalid or unenforceable, such provisions shall be severed and deleted from these Terms and Conditions and the remaining provisions will remain in full force and effect.
                        </p>

                        <p>
                        11.2 If any court or regulatory institution decides that any provision, clause or condition of these Terms is held to be valid or enforceable if a part is deleted, such part will be deemed deleted and the rest of the provision, clause or condition will remain in full force and effect.
                        </p>

                        <p>
                        11.3 These Terms and Conditions and “Nualim” Website and Application are governed by the laws of Canada. Any dispute arising between the Platform and its owner company or any other party shall be resolved amicably. If amicable resolution fails to resolve the dispute within a maximum of 30 days, the competent courts of Canada shall have jurisdiction to hear and determine the dispute.
                        </p>

                        <p>
                        These Terms and Conditions apply between us and the users, and no other person shall be entitled to benefit from these Terms and Conditions.
                        </p>

                        <p className={classes.mainParagraph}>
                        Article 12: Privacy Policy
                        </p>

                        <p>
                        Use of Information We Collect or Receive:
                        </p>

                        <p>
                        We do not share information unless you consent, to comply with laws, to protect your rights, or to fulfill contractual obligations to facilitate the account creation and login process.
                        </p>

                        <p>
                        If you choose to link your account with us to a third-party account, including but not limited to;
                        </p>

                        <ul className="px-3">
                            <li>
                            Your (Google) or (Facebook) account, we will use the information we are allowed to collect from those third parties to facilitate the account creation and login process.
                            </li>

                            <li>
                            Sending you marketing and promotional communications for commercial purposes: We and/or our external marketing partners may use the personal information you send us for our own marketing purposes, if it is in accordance with your marketing preferences. Besides, you can unsubscribe from our marketing emails at any time.
                            </li>

                            <li>
                            Sending you administrative information for commercial purposes and/or legal reasons and/or may be for contractual purposes.
                            </li>

                            <li>
                            We may use your personal information to send you product and service information and new features, and/or information about changes to our Terms, Conditions and Policies. 
                            </li>

                            <li>
                            For contractual reasons, we may use your information to fulfill and manage your orders, payments, returns, and exchanges, which are made through the sites. 
                            </li>

                            <li>
                            To publish testimonials with your consent: we publish testimonials on our sites that may contain personal information. Before publishing a testimonial, we will obtain your consent to use your name and testimonial if you wish to update or remove your testimonial.
                            </li>

                            <li>
                            To provide you with targeted advertising for our business purposes: we may use your information to develop and display content and ads (and work with third parties who do so) tailored to your interests and/or location and to measure their effectiveness.
                            </li>

                            <li>
                            To enable user-to-user communication with your consent: we may use your information to enable communication between users with the consent of each user.
                            </li>
                        </ul>

                        <p className={classes.mainParagraph}>
                        Article 13: Student Policies and Terms.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Learning Process:
                        </p>

                        <p>
                        1. Students are entitled to a (free 30-minute) trial lesson before booking paid lessons. If a student misses the trial lesson, he/she can reschedule it for a paid fee.
                        </p>

                        <p>
                        2. Students must have a reliable internet connection and ensure the quality of their audio/visual tools (headphones, microphone, and camera).
                        </p>

                        <p>
                        3. The learning environment must be quiet, and the background should be appropriate.
                        </p>

                        <p>
                        4. The camera must be turned on during the lesson.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Student Lateness:
                        </p>

                        <p>
                        If a student is late for the start of the lesson, the Teacher is not obligated to compensate for the lost time, and the lesson will end at the scheduled time.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Student Absence or Cancellation:
                        </p>

                        <p>
                        1. If a student requests to cancel a lesson 12 hours or more before the scheduled time, he/she has the right of a full refund of the paid lesson fee, which will be credited to the student wallet.
                        </p>

                        <p>
                        2. If a student requests to cancel a lesson 12 hours or less before the scheduled time, he/she has no right to get any refund of the paid lesson fee. 
                        </p>

                        <p>
                        3. If a student is absent from a scheduled lesson, he/she will not receive a refund of any part of the paid lesson fee.
                        </p>

                        <p>
                        4. If a student wishes to change his/her Teacher or discontinue their lessons and has already booked a number of lessons, he/she must notify the administration at least four days before the next lesson. The administration will then credit the remaining paid lesson amount to the student's wallet on the Platform.
                        </p>

                        <p>
                        5. Students can use the refunded amounts in their wallet to book new lessons or other services within the Platform. Cash refunds are not permitted.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Teacher Late Policy:
                        </p>

                        <p>
                        1. If a Teacher is late for the start of the lesson by less than 15 minutes, he/she will compensate the student for the late minutes at the end of the lesson if his/her schedules and the student’s time allow. Otherwise, they will compensate the student in the next lesson. If the Teacher does not compensate the student, the student should report this to the customer service for further action.
                        </p>

                        <p>
                        2. If a Teacher is late for more than 15 minutes, they are considered absent from the lesson, and the lesson fee will be refunded to the student's wallet. The student should immediately file a complaint with the customer service for further action.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Teacher Absence and Cancellation Policy:
                        </p>

                        <p>
                        1. In rare cases, a Teacher may be absent from a lesson due to unforeseen circumstances, such as a power outage or a medical emergency. In such cases, the student will receive a full refund for the lesson and can reschedule another lesson. The student should immediately file a complaint with the support team for further action.
                        </p>

                        <p>
                        2. If the cancellation is by the Teacher, the student will receive a full refund for the lesson and the amount will be credited to his/her wallet.
                        </p>

                        <p>
                        3. If a Teacher is frequently late or absent from lessons, please report this to the customer service and write these comments in the evaluation that appears after the lesson ends, so that the necessary action and decisions will be taken with the Teacher.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Quality Team Monitoring:
                        </p>

                        <p>
                        1. A member of the Teacher management team has the right to attend any lesson with the Teacher as a listener to verify the student's commitment and to evaluate the Teacher's performance.
                        </p>

                        <p>
                        2. A detailed report of what was studied in the lesson will be written, and homework and assignments will be sent within 12 hours of the start time of the lesson. If the Teacher is late in submitting these materials, please contact customer service for further action.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Evaluation:
                        </p>

                        <p>
                        After each lesson, an evaluation form will appear. Please answer the questions and write any comments or feedback you may have. This feedback is used to monitor quality, improve performance, and provide excellent service.
                        </p>

                        <p style={{ textDecoration : 'underline' }}>
                        Data Protection:
                        </p>

                        <p>
                        1. We care about the security of our users. This requires that you do not share your personal contact information, such as your phone number, email address, social media accounts, or credit card information and so on, with any Teacher or third party (supervisor) and the Platform will not be responsible for any misuse of this information.
                        </p>

                        <p>
                        2. Teachers are also not allowed to give or share their phone number, email address, or social media accounts to students or parents. If anyone does this, please report it to us immediately.
                        </p>

                        <p>
                        3. The Platform protects the privacy of both students and Teachers. All personal information is considered confidential, and no one has the right to know, copy, or use it in any way.
                        </p>

                        <p>
                        4. Please do not discuss payment arrangements, fees, or anything else with Teachers. In these cases, please contact customer service.
                        </p>

                        <p>
                        5. You are not allowed to study with your Teacher outside the Platform (only on the Platform).
                        </p>








                        
                        
                    </div>
                </Col>
                
            </Row>
        </Container>
    )
}


export default TermsAndConditions
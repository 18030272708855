import Card from 'react-bootstrap/Card';
import Chart from 'react-apexcharts';
import StatusButton from '../../../UI/status-button/StatusButton';
import {ApexOptions} from 'apexcharts';

function ResultProgress({report,title,backColor,color}:any) {
  let options: ApexOptions = {
    series: [report ? report : 0],
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    colors: [backColor],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#eeeeee',
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
   
    labels: ['Average Results'],
  };
  return (
    <Card className="text-center main-card">
      <Card.Body className="mb-4 mt-3">
        <Chart options={options} series={options.series} type="radialBar" />
        <div className='mt-4'>
        <StatusButton
          
          status={title}
          background="none"
          color={color}
        />
        </div>
      </Card.Body>
    </Card>
  );
}

export default ResultProgress;

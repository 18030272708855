import React, {useState, useEffect} from 'react';
import {database} from '../Consultants/components/firebase';
import {onValue, ref} from 'firebase/database';
import {colors} from '../../constants/colors';
import moment from 'moment';
import {Col, Container, Row} from 'react-bootstrap';
import {NavLink, useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/hooks';
import {changeNotificationNumber} from '../../store/userSlice';
import { getChatDomain } from '../../helpers/getChatDomain';

const ChatList = ({route}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [whatsAppStatus, setWhatsAppStatus] = useState(false);
  const [whatsAppNumber, setWhatsAppNumber] = useState('');
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const fillMessenger = async () => {
    try {
      let myId = localStorage.getItem('id');
      const query = ref(database, `${getChatDomain()}/${myId}`);
      onValue(query, snapshot => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          const messenger = snapshot.val();
          let readCount = 0;
          messengerGenerator(messenger);
          Object.values(messenger).forEach(lastObject => {
            const keys = Object.keys(lastObject);
            console.log('keys', keys);
            let lastMessage = Object.keys(lastObject).sort((a, b) => b - a)[
              Object.keys(lastObject).length - 1
            ];
            const lastItem = lastObject[lastMessage];
            if (lastItem.notRead) {
              readCount++;
            }
            dispatch(changeNotificationNumber(readCount));
          });
        }
      });
      // await ref.on('value', snap => {
      //   if (snap.exists()) {
      //     const messenger = snap.val();
      //     messengerGenerator(messenger);
      //   } else {
      //     setLoading(false);
      //   }
      // });
    } catch (e) {
      console.log('fill messenger error', e);
    }
  };
  const messengerGenerator = async messenger => {
    try {
      let arr = [];
      let arr2 = [];
      let myId = localStorage.getItem('id');
      for (let j in messenger) {
        let otherMessage;

        let chat = messenger[j]; // timestamp in doctors
        Object.keys(chat)
          .sort((a, b) => b - a)
          .some((val, index) => {
            if (chat[val].senderUID != myId) {
              otherMessage = chat[val];
            }
          });
        let lastMessage = Object.keys(chat).sort((a, b) => b - a)[
          Object.keys(chat).length - 1
        ];
        console.log('lastMessage', lastMessage);

        let displayText;
        let tim = Number(lastMessage) / -1000;
        // let date = moment.unix(tim).locale('en').format('hh:mm a');
        const messageDate = moment(lastMessage / -1);
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        if (messageDate.isSame(today, 'day')) {
          // Display the time if the message is from today
          displayText =
            //  timeZone.includes('Cairo')
            //   ? messageDate.zone('+0300').locale('en').format('hh:mm A')
            //   :
            messageDate.locale('en').format('hh:mm A');
        } else if (messageDate.isSame(yesterday, 'day')) {
          // Display "yesterday" if the message is from yesterday
          displayText = 'yesterday';
        } else {
          // Display the date for messages from previous days
          displayText = messageDate.locale('en').format('YYYY-MM-DD');
        }
        // let date = moment.unix(tim).fromNow();
        let lastMessageVal = chat[lastMessage];
        arr2.push({
          title: otherMessage?.title ?? lastMessageVal.title,
          date: displayText,
          message: lastMessageVal.message,
          time: lastMessage,
          avatar: otherMessage?.avatar ?? lastMessageVal.avatar,
          current: myId,
          guest:
            lastMessageVal.reciverUID == myId
              ? lastMessageVal?.senderUID
              : lastMessageVal?.reciverUID,
          recFCM: otherMessage?.FCM ?? lastMessageVal.FCM,
          notRead: lastMessageVal?.notRead,
        });
      }
      setData(arr2.sort((a, b) => a.time - b.time));
      console.log(
        'arr2.sort((a, b) => a.time - b.time)',
        arr2.sort((a, b) => a.time - b.time),
      );
      setLoading(false);
      return arr;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fillMessenger();
  }, []);

  // const getWhatsStatus = () => {
  //   database()
  //     .ref(`whatsapp/status`)
  //     .once('value', snap => {
  //       if (snap.exists()) {
  //         const val = snap.val();
  //         setWhatsAppStatus(val ?? false);
  //       }
  //     });
  // };
  // const getWhatsNumber = () => {
  //   database()
  //     .ref(`whatsapp/whatsappnumber`)
  //     .once('value', snap => {
  //       if (snap.exists()) {
  //         const val = snap.val();
  //         setWhatsAppNumber(val ?? '');
  //       }
  //     });
  // };

  // useEffect(() => {
  //   setLoading(true);
  // }, []);
  const renderItems = item => {
    console.log('itemitemitem', item);
    return (
      <div>
        <img
          src={
            item?.avatar && item?.avatar != 'null'
              ? item?.avatar
              : '/icons/copy.png'
          }
          style={{
            height: 50,
            width: 50,
            borderRadius: 25,
            marginHorizontal: 5,
          }}
        />
        <div>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginVertical: 3,
            }}>
            <h2
              style={{
                color: colors.chamThirdColor,
                fontSize: 14,
                textAlign: 'left',
              }}>
              {item?.title}
            </h2>

            <h3
              style={{
                color: colors.font2,
                fontSize: 12,
                marginHorizontal: 4,
              }}>
              {item?.date}
            </h3>
          </div>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <h3
              style={{
                color: item?.notRead ? '#29b35e' : colors.font,
                fontSize: 12,
                marginVertical: 1,
                maxWidth: '80%',
                textAlign: 'left',
              }}>
              {item?.message}
            </h3>
            {item?.notRead && (
              <div
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 40,
                  backgroundColor: '#29b35e',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginHorizontal: 10,
                }}></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container>
      <Row>
        <Col
          md={{span: 6, offset: 3}}
          style={{
            paddingHorizontal: 10,
            backgroundColor: 'white',
            marginTop: '2rem',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 4px 20px',
            padding: '2rem',
            borderRadius: '15px',
            cursor: 'pointer',
          }}>
          <NavLink to="https://wa.me/+16478059910" target="_blank">
            <div className="d-flex">
              <img
                src={'/icons/whatsapp3.png'}
                style={{
                  height: 70,
                  width: 70,
                  borderRadius: '50%',
                  marginRight: '1rem',
                }}
              />
              <div>
                <div
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginVertical: 3,
                  }}>
                  <h2
                    style={{
                      color: colors.chamThirdColor,
                      fontSize: 23,
                      textAlign: 'left',
                    }}>
                    Nualim Customer Service
                  </h2>
                </div>
                <div
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'rgb(124 124 124)',
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}>
                  Chat Now
                </div>
              </div>
            </div>
          </NavLink>
        </Col>

        <Col md={{span: 6, offset: 3}}>
          <h2 className="secondry-color mb-3 mt-4">My Chats</h2>
        </Col>
        <Col md={{span: 6, offset: 3}}>
          <div>
            {data.map(item => {
              return (
                <div
                  onClick={() => {
                    navigate(`/chat/${item.current}/${item.guest}`, {
                      state: {
                        current: item.current,
                        guest: item.guest,
                        senderName: localStorage.getItem('name'),
                        recName: item?.title,
                        senderAvatar: localStorage.getItem('avatar'),
                        recAvatar: item?.avatar,
                        senderFCM: 'await getToken()',
                        recFCM: item?.recFCM,
                      },
                    });
                  }}>
                  <div
                    className="d-flex mb-4 justify-content-between"
                    style={{
                      backgroundColor: 'white',
                      flex: 1,
                      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 4px 20px',
                      padding: '3rem',
                      borderRadius: '15px',
                    }}>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          item?.avatar && item?.avatar != 'null'
                            ? item?.avatar
                            : '/icons/manHolder.png'
                        }
                        style={{
                          height: 70,
                          width: 70,
                          borderRadius: '50%',

                          marginRight: '1rem',
                        }}
                      />
                      <div>
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginVertical: 3,
                          }}>
                          <h2
                            style={{
                              color: colors.chamThirdColor,
                              fontSize: 23,
                              textAlign: 'left',
                            }}>
                            {item?.title}
                          </h2>
                        </div>
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          <h3
                            style={{
                              color: item?.notRead ? '#29b35e' : colors.font,
                              fontSize: 16,
                              marginVertical: 1,
                              maxWidth: '80%',
                              textAlign: 'left',
                            }}>
                            {item?.message}
                          </h3>
                          {item?.notRead && (
                            <div
                              style={{
                                width: 18,
                                height: 18,
                                borderRadius: 40,
                                backgroundColor: '#29b35e',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginHorizontal: 10,
                              }}></div>
                          )}
                        </div>
                      </div>
                    </div>

                    <h3
                      style={{
                        color: colors.font2,
                        fontSize: 16,
                        margin: '5px',
                      }}>
                      {item?.date}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatList;

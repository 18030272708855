import Card from 'react-bootstrap/Card';
import './TrackingCard.css';
import {useNavigate} from 'react-router-dom';

function TrackingCard({track}: any) {
  console.log('tracktracktrack', track);

  const navigate = useNavigate();

  function handleClick() {
    navigate('/initiative/intro');
  }
  return (
    <Card
      className="initiative-card text-center"
      onClick={handleClick}
      style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <img
        src={track.image}
        style={{height: '200px', borderRadius: '15px 15px 0 0'}}
      />
      <Card.Body className="mt-3">
        <Card.Title className="initiative-card-title">{track.name}</Card.Title>
        <Card.Text className="initiative-provider">
          {track.provider_name}
        </Card.Text>
        <Card.Text className="mb-2 initiative-rate">
          <p className="text-muted">{track.description}</p>
          <div className="Date d-flex justify-content-around pl-3 pr-3">
            <div className="Start">
              <span className="m-1">Duration:</span>
              <strong>{track.duration} Days</strong>
            </div>
          </div>
          <div className="Price">
            {track.price == 0 ? 'Free' : `${track.price} ${track.user_currency}`}
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default TrackingCard;

export const HandleAPIParams = (
  url: string,
  params: {[key: string]: string},
) => {
  Object?.keys(params)?.forEach(key => {
    url += `${key}=${params[key]}&`;
  });

  return url?.substring(0, url?.length - 1);
};

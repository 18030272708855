import {Navigate} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    const isCons = localStorage.getItem('isCons');
    if(isCons != 'true' ) {
        return <Navigate to="/"  />
    }
 return children

};

export default ProtectedRoute;
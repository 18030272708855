import ReactStars from 'react-rating-stars-component';
import classes from './RateStars.module.css'
function RateStars({rateValue}) {
  console.log('rateValuerateValuerateValuerateValue', rateValue);
  return (
    <span className={`${classes.RateStars}`} >
      <ReactStars
        count={5}
        size={24}
        activeColor="#ffd700"
        edit={false}
        value={rateValue}
        isHalf={true}
      />
    </span>
  );
}

export default RateStars;

import moment from 'moment';
import {SlotData} from '../Screens/Booking/interface/IBooking';

export const groupDatesByDayOfWeek = (datesArray: SlotData[]) => {
  console.log('datesArraydatesArray', datesArray);

  const daysOfWeek: string[] = [
    'Saturday',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
  ];

  // Create an object to hold dates grouped by day of week
  const datesByDayOfWeek: {[key: string]: SlotData[]} = {};
  for (const day of daysOfWeek) {
    datesByDayOfWeek[day] = [];
  }
  console.log(
    'datesByDayOfWeekdatesByDayOfWeekdatesByDayOfWeek',
    datesByDayOfWeek,
  );

  for (const dateObj of datesArray) {
    const date = moment(dateObj.date);
    const dayOfWeek = date.locale('en').format('dddd');
    datesByDayOfWeek[dayOfWeek].push({
      ...dateObj,
    });
  }

  // Convert datesByDayOfWeek to an array of objects with dayOfWeek and dates properties
  const result = [];
  for (const day of daysOfWeek) {
    if (datesByDayOfWeek[day].length > 0) {
      result.push({
        dayOfWeek: day,
        dates: datesByDayOfWeek[day],
      });
    }
  }

  return result;
};

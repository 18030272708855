import classes from './CustomSelectRadio.module.css';
const CustomSelectRadio = ({text,forId,disabled,name,value,onRadioChange,checked,...res}) => {

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onRadioChange(selectedValue);
  };

  return ( 
    <p {...res} className={`${classes.RadioOption} ${disabled ? classes.DisabledInput : '' } `}  >
      <input
        type="radio"
        id={`${forId}`}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        value={value}
        checked={checked}
        />
      <label htmlFor={`${forId}`}>{text}</label>
    </p>
  );
};

export default CustomSelectRadio;

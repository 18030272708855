export const getLanguage = () => {
    const storedLanguage = localStorage.getItem('lang');
    document.body.lang = storedLanguage || 'en';
    return storedLanguage || 'en'; // Set 'en' as the default language if not found
  };
  
  // Function to set the language in localStorage
  export const setLanguage = (language) => {
    document.body.lang = language;
    localStorage.setItem('lang', language);
  };
import Card from 'react-bootstrap/Card';
import '../style/PersonalityAnalysisCard.css';
import Chart from 'react-apexcharts';
import StatusButton from '../../../UI/status-button/StatusButton';
import {ApexOptions} from 'apexcharts';
import {PersonalityAnalysisCardProp} from '../interface/IQuiz';
import { colors } from '../../../constants/colors';

function PersonalityAnalysisCard({report, title}: PersonalityAnalysisCardProp) {
  let options: ApexOptions = {
    series: [report?.percentage ? report.percentage : 0],
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    colors: ['#39b970'],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#eeeeee',
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      //type: 'gradient',
      //gradient: {
        //shade: 'dark',
        //type: 'vertical',
        //gradientToColors: ['#87D4F9'],
        //stops: [0, 100],
      //},
      colors: ( report?.percentage ? report.percentage : 0 ) >= 50 ? ['#39b970'] : [colors.chamThirdColor]  
    },
    labels: ['Average Results'],
  };
  return (
    <Card className="text-center main-card">
      <Card.Body className="mb-4 mt-3">
        <Chart options={options} series={options.series} type="radialBar" />
        
        <StatusButton
          status={ ( report?.percentage ? report.percentage : 0 ) >= 50 ? 'Pass' : 'Fail' }
         
          background={ ( report?.percentage ? report.percentage : 0 ) >= 50 ? 'rgba(57, 185, 112, .1)' : colors.lightred }
          color={ ( report?.percentage ? report.percentage : 0 ) >= 50 ? '#39b970' : colors.chamThirdColor }
        />
      </Card.Body>
    </Card>
  );
}

export default PersonalityAnalysisCard;

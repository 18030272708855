import {Col, Container, Row} from 'react-bootstrap';
import PersonalityAnalysisCard from './PersonalityAnalysisCard';
import {PersonalityAnalysisCardProp} from '../interface/IQuiz';
import MainButton from '../../../UI/main-button/MainButton';
import {useNavigate} from 'react-router-dom';
const UserQuizResult = ({report, title}: PersonalityAnalysisCardProp) => {
  const navigate = useNavigate();
  return (
    <Container>
      {/* personality analysis list     */}
      <section className="section">
        {/*
        <Row>
          <Col md={3} className="m-auto">
            <PersonalityAnalysisCard report={report} title={title} />
          </Col>
        </Row>
        */}
        <div>
          
          <Col md={3} className="m-auto">
            <PersonalityAnalysisCard report={report} title={title} />
          </Col>
          <div onClick={() => navigate(-1)}>
            <MainButton text={'Back'} />
          </div>
        </div>
      </section>
    </Container>
  );
};

export default UserQuizResult;

import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const GetTokens = async (id: string | number) => {
  const api = `/api/mobile/user/firebase/tokens/${id}`;
  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};

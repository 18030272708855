import {configureStore} from '@reduxjs/toolkit';
import coursesSlice from './coursesSlice';
import LanguageSlice from './LanguageSlice';
import ConsultantsSlice from './ConsultantsSlice';
import CartSlice from './CartSlice';
import userSlice from './userSlice';

const store = configureStore({
  reducer: {
    courses: coursesSlice,
    language: LanguageSlice,
    consultants: ConsultantsSlice,
    cart: CartSlice,
    user: userSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

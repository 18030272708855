import { OverlayTrigger, Popover } from "react-bootstrap";

function ZeroProgressPopover() {
  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
       
      }}>
      This activity does not affect your performance assessment because it does not include grades
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom}>
      <img src="/icons/info.png" style={{ width: '20px', height: '20px' , cursor: 'pointer' ,  margin:'0 10px' }} />
    </OverlayTrigger>
  );
}

export default ZeroProgressPopover;

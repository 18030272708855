import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useLocation, useParams} from 'react-router-dom';

import {useEffect, useState} from 'react';
import { getFamily } from './family.services';
import { NavLink } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { colors } from '../../constants/colors';


const UserFamily = () => {
  
  const navigate = useNavigate();
  const [childs, setChilds] = useState([]);
  
  
  useEffect(() => {
    getFamilyFn()
  }, []);
  

  const getFamilyFn = async () => {
    let result = await getFamily();
    if (result?.response?.status) {
      setChilds(result?.response?.data);
    }
  };

  return (
    <div>
      <Container>
        <Row className="d-flex">
        <Col md={3} className='mb-5 text-center'>
                    <NavLink to={'/add-child?status=add'}>  
                    <div>  
                    <FaPlus 
                    
                    style={{
                        color:colors.chamThirdColor ,
                        background:colors.lightred,
                        fontSize:'128px',
                        borderRadius: '50%',
                        padding: '3rem',
                        margin: '0 20px 0 0'
                      }}
                    />
                    </div>
                   </NavLink>
                   
                </Col>
            {childs?.map((i:any) => {
                return (
                
                <Col md={3} className='mb-5 text-center'>
                   
                    <div style={{ cursor: 'pointer' }} onClick={ () => {
                      navigate(`/add-child?id=${i.id}&status=edit` , {state : {
                        data : i
                      }} )
                    } } > 
                    <img src={i.avatar ?? '/kid.png' }
                    style={{
                        width:'128px',
                        height: '128px',
                        borderRadius: '50%',
                    }}
                   />
                   <p className='text-center text-dark mt-2'>{i.username}</p>
                   </div>
                  
                   
                </Col>
                
                );
            })}
          
        </Row>
      </Container>
    </div>
  );
};

export default UserFamily;

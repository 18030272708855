import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from './style/AvailabilityInstructions.module.css'
import Carousel from 'react-bootstrap/Carousel';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

interface ChildModalProps {
  show: boolean;
  onHide: () => void;
  handleClose: () => void;
}

const AvailabilityInstructions = ({ show, onHide , handleClose } :ChildModalProps ) => {

//const [show, setShow] = useState<boolean>(true);

const [showAddInstructions, setShowAddInstructions] = useState<boolean>(false);

const [showAddFutureAvailabilty, setShowAddFutureAvailabilty] = useState<boolean>(false);

const [showAddSingleSession, setShowAddSingleSession] = useState<boolean>(false);

const [showDeleteInstructions, setShowDeleteInstructions] = useState<boolean>(false);

const [showDeleteallSessionsForDay, setShowDeleteallSessionsForDay] = useState<boolean>(false);

const [showDeleteallSessionsForPeriod, setShowDeleteallSessionsForPeriod] = useState<boolean>(false);

const [showDeleteFutureSessions, setShowDeleteFutureSessions] = useState<boolean>(false);

const [showDeleteThisSession, setShowDeleteThisSession] = useState<boolean>(false);

const [index, setIndex] = useState(0);


const handleSelect = (selectedIndex:any) => {
    setIndex(selectedIndex);
};

  return (
    <div className="mt-md-5">
      {/*Show Availability Instructions pop up*/}
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <h5 className="mb-3">How can you add availability?</h5>

                <div className={`${classes.InstructionItem} mb-2`} onClick={ () => { 
                  handleClose()
                  setShowAddInstructions(true) } }>
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Add instructions</h6></NavLink>
                </div>

                <div className={`${classes.InstructionItem} mb-2 cursor-pointer`} onClick={ () => { 
                  handleClose()
                  setShowAddFutureAvailabilty(true) } }>
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Add future availability</h6></NavLink>
                </div>

                <div className={`${classes.InstructionItem} mb-2`} onClick={ () => { 
                  handleClose()
                  setShowAddSingleSession(true) } }>
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Add single availability</h6></NavLink>
                </div>
            </div>

            <div className="w-100 mt-4">
                <h5 className="mb-3">How can delete availability?</h5>
                
                <div className={`${classes.InstructionItem} mb-2`} onClick={ () => { 
                  handleClose()
                  setShowDeleteInstructions(true) } }>
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Delete instructions</h6></NavLink>
                </div>

                <div className={`${classes.InstructionItem} mb-2`} onClick={ () => { 
                  handleClose()
                  setShowDeleteallSessionsForDay(true) } } >
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Delete all lessons for the selected day </h6></NavLink>
                </div>

                <div className={`${classes.InstructionItem} mb-2`} onClick={ () => { 
                  handleClose()
                  setShowDeleteallSessionsForPeriod(true) } } >
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Delete availability for a period of time</h6></NavLink>
                </div>

                <div className={`${classes.InstructionItem} mb-2`}
                onClick={ () => { 
                  handleClose()
                  setShowDeleteFutureSessions(true) } }
                >
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Delete future lessons</h6></NavLink>
                </div>

                <div className={`${classes.InstructionItem} mb-2`} 
                 onClick={ () => { 
                  handleClose()
                  setShowDeleteThisSession(true) } }
                >
                    <NavLink to="#" className={`text-white`} ><h6 className="mb-0">Delete this lesson</h6></NavLink>
                </div>
            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show Add Instructions pop up*/}
      <Modal show={showAddInstructions} onHide={ () => setShowAddInstructions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <h5 className="mb-3">Add Instruction</h5>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>You can add availability from the current date up to 6 months, so you can always maintain availability for the next 6 months</span></p>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>You can add availability at any time but the added period can not be intersected with any previously added period</span></p>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>3</span> <span>You can add availability in bulk using the Add Future Availability feature, or availability for a specific day using the single Availability Add feature</span></p>

            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show Add Future Availabilty pop up*/}
      <Modal show={showAddFutureAvailabilty} onHide={ () => setShowAddFutureAvailabilty(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava1.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Click on the + icon at the top of the screen</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava2.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select add future availability</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava3.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Insert start date</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>Insert end date</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>3</span> <span>Select lesson duration from the available durations</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>4</span> <span>Select one of the 2 options of selected date of days which may be date range or single dates</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>5</span> <span>You can clear selected date by clicking on "Clear"</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava4.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select one or more single date form the calender</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava5.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select one or more date range from the calender by clicking on two dates so all the dates between them including the 2 selected dates will be selected</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava6.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>this is the view after adding the availability</span></p>
                </Carousel.Item>
                
                </Carousel>

                

            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show Add Single Session pop up*/}
      <Modal show={showAddSingleSession} onHide={ () => setShowAddSingleSession(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava1.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Click on the + icon at the top of the screen</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava2.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select add single lesson</span></p>
                </Carousel.Item>
                
                
                <Carousel.Item>
                    <img src='/screenshots/ava7.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Insert start date</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>Select lesson duration from the available durations</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava6.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>4</span> <span>this is the view after adding the availability</span></p>
                </Carousel.Item>
                
                </Carousel>

                

            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show Availability Instructions pop up*/}
      <Modal show={showDeleteInstructions} onHide={ () => setShowDeleteInstructions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <h5 className="mb-3">Delete Instructions</h5>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>You can delete any unbooked lesson</span></p>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>If you want to delete a booked lesson, you must cancel it first</span></p>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>3</span> <span>You can delete aggregated availability for a specific time period, day, or future similar lessons</span></p>

                <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>4</span> <span>You can delete single availability</span></p>

            </div>  

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show delete all session for a day pop up*/}
      <Modal show={showDeleteallSessionsForDay} onHide={ () => setShowDeleteallSessionsForDay(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava8.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Click on the trash icon for deletion at the top of the screen</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava9.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select delete all lessons for the selected day</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava10.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>A confirmation message appears to confirm deleting or cancel deleting</span></p>
                </Carousel.Item>
                </Carousel>
            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show delete all session for a period pop up*/}
      <Modal show={showDeleteallSessionsForPeriod} onHide={ () => setShowDeleteallSessionsForPeriod(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava8.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>click on the trash icon for deletion at the top of the screen</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava9.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select delete all lessons for a period of time</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava11.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select from the calender the start date and the end date for the period that you want to delete its availability</span></p>
                </Carousel.Item>
                </Carousel>
            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show delete all session for a period pop up*/}
      <Modal show={showDeleteallSessionsForPeriod} onHide={ () => setShowDeleteallSessionsForPeriod(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava8.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>click on the trash icon for deletion at the top of the screen</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava9.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select delete all lessons for a period of time</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava11.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select from the calender the start date and the end date for the period that you want to delete its availability</span></p>
                </Carousel.Item>
                </Carousel>
            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show delete future sessions pop up*/}
      <Modal show={showDeleteFutureSessions} onHide={ () => setShowDeleteFutureSessions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava12.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>click on the trash icon on any lesson</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava13.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select Delete future lesson</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava14.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>The appearance of multi-selection list of all similar lessons grouped according to the days of the week</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava15.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>You can select one or more similar lessons by clicking on their checkboxes</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>Click on delete button to confirm or back to cancel</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava16.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>A confirmation message appears displaying the selected period to confirm deleting or cancel deleting</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>Click on delete button to confirm or Cancel to cancel</span></p>
                </Carousel.Item>
                </Carousel>
            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

      {/*Show delete this session pop up*/}
      <Modal show={showDeleteThisSession} onHide={ () => setShowDeleteThisSession(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          
            <div className="w-100">
                <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                nextIcon={<FaArrowAltCircleRight color="#0576e3" size={30} />}
                prevIcon={<FaArrowAltCircleLeft color="#0576e3" size={30} />}
                indicators={false}
                >
                <Carousel.Item>
                    <img src='/screenshots/ava12.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>click on the trash icon on any lesson</span></p>
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/screenshots/ava13.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>Select Delete this lesson</span></p>
                </Carousel.Item>
               
                <Carousel.Item>
                    <img src='/screenshots/ava16.png' style={{width : '100%'}} alt="f" />
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>1</span> <span>A confirmation message appears displaying delete or cancel deleting</span></p>
                    <p className="d-flex mt-4"><span className={`${classes.ItemNumber}`}>2</span> <span>Click on delete button to confirm or Cancel to cancel</span></p>
                </Carousel.Item>
                </Carousel>
            </div>

          </Modal.Body>
       
          <Modal.Footer>
            

          </Modal.Footer>
    
      </Modal>

    </div>
  );
};

export default AvailabilityInstructions;

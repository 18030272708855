import {Card, Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FaCircle, FaEdit, FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileSignature, FaFileUpload, FaPaperclip, FaPlay, FaPlaystation, FaRegCheckSquare, FaRegEdit, FaRegFileAlt, FaYoutube, FaYoutubeSquare} from 'react-icons/fa';
import { IoGameControllerOutline } from "react-icons/io5";

import practicalTaskClasses from '../Courses/style/PracticalTaskUplaod.module.css';
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  addTrackNote,
  editTrackNote,
  sendInitiativeProf,
  updateProgress,
  updateProgressAutomatic,
} from '../Tracking/Trackings.service';
import { useAppDispatch , useAppSelector } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';

import {getActivityData} from './Initiatives.service';

import moment from 'moment';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import YouTube from 'react-youtube';
import InitiativeDetailsCard from './InitiativeDetailsCard';
import toast, {Toaster} from 'react-hot-toast';
import { getDomain } from '../../helpers/getDomain';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import { colors } from '../../constants/colors';
import ZeroProgressPopover from './components/ZeroProgressPopover';
import { t } from 'i18next';

const UserActvitiyAddProgress = () => {
  const videoOptions = {
    playerVars: {
      // You can add additional YouTube player options here
      autoplay: 0,
    },
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [lang, setLang] = useState(queryParams.get('lang') || 'en');

  
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  
  const [progressVal, setProgressVal] = useState<any>(1);
  const [showProgressVal, setShowProgressVal] = useState<any>(false);

  const [showGame, setShowGame] = useState<boolean>(false);

  const [confirmUpdate, setConfirmUpdate] = useState<boolean>(false);

  const [showAddNote, setShowAddNote] = useState<boolean>(false);

  const [confirmDeleteNote, setConfirmDeleteNote] = useState<boolean>(false);

  const [showAllNotes, setShowAllNotes] = useState<boolean>(false);

  const [allNote, setAllNote] = useState<any>([]);
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {actId, trackId, date, userId , daySelected } = useParams();

  const [activityData, setActivityData] = useState<any>();

  const [selectedNoteId, setSelectedNoteId] = useState<any>();

  const [selectedNoteValue, setSelectedNoteIdValue] = useState<any>();

  const [profMidea, setprofMidea] = useState<any>();
  
  
  //const daySelected = location.state.daySelected;
  const today = moment().locale('en').format('YYYY-MM-DD');

  const [userActivityId, setUserActivityId] = useState(null);

  const [showUpdateProgress, setShowUpdateProgress] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [autoUpdate, setAutoUpdate] = useState<number>(1);

  const [showYoutubeTask, setShowYoutubeTask] = useState<boolean>(false);

  const [showGameEndMsg, setshowGameEndMsg] = useState<boolean>(false);

  const hasHistory = queryParams.has('tracking_enrollment_history_id');
  

  const getData = async () => {
    dispatch(setLoading(true));
    let trackHistory = hasHistory ? queryParams.get('tracking_enrollment_history_id') : null
    let result = await getActivityData(actId!, daySelected!, userId!, trackId!,trackHistory);
    if (result.response) {
      dispatch(setLoading(false));
      console.log('progress data', result.response);
      setActivityData(result.response.data);
      setprofMidea(
        result.response.data?.media?.profs.length
          ? result.response.data?.media?.profs[0].media_path
          : null,
      );
      setAutoUpdate(result.response.data.auto_progress_update);
      if( result.response.data.auto_progress_update == 0 && result.response.data.progress){
        setProgress(result.response.data.progress)
      }
      window.scrollTo(0, 0);
    }
  };

  const getAllNote = async () => {
    dispatch(setLoading(true));
    let token = localStorage.getItem('token');
    
    let url = `/api/trackingsystem/note/all-activity-notes?activity_id=${actId}&current_user_id=${userId}&tracking_id=${trackId}`
   
    try {
      let result = await axios.get(getDomain() + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('dsadsadafdata', result.data.data);

      if (result.data.status) {
      dispatch(setLoading(false));
       setAllNote(result.data.data)
       setShowAllNotes(true)
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  const deleteNote = async (noteId: any) => {
    let token = localStorage.getItem('token');
    try {
      let result = await axios.delete(
        `${getDomain()}/api/trackingsystem/note/${noteId}`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (result.data.status) {
        setConfirmDeleteNote(false);
        setShowAllNotes(false)
        getData();
      }
    } catch (e) {}
  };

  const addNote = async (actId: any) => {
    let data = new FormData();
    data.append(`note`, selectedNoteValue);
    data.append(`user_tracking_activity_id`, actId);

    console.log(data);
    let result = await addTrackNote(data);

    if (result.response.status) {
      setShowAddNote(false);
      getData();
    }
  };

  const editNote = async () => {
    let data = new FormData();
    data.append('note', selectedNoteValue);
    data.append('id', selectedNoteId);
    let result = await editTrackNote(data);

    if (result.response.status) {
      setShow(false);
      setShowAllNotes(false)
      getData();
    }
  };

  const updateAuto = async (id: any, progress: any) => {
    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('progress', progress);
    data.append('media_id', id);
    let result = await updateProgressAutomatic(data);

    if (result.response.status) {
      getData();
    }
  };

  const handleFileChange = async (event: any) => {
    setShowProgressVal(true)
    dispatch(setLoading(true));
    const file = event.target.files[0];
    setSelectedFile(file);

    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('activity_id', String(actId));

    data.append('prof', activityData?.media?.profs[0]?.id ?? 0);
    const fileExtension = file.name.split('.').pop();

    if (
      [
        'png',
        'PNG',
        'jpg',
        'JPG',
        'jpeg',
        'JPEG',
        'webp',
        'WEBP',
        'svg',
        'SVG',
      ].includes(fileExtension)
    ) {
      data.append('type', 'document');
    } else {
      data.append('type', 'video');
    }

    data.append('file', activityData?.media?.profs[0]?.id ? 0 : file);
    //let result = await sendInitiativeProf(data);

    //if (result.response.status) {
      //getData();
    //}
    let token = localStorage.getItem('token');
    try {
      let result = await axios.post(getDomain() + '/api/trackingsystem/activities/add/prof', data , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: function(progressEvent:any) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          
          setProgressVal(percentCompleted)

        }
      });
      console.log('dsadsadafdata', result.data.data);

      if (result.data.status) {
        setShowProgressVal(false)
        setProgressVal(1)
        getData()
      }
    } catch (e) {
      dispatch(setLoading(false));
      showProgressVal(1)
      console.log(e);
    }
  };
  const removeProf = async () => {
    

    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('activity_id', String(actId));

    data.append('prof', activityData?.media?.profs[0]?.id ?? 0);
    data.append('type', 'document');

    data.append('file', '0');
    let result = await sendInitiativeProf(data);

    if (result.response.status) {
      getData();
    }
  };

  const handleClick = (e: any) => {
    const bar = e.currentTarget;

    const clickPosition = e.clientX - bar.getBoundingClientRect().left;

    const totalWidth = bar.clientWidth;
    let newProgress = (clickPosition / totalWidth) * 100;
    if (newProgress >= 95) {
      newProgress = 100;
    }
    setProgress(Math.floor(newProgress));
    if (newProgress >= 90) {
      setStyleProgress(85);
    } else {
      setStyleProgress(Math.floor(newProgress));
    }
  };

  const updateManualProgress = async () => {
    let data = new FormData();
    data.append('user_activity_id', String(userActivityId));
    data.append('progress', String(Math.floor(progress)));
    let result = await updateProgress(data);

    if (result.response.status) {
      setShowUpdateProgress(false);
      getData()
    }
  };

  let token = localStorage.getItem('token');

  const [topSmallAds, setTopSmallAds] = useState([]);
  const [bottomSmallAds, setBottomSmallAds] = useState([]);

  const getSmallAds = async () => {
    let api = `${getDomain()}/api/mobile/get/small/ads/Activity/${actId}`;

    try {
      let result = await axios({
        url: api,
        method: 'get',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('small ads', result?.data?.data);
      if(result?.data?.data.length){
        setTopSmallAds(result?.data?.data.filter((obj:any) => obj.position == 3))
        setBottomSmallAds(result?.data?.data.filter((obj:any) => obj.position == 4))
      }
    } catch (error) {}
  };

  useEffect(() => {
    
    getData();
    getSmallAds();
  }, []);

  return (
    <div className={`mt-md-5 position-relative`}>
      <Toaster position="top-right" />
      <Container>
        <Row className="d-flex justify-content-between">
          {/* <Col xs={12} lg={4} className="mt-3 mb-3">
            <InitiativeDetailsCard tracking={item!} />
          </Col> */}

          <Col xs={12} lg={12} className="mt-3">
            
            <Card style={{padding: '1rem 2rem' ,borderColor: 'green' , marginBottom: '2rem' }}>
            <div
                className="d-flex justify-content-between align-items-center"
                >
                <div>
                <h4>{activityData?.name}</h4>
                <div style={{fontSize: '14px'}} className={`${moment(activityData?.end_date).isSame(today) ? 'secondry-color' : '' } font-weight-bold`}>End Date: {moment(activityData?.end_date).isSame(today) ? 'Today' : activityData?.end_date}
                </div>
                </div>
                <div>
                  <CircleProgress value={activityData?.progress ?? 0 } />
                </div>
              </div>
              </Card>

            <div>
              {topSmallAds.length > 0 ? (
                <div className="mt-3 mb-3 cursor-pointer">
                  {topSmallAds.map((item:any) => (
                     <div
                     onClick={ () => {
                      if(item.model_name == 'Course' ){
                       
                        window.location.href = `/courses/${item.model_id}`
                      }else if(item.model_name == 'Consultant'){
                        window.location.href = `/consultants/organization/${item.model_id}`
                      }
                      else if( item.model_name == 'Tracking' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/tracking/${item.model_id}/details`
                        }
                      }
                      else if( item.model_name == 'TrackingPackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=1`
                        }
                      }
                      else if( item.model_name == 'Initiative' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/initiative/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/details?is_level=0`
                        }
                      }else if( item.model_name == 'InitiativePackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=1`
                        }
                      }
                    } }
                     ><img src={item.image} alt="img" className='w-100 rounded' /></div>
                  ))}
                </div>
              ) : (
              ''
              )}
            </div>

            <div className="mb-2">
              <h4>{t('About Activity')}</h4>
              <p className={`text-font-muted ${ lang == 'ar' ? 'ar-dir' : '' }`}>{activityData?.description}</p>
            </div>

            {activityData?.intro_video ? (
              <div className="mb-5" style={{ cursor: 'pointer' }}>
                <h4>{t('Intro Video')}</h4>
                <div className="media mt-3">
                  <div className="d-flex justify-content-between flex-column mb-3">
                    <div>
                    <FaPlay
                            className="activity-icon"
                            style={{
                              color:colors.chamThirdColor ,
                              background:colors.lightred,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '15px',
                              
                            }}
                            />
                      
                    </div>

                    <video
                      width="320"
                      height="240"
                      controls
                      className="mt-4"
                      style={{borderRadius: '15px'}}>
                      <source
                        src={activityData?.intro_video}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {activityData?.auto_progress_update ? (
              <div>
                {activityData?.media?.attachments?.length > 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Attachments')}</h4>
                    <div className="attachments mt-3">
                      {activityData?.media?.attachments.map((a: any) => {
                        return (
                          <div className="d-flex justify-content-between align-items-center mb-3"
                          
                          >
                            <div
                            onClick={() => {
                              if (!moment(daySelected).isAfter(today) || moment(daySelected).isSame(today) ) {
                                
                                window.open(a.media_path, '_blank');
                                daySelected == today &&
                                  updateAuto(a.id, 100);
                              } else {
                                toast.error("Can't open today");
                              }
                            }}
                            >
                            <FaRegFileAlt 
                             className="activity-icon"
                            style={{
                              color:a.percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:a.percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '10px',
                              
                            }}
                            />
                              <span>{a.name}</span>
                            </div>

                            <div>
                              <div className='d-flex align-items-center'>
                                {a.is_answered ? 
                                  <FaRegCheckSquare
                                    size={19}
                                    color="green"
                                    style={{
                                      verticalAlign: 'text-bottom',
                                      margin: '1.5rem',
                                    }}
                                  />
                                  :
                                  ''
                                }

                                  <div
                                    >
                                    <img
                                    onClick={ () =>  window.open(`${a.media_path}`,'_blank') }
                                      src="/icons/download.png"
                                      alt="image"
                                      style={{
                                        width: '50px',
                                        margin: '0 20px 0 0',
                                      }}
                                    />

                                        {a.percentage == 0 && ( <ZeroProgressPopover /> ) }
                                  </div>

                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {activityData?.media?.videos.length != 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}
                  onClick={ () => {
                    if( moment(daySelected).isAfter(today) ){
                      toast.error("Can't open today");
                    }
                  } }
                  >
                    <h4>{t('Media')}</h4>
                    <div className="media mt-3">
                      <div
                        className={`d-flex justify-content-between mb-3`}> 
                        <div>
                        <FaPlay
                           className="activity-icon"
                            style={{
                              color:activityData?.media?.videos[0].percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:activityData?.media?.videos[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '15px',
                             
                            }}
                            />
                          <span>{activityData?.media?.videos[0].name}</span>
                        </div>

                        <div className='d-flex align-items-center' >

                        

                        {activityData?.media?.videos[0].is_answered ? 
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        :
                        ''
                        }

                      {activityData?.media?.videos[0].percentage == 0 && ( <ZeroProgressPopover /> ) }

                      </div>

                        
                          
                          
                        
                      </div>

                      {
                        !moment(daySelected).isAfter(today) ? 
                        <video
                        width="320"
                        height="240"
                        controls
                        className="mt-4"
                        style={{borderRadius: '15px'}}
                        onEnded={() => {
                          daySelected == today &&
                            updateAuto(
                              activityData?.media?.videos[0].id,
                              100,
                            );
                        }}>
                        <source
                          src={activityData?.media?.videos[0].media_path}
                          type="video/mp4"
                        />
                      </video>
                      :
                      ''
                      }
                    </div>
                  </div>
                )}
                {activityData?.media?.quizs?.data && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Quiz')}</h4>
                    <div className="quiz mt-3">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3"
                        >
                        <div className="d-flex justify-content-between align-items-center"
                        onClick={() => {
                          if (!moment(daySelected).isAfter(today)) {
                            if (activityData?.media?.quizs?.is_answered !== 1)
                              navigate(`/tracking-system-quiz`, {
                                state: {
                                  questions: activityData?.media?.quizs.data,
                                  mediaId: activityData?.media?.quizs.id,
                                  userActivityId:
                                    activityData?.user_activity_id,
                                    
                                  percentage: activityData?.media?.quizs.percentage,  
                                },
                              });
                            else toast.error('Already Answered');
                          } else {
                            toast.error("Can't open today");
                          }
                        }}
                        >
                          
                          <FaRegEdit 
                           className="activity-icon"
                          style={{
                            color: activityData?.media?.quizs.percentage != 0 ? colors.chamThirdColor : colors.blue ,
                            background: activityData?.media?.quizs.percentage != 0 ? colors.lightred : colors.whiteBlue,
                            fontSize:'50px',
                            borderRadius: '10px',
                            padding: '10px',
                           
                          }}
                          />
                          <div>
                            <span>{t('Quiz Title')}</span>
                            <p className="m-0" style={{color: '#b3b3b3'}}>
                              {t('Quiz')},{' '}
                              {activityData?.media?.quizs.data.questions.length}{' '}
                              {t('Questions')}
                            </p>
                          </div>
                        </div>

                        <div className='d-flex align-items-center'>
                        {activityData?.media?.quizs?.is_answered == 1 ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {activityData?.media?.quizs.percentage == 0 && ( <ZeroProgressPopover /> ) }

                        </div>
                        

                        
                      </div>
                    </div>
                  </div>
                )}
                {activityData?.media?.game.length != 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Game')}</h4>
                    <div
                      className="media mt-3"
                      onClick={() => {
                       
                        if (moment(daySelected).isSame(today)) {
                          setShowGame(true);
                        }else if (moment(daySelected).isBefore(today)) {
                          setshowGameEndMsg(true);
                        }
                        else {
                          toast.error("Can't open today");
                        }
                      }}>
                      <div
                        className={`d-flex justify-content-between align-items-center mb-3`}>
                        <div>
                        <IoGameControllerOutline 
                           className="activity-icon"
                            style={{
                              color:activityData?.media?.game[0].percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:activityData?.media?.game[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '10px',
                             
                            }}
                            />
                          <span>{t('Game')}</span>
                        </div>

                        <div className='d-flex align-items-center'>     
                        {activityData?.media?.game[0].is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {activityData?.media?.game[0].percentage == 0 && ( <ZeroProgressPopover /> ) }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activityData?.media?.social_media.length != 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!moment(daySelected).isAfter(today)) {
                      setShowYoutubeTask(true);
                    } else {
                      toast.error("Can't open today");
                    }
                  }}
                  >
                    <h4>{t('YouTube')}</h4>
                    <div className="media mt-3">
                      <div
                        className={`d-flex justify-content-between align-items-center mb-3`}>
                        <div>
                        <FaYoutube
                             className="activity-icon"
                            style={{
                              color:activityData?.media?.social_media[0].percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:activityData?.media?.social_media[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '10px',
                             
                            }}
                            />
                          <span>{t('YouTube')}</span>
                        </div>

                        <div className='d-flex align-items-center'>
                        {activityData?.media?.social_media[0].is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {activityData?.media?.social_media[0].percentage == 0 && ( <ZeroProgressPopover /> ) }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                      
                {activityData?.prof != 0 && (
                <div className="mb-5" style={{ cursor: 'pointer' , position: 'relative' }}>
                  {
                     moment(daySelected).isAfter(today) || hasHistory
                     
                     ?
                 
                  <div
                    style={{ 
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top:'0',
                      left:'0',
                      zIndex: '2',
                  }}
                  onClick={ () => {
                    if( moment(daySelected).isAfter(today) || hasHistory || moment(daySelected).isBefore(today) ){
                      toast.error("Can't open today");
                    }
                  } }
                  >
                  </div>
                  :
                  ''
                   }
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4>{t('Add Proof')}</h4>
                  </div>

                  <div className="proof text-center">
                    { activityData?.media?.profs.length == 0 && (
                      <div>
                        <div
                          className={`${practicalTaskClasses.UploadBtnWrapper} w-100 mb-4 mt-3`}
                        
                          >
                          <button className="w-100 p-4">
                          
                              <img src="/practical-task/clip.png" />
                            <div
                              style={{
                                color: '#0576e3',
                                marginTop: '1rem',
                                fontWeight: 'normal',
                              }}>
                              <strong style={{textDecoration: 'underline'}}>
                                {t('Click')}
                              </strong>{' '}
                              {t('to attach your file')}
                            </div>
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            className={practicalTaskClasses.UploadBtnWrapper}
                            onChange={handleFileChange}
                            id="pictureX"
                            disabled={moment(daySelected).isAfter(today)}
                          />
                        </div>
                        { showProgressVal && (<ProgressBar now={progressVal} label={`${progressVal}%`} />)}
                      </div>
                    )}
                   
                    {((activityData?.media?.profs.length && (!moment(daySelected).isAfter(today) || moment(daySelected).isSame(today))) ) ? (
                      <div
                        className={`d-flex justify-content-between align-items-center`}>
                          <div className='d-flex align-items-center'>
                          <FaPaperclip
                          className="activity-icon"
                            style={{
                              color:colors.chamThirdColor ,
                              background:colors.lightred,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '12px',
                             
                            }}
                            />
                        <div>{t('Proof Added')}</div>  
                        {
                        profMidea ? 
                        <div
                          onClick={() => {
                            if (!moment(daySelected).isAfter(today)) {
                              window.open(activityData?.media?.profs[0].media_path, '_blank');
                            } else {
                              toast.error("Can't open today");
                            }
                          }}>
                          <img
                            src="/icons/open-eye.png"
                            alt="image"
                            style={{
                              margin:'0 10px',
                              cursor:'pointer'
                            }}
                          />
                        </div>
                        : ''
                      }
                      </div>

                  {activityData?.media?.profs.length &&
                    activityData?.media?.profs[0].status == 0 &&
                    activityData?.media?.profs[0].prof_remarkable == 1 ? (
                      <span
                        style={{
                          border: '1px solid #0576e3',
                          padding: '5px',
                          borderRadius: '10px',
                          color:colors.chamThirdColor,
                          fontWeight: '500',
                        }}>
                        {t('waiting for marking')}
                      </span>
                    ) : (
                      ''
                    )}

                       

                   

                     <div className='d-flex align-items-center'>  

                      {activityData?.media?.profs.length &&
                    activityData?.media?.profs[0].status != 1 ? (
                        <div
                          onClick={() => {
                            
                            removeProf()
                            setprofMidea(null);
                            setSelectedFile(null);
                          }}>
                          <img
                            alt="img"
                            src="/practical-task/close.png"
                            style={{width: '20px'}}
                            
                          />
                        </div>

                        ) : (
                          ''
                        )}
                           
                    {activityData?.media?.profs.length &&
                    activityData?.media?.profs[0].status == 1 ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem 0',
                            }}
                          />
                        ) : (
                          ''
                        )}

                      {activityData?.media?.profs[0].percentage == 0 && ( <ZeroProgressPopover /> ) }

                        </div>

                      </div>

                    )
                  :
                  ''
                  }

                   
                  </div>
                  

                </div>
                )}
              </div>
            ) : (
              ''
            )}

            <div>
              {bottomSmallAds.length > 0 ? (
                <div className="mt-3 mb-5 cursor-pointer">
                  {bottomSmallAds.map((item:any) => (
                     <div
                     onClick={ () => {
                      if(item.model_name == 'Course' ){
                       
                        window.location.href = `/courses/${item.model_id}`
                      }else if(item.model_name == 'Consultant'){
                        window.location.href = `/consultants/organization/${item.model_id}`
                      }
                      else if( item.model_name == 'Tracking' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/tracking/${item.model_id}/details`
                        }
                      }
                      else if( item.model_name == 'TrackingPackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=1`
                        }
                      }
                      else if( item.model_name == 'Initiative' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/initiative/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/details?is_level=0`
                        }
                      }else if( item.model_name == 'InitiativePackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=1`
                        }
                      }
                    } }
                     ><img src={item.image} alt="img" className='w-100 rounded' /></div>
                  ))}
                </div>
              ) : (
              ''
              )}
            </div>

            <div className="mb-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="m-0">{t('Notes')}</h4>
                <div
                 
                  style={{
                    color: '#0576e3',
                    fontWeight: '500',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                    
                  }}
                  onClick={() => getAllNote()}>
                  {t('See all')}
                </div>
              </div>

              {activityData?.notes?.length > 0 && (
                <div>
                  {activityData?.notes.map((n: any) => {
                    return (
                      <div className="media mt-3">
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src="/icons/file.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <div className="w-100">
                            <span>{n.note}</span>
                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                className="m-0 w-100"
                                style={{color: '#b3b3b3'}}>
                                {moment(n.note_added_date)
                                  .locale('en')
                                  .format('D MMM YYYY')}
                              </p>
                              <div className="d-flex align-items-end">
                                <p
                                  className="cursor-pointer"
                                  style={{color: '#0acad7', margin: '0 20px'}}
                                  onClick={() => {
                                    setShow(true);
                                    setSelectedNoteIdValue(n.note);
                                    setSelectedNoteId(n.id);
                                  }}>
                                  Edit
                                </p>
                                <p
                                  className="m-0 secondry-color cursor-pointer"
                                  onClick={() => {
                                    setConfirmDeleteNote(true);
                                    setSelectedNoteId(n.id);
                                  }}>
                                  Delete
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {!hasHistory && (

              <div>
                <p
                  className="cursor-pointer secondry-color mt-4"
                  style={{fontSize: '18px'}}
                  onClick={() => {
                    setShowAddNote(true);
                  }}>
                  + {t('Add new note')}
                </p>
              </div>
              ) }
            </div>

            {autoUpdate == 0 && daySelected == today ? (
              <div
                className="mb-5"
                onClick={() => {
                  setUserActivityId(activityData?.user_activity_id);
                  setShowUpdateProgress(true);
                }}>
                <MainButton text={'Update Progress'} />
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>

      {/*Edit Note*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Edit Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}>
              {selectedNoteValue}
            </textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => editNote()}>
            <MainButton text={'Edit note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Add Note*/}
      <Modal show={showAddNote} onHide={() => setShowAddNote(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Add Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}></textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() =>
              selectedNoteValue && addNote(activityData.user_activity_id)
            }>
            <MainButton text={'Add note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete note*/}
      <Modal
        show={confirmDeleteNote}
        onHide={() => setConfirmDeleteNote(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              Are you sure that you want to delete the selected note ?
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => deleteNote(selectedNoteId)}>
            <MainButton text={'Confirm'} />
          </div>
          <div onClick={() => setConfirmDeleteNote(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Note List*/}
      <Modal show={showAllNotes} onHide={() => setShowAllNotes(false)} centered>
        

        <Modal.Body>
        {allNote.length > 0 && (
          <div>
            {allNote.map((n: any) => {
              return (
                <div className="media mt-3">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src="/icons/file.png"
                      alt="image"
                      style={{width: '50px', margin: '0 20px 0 0'}}
                    />
                    <div className="w-100">
                      <span>{n.note}</span>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          className="m-0 w-100"
                          style={{color: '#b3b3b3'}}>
                          {moment(n.note_added_date)
                            .locale('en')
                            .format('D MMM YYYY')}
                        </p>
                        <div className="d-flex align-items-end">
                          <p
                            className="cursor-pointer"
                            style={{color: '#0acad7', margin: '0 20px'}}
                            onClick={() => {
                              setShow(true);
                              setSelectedNoteIdValue(n.note);
                              setSelectedNoteId(n.id);
                            }}>
                            Edit
                          </p>
                          <p
                            className="m-0 secondry-color cursor-pointer"
                            onClick={() => {
                              setConfirmDeleteNote(true);
                              setSelectedNoteId(n.id);
                            }}>
                            Delete
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
         {allNote.length == 0 && (
          <h5 className='text-center'>There is no data found...</h5>
         )}
        </Modal.Body>

       
      </Modal>

      {/*Update Progress manually*/}
      <Modal
        show={showUpdateProgress}
        onHide={() => setShowUpdateProgress(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '10px',
              backgroundColor: '#ccc',
              position: 'relative',
              borderRadius: '15px',
              cursor: 'pointer',
              marginTop: '25px',
            }}>
            <span
              className={classes.ProgressValue}
              style={{
                color: '#fff',
                background: '#0576e3',
                borderBottom: '10px solid #0576e3',
                left: `${styleProgress}%`,
              }}>
              {progress}%
            </span>
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: 'green',
                position: 'absolute',
              }}></div>
          </div>
          <div className="d-flex justify-content-between">
            <div>0%</div>
            <div>100%</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => updateManualProgress()}>
            <MainButton text={'Done'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Update Game*/}
      <Modal
        show={showGame}
        onHide={() => setShowGame(false)}
        centered
        size="lg">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <iframe
            title="Your iFrame Title"
            style={{width: '100%', height: '400px'}}
            src={
              activityData?.media?.game.length
                ? activityData?.media?.game[0].media_path
                : ''
            }
            frameBorder="0"
            allowFullScreen></iframe>
        </Modal.Body>

        <Modal.Footer>
          { moment(daySelected).isSame(today) && (
          <div
            className="w-100"
            onClick={() => {
              setConfirmUpdate(true);
            }}>
            <MainButton text={'Submit'} />
          </div>
          ) }
           { moment(daySelected).isBefore(today) && (
          <div
            className="w-100"
            onClick={() => {
              setShowGame(false)
            }}>
            <MainButton text={'Cancel'} />
          </div>
          ) }
        </Modal.Footer>
      </Modal>

      {/*Confirm update game*/}
      <Modal
        show={confirmUpdate}
        onHide={() => setConfirmUpdate(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="w-100 mb-3"
            onClick={() => {
              daySelected == today &&
                updateAuto(activityData?.media?.game[0].id, 100);
              setConfirmUpdate(false);
              setShowGame(false);
            }}>
            <MainButton text={'Continue With Saving'} />
          </div>
          <div
            onClick={() => {
              setConfirmUpdate(false);
              setShowGame(false);
            }}
            className="w-100">
            <CancelButton text={'Continue Without Saving'} />
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>

      {/*Update social*/}
      <Modal
        show={showYoutubeTask}
        onHide={() => setShowYoutubeTask(false)}
        centered
        size="lg">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {activityData?.media?.social_media.length ?
          <div className='social-popup'>
          <YouTube
            videoId={
              activityData?.media?.social_media[0].media_path
            }
            opts={videoOptions}
            onEnd={() => {
              daySelected == today &&
                updateAuto(
                  activityData?.media?.social_media[0].id,
                  100,
                );
                setShowYoutubeTask(false)
            }}
          />
          </div>
          :
          ''
           }

        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() => {
              setConfirmUpdate(true);
            }}>
           
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm prev game*/}
      <Modal
        show={showGameEndMsg}
        onHide={() => setshowGameEndMsg(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
            You can open this task but without getting the progress because the deadline has passed
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => {
            setshowGameEndMsg(false)
            setShowGame(true)

          }}>
            <MainButton text={'Confirm'} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserActvitiyAddProgress;

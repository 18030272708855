import {useEffect} from 'react';
import {DyteMeeting} from '@dytesdk/react-ui-kit';
import {useDyteClient} from '@dytesdk/react-web-core';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

function Meet() {
  const [meeting, initMeeting] = useDyteClient();
  const {authToken} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      initMeeting({
        authToken,
      });
    }
  }, []);

  meeting?.meta.addListener('disconnected', () => {
    navigate(-1);
  });

  return (
    <DyteMeeting
      meeting={meeting!}
      style={{
        zIndex: 10000,
      }}
    />
  );
}

export default Meet;

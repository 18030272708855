import {useEffect, useState} from 'react';
import {app, database} from '../Consultants/components/firebase';
import {
  ref,
  orderByKey,
  endAt,
  get,
  limitToLast,
  query,
  onValue,
  off,
  update,
  set,
} from 'firebase/database';
import {colors} from '../../constants/colors';
import moment from 'moment';
import classes from '../Consultants/style/Chat.module.css';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {useLocation, useParams} from 'react-router-dom';
import {GetTokens} from './Chat.services';
import { getChatDomain } from '../../helpers/getChatDomain';

const Chat = ({}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {userId, guestId} = useParams();
  const [tokens, setTokens] = useState([]);
  const [messages, setMessages] = useState([]);
  const [oldMessages, setOldMessages] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [values, setValues] = useState({});
  const [modifiedM, setModifiedM] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  useEffect(() => {
    chatListen();
    return () => {
      const refPath = ref(database, `${getChatDomain()}/${userId}/${guestId}/`);
      off(refPath);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getUserTokens(guestId);
  }, []);

  const getUserTokens = async id => {
    const result = await GetTokens(id);
    setTokens(result?.response?.data);
  };

  const groupDate = messageDate => {
    if (messageDate) {
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');
      if (messageDate?.isSame(today, 'day')) {
        // Display the time if the message is from today
        return 'today';
      } else if (messageDate?.isSame(yesterday, 'day')) {
        // Display "yesterday" if the message is from yesterday
        return 'yesterday';
      } else {
        // Display the date for messages from previous days
        return moment(messageDate).locale('en').format('MMM D, Y');
      }
    } else {
      return moment().locale('en').format('MMM D, Y');
    }
  };

  const fetchData = async () => {
    dispatch(setLoading(true));
    let val;

    const refPath = ref(database, `${getChatDomain()}/${userId}/${guestId}/`);
    const filter =
      oldMessages !== ''
        ? limitToLast(100, orderByKey(endAt(oldMessages)))
        : limitToLast(100, orderByKey());

    try {
      const query1 = query(refPath, filter);
      const snapshot = await get(query1, refPath);

      if (snapshot.exists()) {
        val = snapshot.val();
      }

      if (!val) {
        val = {};
      }
      const messages = {...val, ...values};

      const messagesKeys = Object.keys(messages).sort((a, b) => b - a);
      let messagesValues = [];

      messagesKeys.forEach(timeStamp =>
        messagesValues.push(messages[timeStamp]),
      );
      setMessages(messagesValues.reverse());
      const modifiedData = [];

      let currentDate = null;

      messagesValues.reverse().forEach(item => {
        const itemDate = moment(item?.messageTime)
          .locale('en')
          .startOf('day')
          .format('MMMM D, YYYY');

        if (itemDate !== currentDate) {
          modifiedData.push({
            id: groupDate(moment(item?.messageTime)),
            text: itemDate,
            isHeader: true,
          });
          currentDate = itemDate;
        }

        modifiedData.push(item);
      });

      const refPath2 = ref(database, `${getChatDomain()}/${guestId}/${userId}/`);
      const filter2 = limitToLast(1, orderByKey());
      const query2 = query(refPath2, filter2);

      const x = await get(query2, refPath2);

      console.log('xxxxxxxxxxxx,', x.val());

      if (
        Object.values(x.val())[0].notRead == false &&
        modifiedData[modifiedData.length - 1].senderUID == userId
      ) {
        modifiedData.push({
          text: 'itemDate',
          isRead: true,
        });
      }

      console.log('modifiedData', modifiedData.reverse());
      setModifiedM(modifiedData.reverse());
      setOldMessages(messagesKeys[0]);
      setValues(messages);
    } catch (error) {
      console.error('messagesmessages', error);
    } finally {
      //window.scrollTo({
      //top: document.getElementById('footer').offsetTop - document.getElementById('footer').offsetHeight  ,
      //behavior: 'smooth',
      //});

      document
        .getElementById('sendMsg')
        .scrollIntoView({behavior: 'smooth', block: 'end'});

      dispatch(setLoading(false));
    }
  };
  const chatListen = async () => {
    let refPath = ref(database, `${getChatDomain()}/${userId}/${guestId}/`);
    const queryChat = query(refPath, limitToLast(100, orderByKey()));
    onValue(queryChat, snap => {
      let val = snap.val();
      if (!val) {
        val = {};
      }
      // set the val into messages then modify the state
      const messages1 = {...val, ...messages};

      const messagesKeys = Object.keys(messages1).sort((a, b) => {
        return b - a;
      });

      if (Object.keys(val).length !== 0) {
        const latestMessageKey = messagesKeys[messagesKeys.length - 1];
        update(
          ref(database, `${getChatDomain()}/${userId}/${guestId}/${latestMessageKey}`),
          {
            notRead: false,
          },
        );
      }
      let messagesValues = [];

      messagesKeys.forEach(timeStamp =>
        messagesValues.push(messages1[timeStamp]),
      );

      setValues(messages1);
      setOldMessages(messagesKeys[0]);

      setMessages(messagesValues.reverse());
      const modifiedData = [];
      let currentDate = null;
      messagesValues.reverse().forEach(item => {
        const itemDate = moment(item?.messageTime)
          .locale('en')
          .startOf('day')
          .format('MMMM D, YYYY');
        console.log('itemitemitem', item);
        if (itemDate !== currentDate) {
          modifiedData.unshift({
            id: groupDate(moment(item?.messageTime)),
            text: itemDate,
            isHeader: true,
          });
          currentDate = itemDate;
        }

        modifiedData.unshift(item);
      });
      let refPath2 = ref(database, `${getChatDomain()}/${guestId}/${userId}/`);
      const queryChat2 = query(refPath2, limitToLast(1, orderByKey()));

      get(queryChat2, refPath2)
        .then(snapshot => {
          if (
            snapshot.exists() &&
            Object.values(snapshot.val())[0].notRead === false &&
            modifiedData[modifiedData.length - 1].senderUID === userId
          ) {
            modifiedData.push({
              text: 'itemDate',
              isRead: true,
            });
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      console.log('modifiedData.reverse()', modifiedData);
      setModifiedM(modifiedData.reverse());
    });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() != '') {
      let newText1 = {
        message: newMessage,
        senderUID: userId,
        reciverUID: guestId,
        title: location?.state.recName,
        avatar: location?.state?.recAvatar ?? null,
        FCM: tokens,
        messageTime: new Date().getTime(),
        notRead: false,
      };
      let newText2 = {
        message: newMessage,
        senderUID: userId,
        reciverUID: guestId,
        title: location?.state?.senderName,
        avatar: location?.state?.senderAvatar ?? null,
        FCM: location?.state?.senderFCM,
        messageTime: new Date().getTime(),
        notRead: true,
      };
      let timestamp = 0 - new Date().getTime();

      update(
        ref(database, `${getChatDomain()}/${userId}/${guestId}/${timestamp}`),
        newText1,
      );

      update(
        ref(database, `${getChatDomain()}/${guestId}/${userId}/${timestamp}`),
        newText2,
      );

      setOldMessages(timestamp);
      setNewMessage('');
      document
        .getElementById('sendMsg')
        .scrollIntoView({behavior: 'smooth', block: 'end'});

      if (Array.isArray(tokens)) {
        for (let index = 0; index < tokens.length; index++) {
          const element = tokens[index];
          fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `key=AAAAcfwdGcE:APA91bGgM7dzmzBHrJQjYLCEORg6QZXzQsB63DGFHMXv4jsuI5Fro1BTDgpsYJkaNb2zTls2dBsaAShSDnKrX_H3NKpYf_QsmAV9FQM3dmQRALhC1WPbJybLQ_IuU-zPgdrxWpuha14y`,
            },
            body: JSON.stringify({
              to: element?.firebase_token,
              notification: {
                body: newMessage,
                title: location?.state?.senderName,
                android_channel_id: 'defaults',
              },
              data: {
                type: 'chat',
                avatar: location?.state?.senderAvatar ?? null,
                recFCM: location?.state?.senderFCM,
                guest: location?.state.current,
                title: location?.state?.senderName,
              },
            }),
          });
        }
      } else {
        fetch('https://fcm.googleapis.com/fcm/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `key=AAAAcfwdGcE:APA91bGgM7dzmzBHrJQjYLCEORg6QZXzQsB63DGFHMXv4jsuI5Fro1BTDgpsYJkaNb2zTls2dBsaAShSDnKrX_H3NKpYf_QsmAV9FQM3dmQRALhC1WPbJybLQ_IuU-zPgdrxWpuha14y`,
          },
          body: JSON.stringify({
            to: tokens,
            notification: {
              body: newMessage,
              title: location?.state?.senderName,
              android_channel_id: 'defaults',
            },
            data: {
              type: 'chat',
              avatar: location?.state?.senderAvatar ?? null,
              recFCM: location?.state?.senderFCM,
              guest: location?.state?.current,
              title: location?.state?.senderName,
            },
          }),
        });
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(/whatsAppBack.png)`,
      }}>
      <div style={{textAlign: 'center', padding: '2rem 0', display: 'none'}}>
        <img
          src="/icons/manHolder.png"
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            margin: '0 10px',
          }}
        />
        <strong>Ahemd</strong>
      </div>
      <div>
        {/* <FlatList
            data={modifiedM}
            ref={flatListRef}
            renderItem={renderMessage}
            keyExtractor={item => item.key}
            inverted
            showsVerticalScrollIndicator={false}
            onEndReached={() => fillChat3()}
          /> */}

        {modifiedM.map(item => {
          const isSender = item.senderUID === userId;
          const isHeader = item.isHeader;
          const isRead = item.isRead;
          return (
            <div
              style={{
                padding: '0rem 2rem',
                textAlign: isSender ? 'right' : 'left',
              }}>
              {isHeader ? (
                <h4
                  style={{
                    color: colors.font2,
                    textAlign: 'center',
                    fontSize: 20,
                    marginVertical: 2,
                    paddingTop: '40px',
                  }}>
                  {item?.id}
                </h4>
              ) : (
                <div
                  style={{
                    backgroundColor: isSender ? '#e1ffd3' : '#fff',
                    alignSelf: isSender ? 'flex-end' : 'flex-start',
                    borderRadius: 8,
                    paddingInline: 8,
                    marginBlock: 6,
                    display: 'inline-block', // Set to 'inline-block' to take content width
                    textAlign: isSender ? 'right' : 'left',
                    marginLeft: isSender ? 'auto' : '0', // Use auto margin to push to the right if isSender is true
                    marginRight: isSender ? '0' : 'auto',
                  }}
                  // onLongPress={() => setSelectedMessage(item)}
                  // activeOpacity={1}
                >
                  {/* <div> */}
                  {/* <div
                    // onLongPress={() => setSelectedMessage(item)}
                    // activeOpacity={1}
                    > */}
                  {/* <DetectLink
                            text={item?.message}
                            onLongPress={() => setSelectedMessage(item)}
                            linkColor={colors.blue}
                            style={{
                              fontFamily: getFont({}),
                              color: colors.black,
                              marginVertical: 4,
                            }}
                          /> */}
                  <div>
                    <p style={{margin: '5px'}}>{item?.message}</p>
                  </div>

                  <p
                    style={{
                      // fontFamily: getFont({}),
                      color: colors.font2,
                      alignSelf: 'flex-end',
                      fontSize: 14,
                      margin: '5px',
                    }}>
                    {
                      // timeZone.includes('Cairo')
                      //   ? moment(item?.messageTime)
                      //       .zone('+0300')
                      //       .locale('en')
                      //       .format('hh:mm a')
                      //   :
                      moment(item?.messageTime).locale('en').format('hh:mm a')
                    }
                  </p>
                  {/* </div> */}
                  {selectedMessage == item && (
                    <div
                      style={{
                        backgroundColor: '#c1e7df',
                        padding: 3,
                        width: '40%',
                        alignSelf: isSender ? 'flex-end' : 'flex-start',
                      }}>
                      <div
                        onClick={() => {
                          setSelectedMessage(null);
                        }}
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 6,
                        }}>
                        <p
                          style={{
                            color: colors.font,
                          }}>
                          copy
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
        {/* </ImageBackground> */}
      </div>
      {/* <KeyboardAvoidingView
        enabled={Platform.OS === 'ios'}
        keyboardVerticalOffset={10}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: Platform.OS === 'ios' ? 30 : 15,
          paddingHorizontal: 15,
          paddingTop: 7,
        }}
        behavior={'padding'}> */}

      <div className="d-flex" style={{padding: '30px'}} id="sendMsg">
        <input
          value={newMessage}
          onChange={i => setNewMessage(i.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleSendMessage();
            }
          }}
          placeholder={t('sendMessageChat')}
          style={{
            background: '#fff',
            color: '#000',
            width: '70%',
            border: '1px solid #fff',
            outline: 'none',
            padding: '10px 25px',
            borderRadius: '30px',
            flexGrow: '1',
          }}
        />
        <div
          onClick={handleSendMessage}
          style={{
            background: 'blue',
            width: 'fit-content',
            borderRadius: '50%',
            padding: '10px',
            marginLeft: '10px',
            cursor: 'pointer',
          }}>
          <img src="/send-message.png" width={25} height={25} />
        </div>
      </div>
    </div>
  );
};

export default Chat;

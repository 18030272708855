const CourseContentObj = [
    { 
        id: 1,
        title: 'Section 1: Intoduction',
        collapsed: true,
        lessons: [
            {
                id: 1,
                title:'Lesson 1 : Intoduction to soft skills',
                type: 'meet',
                day: '2023-06-10',
                from: '11:00 PM',
                to: '12:00 AM',
                url: 'https://meet.google.com/' 
            },
            {
                id: 2,
                title:'Lesson 2 : Intoduction to soft skills',
                type: 'meet',
                day: '2023-06-11',
                from: '10:00 PM',
                to: '11:00 PM' ,
                url: 'https://meet.google.com/'
            },
            {
                id: 3,
                title:'Lesson 3 : Intoduction to soft skills',
                type: 'meet',
                day: '2023-06-11',
                from: '10:00 PM',
                to: '11:00 PM' ,
                url: 'https://meet.google.com/'
            },
            {
                id: 4,
                title:'Lesson 3 : Intoduction to soft skills',
                type: 'meet',
                day: '2023-06-11',
                from: '10:00 PM',
                to: '11:00 PM' ,
                url: 'https://meet.google.com/'
            },
            
            
        ]
    
    },
]

export default CourseContentObj
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5McEtWHE93rJiDLrR0kSFZw5Tutcov84",
  authDomain: "nualim-8e61c.firebaseapp.com",
  databaseURL: "https://nualim-8e61c-default-rtdb.firebaseio.com",
  projectId: "nualim-8e61c",
  storageBucket: "nualim-8e61c.appspot.com",
  messagingSenderId: "489561070017",
  appId: "1:489561070017:web:80b2e224134bb0a4ddf740",
  measurementId: "G-7ZYHR3L0MX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);

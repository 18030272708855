import Card from 'react-bootstrap/Card';
import './Story.css'
import { StoriesItemProps } from '../../Screens/stories/IStories';
import { useEffect, useState } from 'react';

function Story({story}: StoriesItemProps) { 
  const [fontSize, setFontSize ] = useState
  (15);
  const HandleFontSize = (letters:any) => {
    if (letters.length > 0 && letters.length < 6) {
      setFontSize(60);
    } else if (letters.length > 6 && letters.length < 18) {
      setFontSize(40);
    } else if (letters.length > 18 && letters.length < 32) {
      setFontSize(15);
    }else{
      setFontSize(15);
    }
  };
  useEffect(() => {
    if (story?.data[0]?.story_type == 'text') {
      HandleFontSize(story?.data[0]?.data);
    }
  }, []);
  return (
    <>

    <Card className="story-card text-center" >
      
      <div style={ { 'position' : 'relative' , height: '180px' } }>
        { story?.data[0].story_type == 'image' && ( <Card.Img variant="top" src={story?.data[0]?.data_path ?? '/logo.png'} /> ) }
        { story?.data[0].story_type == 'video' && ( <Card.Img variant="top" src={story?.data[0]?.thumbnail_path ?? '/logo.png'} /> ) }
        { story?.data[0].story_type == 'text' && ( <p className='card-img-top d-flex justify-content-center align-items-center' style=
        {{color:story?.data[0]?.text_color,
          fontSize: fontSize,
          backgroundColor:story?.data[0]?.background_color,
          overflow: 'hidden'
        
        }}
        >
          {story?.data[0]?.data} 
        </p> ) }
        <img src={story?.data[0]?.consultant_image ?? '/icons/manHolder.png' } className='story-user' alt="img" />
      </div>
      <Card.Body className='mt-4 mb-2'>
        <Card.Title className="story-card-title" >{story?.data[0]?.consultant_name}</Card.Title>
      </Card.Body>
    </Card>
  </>

  );
}

export default Story;



import Card from 'react-bootstrap/Card';
import {NavLink} from 'react-router-dom';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import {Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import { TrackingItemProps } from './interfaces/ITrackings';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import classes from './style/TrackingCard.module.css'
import { repeatTrack } from './Trackings.service';
import moment from 'moment';

function TrackingDetailsCard({tracking}: TrackingItemProps) {

  const [showConfirmRepeat, setShowConfirmRepeat] = useState<boolean>(false);

  const repeatTracking = async () => {
    let trackId = tracking?.id;
    let currentUserId = tracking?.selected_enrolled_user_id

    let dataForm = new FormData();
    dataForm.append('current_user_id', String(currentUserId));
    let result = await repeatTrack(trackId, dataForm);
    if (result?.response?.status) {
      if (
        moment(new Date(), 'YYYY-MM-DD').isSameOrBefore(
          moment(tracking?.end_date, 'YYYY-MM-DD'),
          'day',
        )
      ) {
        //setDaySelected(moment().locale('en').format('YYYY-MM-DD'));
        //getStartAndEnd(false, moment().locale('en').format('YYYY-MM-DD'));
      } else {
        //Navigation.back();
      }
    } else {
      
    }
  };
  
  return (
    <>
      <Card className="text-center">
        
        <Card.Body className="mt-3">
          <Card.Title>
            <div className="mb-3">{tracking?.name}</div>
          </Card.Title>

          
         
            
          <Card.Title>
            <div className="d-flex justify-content-center align-items-center">
              <span style={{fontSize: '1.2rem', marginRight: '5px'}}>
                Total Score
              </span>
              <span>
                
                
                  <CircleProgress
                    value={
                      tracking?.total_progress % 1 === 0
                        ? tracking?.total_progress.toFixed(0)
                        : tracking?.total_progress.toFixed(1)
                    }
                  />
                
              </span>
            </div>
          </Card.Title>

          <Card.Title>
            <div className="p-3">
              <hr />

              <div className="d-flex justify-content-between align-items-center">
                {tracking?.has_unlimited_enrollment == 1 ? (
                  <NavLink to="/">
                    <img
                      src="/icons/assignment.png"
                      style={{
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />

                    <span className="secondry-color" style={{fontSize: '17px'}}>
                      Assign Family
                    </span>
                  </NavLink>
                ) : (
                  ''
                )}
                {tracking?.has_started_tracking == 1 && (
                  <div>
                    <img
                      src="/icons/reload2.png"
                      style={{
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />

                    <span
                      className="secondry-color"
                      style={{fontSize: '17px', cursor: 'pointer'}}
                      onClick={() => {
                        setShowConfirmRepeat(true);
                      }}>
                      Repeat
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Card.Title>
              
          

          
        </Card.Body>
      </Card>

      {/*Delete all availabilty for selected day*/}
      <Modal
        show={showConfirmRepeat}
        onHide={() => setShowConfirmRepeat(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <p className="w-100 mb-4" style={{ fontSize: '22px' }}>
              If you repeat this system now, you will loose any progress on it
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => repeatTracking()}>
            <MainButton text={'Repeat'} />
          </div>
          <div onClick={() => setShowConfirmRepeat(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      
    </>
  );
}

export default TrackingDetailsCard;

import {ComponentsProps} from '../interface/ISurvey';
import classes from '../style/Survey.module.css';

const Select = ({allData, index, options, selected}: ComponentsProps) => {
  return options?.map((i, key) => {
    return (
      <p className={classes.Question}>
        <input
          type="radio"
          id={`answer-${i.id}-${index}`}
          name={`radio-group-${index}`}
          onChange={() => {
            selected(i.id);
          }}
          checked={i.id == allData?.[index!].answer}
        />
        <label htmlFor={`answer-${i.id}-${index}`}>{i.option}</label>
      </p>
    );
  });
};

export default Select;

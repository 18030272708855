import Card from 'react-bootstrap/Card';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import {Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import { TrackingItemProps } from './interfaces/ITrackings';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import classes from './style/TrackingCard.module.css'
import {useTranslation} from 'react-i18next';
import { repeatTrack } from './Trackings.service';
import moment from 'moment';
import {setLoading} from '../../store/userSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import PopoverUi from './components/PopoverUi';


function TrackingDetailsCard({tracking,getData,setShowAssignUsers}: TrackingItemProps) {
  const {t} = useTranslation();
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showConsultants, setShowConsultants] = useState(false);
  const [showConfirmRepeat, setShowConfirmRepeat] = useState<boolean>(false);
  const [daySelected, setDaySelected] = useState<string | undefined>();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const packagePriceQueryParam = queryParams.get('package_price') || null ;

  const [cannotRepeat, setCannotRepeat] = useState('');

  const [showCannotRepeat, setShowCannotRepeat] = useState(false);

  const repeatTracking = async () => {
    dispatch(setLoading(true));
    let trackId = tracking?.id;
    let currentUserId = tracking?.selected_enrolled_user_id;

    let dataForm = new FormData();
    dataForm.append('current_user_id', String(currentUserId));
    let result = await repeatTrack(trackId, dataForm);

    
    if (result?.response?.status) {
      

      setShowConfirmRepeat(false);
      if (
        moment(new Date(), 'YYYY-MM-DD').isSameOrBefore(
          moment(tracking?.end_date, 'YYYY-MM-DD'),
          'day',
        )
      ) {
        setDaySelected(moment().locale('en').format('YYYY-MM-DD'));
        
      }
      if( getData ){
        getData(null);
      }
    }else{
          setShowConfirmRepeat(false)
          setCannotRepeat('Can not repeat follow-up system');
          setShowCannotRepeat(true)
          dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    console.log('tracking details for user' , tracking )
  }, []);

  return (
    <>

<div className='d-flex justify-content-between align-items-center share-dropdown mb-1'>
        <div className='d-flex align-items-center'>
        <h4 className='track-name'>{tracking?.name}</h4>
        <p className='track-provider'>{tracking?.provider_name}</p>
        </div>

        
        
        <div className='d-flex align-items-center cursor-pointer'>

          {/*show assign users*/}
        <div
        onClick={() => {
          if(setShowAssignUsers){
            setShowAssignUsers(true)
          }
          } }
              style={{
                padding: '5px 15px 5px 15px',
                border: '1px solid #E1E1E1',
                borderRadius: '6px',
                margin: '5px',
              }}
              className="d-flex justify-content-between align-items-center mb-3 mt-3">
              <div>
                <img
                  src={tracking?.selected_user_avatar ?? '/icons/manHolder.png'}
                  style={{
                    width: '30px',
                    height: '30px',
                    margin: '0 10px',
                    borderRadius: '50%',
                  }}
                />
                <span>{tracking?.selected_user_name}</span>
              </div>
              <div>
                <img src='/down_small_fill.svg' alt="img" />
              </div>
              
            </div>
        {/*show assign users*/}

        <div
        style={{
          padding: '7px 10px',
          border: '1px solid #E1E1E1',
          borderRadius: '6px',
          margin: '5px',
        }}
        >
            <PopoverUi name={tracking?.selected_user_name} />
        </div>
        
       


        
        </div>
        
      </div>
      <Card className="text-center">

      { (tracking?.price != 0 || packagePriceQueryParam  ) ? 
          <span className="top-label">
            { tracking?.has_unlimited_enrollment == 0 ? 'limited' : 'unlimited' }
          </span>
          :
          ''
          }
        
        <Card.Body className="mt-3 position-relative">

         
          {/*
          <Card.Title>
            <div className="mb-3">{tracking?.name}</div>
          </Card.Title>
          */}

          
         
            
          <Card.Title>
            <div className="d-flex justify-content-center align-items-center">
              <span style={{fontSize: '1.2rem', margin: '0 30px'}}>
                {t('TotalScore')}
              </span>
              <span>
                
                
                  <CircleProgress
                   progressColor='#e37e08'
                   background='#fff4e8'
                   titleColor='#e37e08'
                    value={
                      parseFloat(tracking?.total_progress) % 1 === 0
                        ? parseFloat(tracking?.total_progress).toFixed(0)
                        : parseFloat(tracking?.total_progress).toFixed(1)
                    }
                  />
                
              </span>
            </div>
          </Card.Title>
              
            
        


          

              <Card.Title>
                  <div className="p-3">
                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                      
                  <div>
                    <img
                      src="/requestCons.png"
                      style={{
                        width: '25px',
                        marginRight: '10px',
                      }}
                    />

                    <span
                      className="secondry-color"
                      style={{fontSize: '17px', cursor: 'pointer'}}
                      onClick={() => {
                        navigate(`/tracking/${tracking?.id}/${tracking?.selected_enrolled_user_id}/${tracking?.selected_user_name}/private-request`)
                      }}>
                      Supervision Requests
                    </span>
                  </div>

                       { 
                        (moment(new Date(), 'YYYY-MM-DD').isSameOrBefore( moment(tracking?.end_date, 'YYYY-MM-DD'),'day') &&
                        tracking?.has_started_tracking == 1 &&
                        tracking?.has_unlimited_enrollment == 0) ||
                      (tracking?.has_started_tracking == 1 &&
                        tracking?.has_unlimited_enrollment == 1)

                        && !tracking?.tracking_enrollment_history_id
                       
                       ?
                        <div>
                          <img
                            src="/icons/reload2.png"
                            style={{
                              width: '25px',
                              marginRight: '10px',
                            }}
                          />

                          <span
                            className="secondry-color"
                            style={{fontSize: '17px', cursor: 'pointer'}}
                            onClick={() => {
                              setShowConfirmRepeat(true);
                            }}>
                            Repeat
                          </span>
                        </div>
                    :
                    ''  
                    }
                    </div>
                  </div>
                </Card.Title>

          
        </Card.Body>
      </Card>

      {/*Delete all availabilty for selected day*/}
      <Modal
        show={showConfirmRepeat}
        onHide={() => setShowConfirmRepeat(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <p className="w-100 mb-4" style={{fontSize: '22px'}}>
            {
            moment(new Date(), 'YYYY-MM-DD').isSameOrBefore(moment(tracking?.end_date, 'YYYY-MM-DD'),'day')
            ?
            <p>If you repeat this system now, you will loose any progress on it</p>
            :
            <p>Now, you can re-take this system as you have finished it</p>
            }
              
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => repeatTracking()}>
            <MainButton text={'Repeat'} />
          </div>
          <div onClick={() => setShowConfirmRepeat(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*can not repeat pop up*/}
      <Modal
        show={showCannotRepeat}
        onHide={() => setShowCannotRepeat(false)}
        centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>
        <h4 className="text-center">{cannotRepeat}</h4>
        </Modal.Body>
        
        <Modal.Footer>
          
          <div onClick={() => setShowCannotRepeat(false)} className="w-100">
            <MainButton text={t('Ok')} />
          </div>
        </Modal.Footer>
      </Modal>

      

      
    </>
  );
}

export default TrackingDetailsCard;

import {Col, Container, Row} from 'react-bootstrap';
import TrackingCard from './components/TrackingCard';
import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getAllLevels} from './Trackings.service';
import {TrackingType} from './interfaces/ITrackings';
import ItemsPagination from '../../UI/pagination/ItemsPagination';

const TrackingLevelsList = () => {
  const location = useLocation();
  const item = location.state.item;
  const tab = location.state.tab;
  const [levelsPage, setLevelsPage] = useState(1);
  const [levelsLastPage, setLastLevelPage] = useState(1);
  const [levels, setLevels] = useState<TrackingType[]>([]);

  useEffect(() => {
    getLevels();
  }, [levelsPage]);

  const getLevels = async () => {
    let result = await getAllLevels(item.id, levelsPage, tab ? 1 : 0);
    if (result.response) {
      if (levelsPage == 1) {
        setLevels([...result.response.data]);
        setLevelsPage(result.response.meta.current_page);
        setLastLevelPage(result.response.meta.last_page);
      } else {
        setLevels([...levels, ...result.response.data]);
        setLevelsPage(result.response.meta.current_page);
        setLastLevelPage(result.response.meta.last_page);
      }
    }
  };

  return (
    <Container>
      <h1 className="page-title" style={{margin: '0 1rem'}}>
        Initiative Levels
      </h1>

      <Row>
        {levels.map(tracking => {
          return (
            <Col lg={6}>
              <TrackingCard tracking={tracking} levelList={true} />
            </Col>
          );
        })}
      </Row>
      <ItemsPagination
        pages={levelsLastPage}
        current={levelsPage}
        changePage={i => setLevelsPage(i)}
      />
    </Container>
  );
};

export default TrackingLevelsList;

import Card from 'react-bootstrap/Card';
import courseImg from '../images/courses/course-img.png';
import CourseCardLink from './course-card-link/CourseCardLink';
import RateStars from './rate-stars/RateStars';
import './CourseCard.css';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { CourseItemProps } from '../Screens/Courses/interfaces/ICourses';
import RateAndPrice from '../components/RateAndPrice/RateAndPrice';
import { colors } from '../constants/colors';



function ListCourseCard({course}: CourseItemProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    if (course.is_enrolled) {
      navigate(`/lessons/${course.id}`);
    } else {
      navigate(`/courses/${course.id}`);
    }
  }

  return (
    <Card className="course-card list-item-card" onClick={handleClick}>
      <p className="top-card-label">
        {/*
        <span className="price-label">
          {course.is_live ? t('live') : t('recorded')}
        </span>
        */}

        { course.is_enrolled ? 
        <span className='subscribed-label'>
          Enrolled
        </span>
        : '' }
        
      </p>
      <Card.Img variant="top" src={course.image ? course.image : courseImg} />
      <Card.Body>
        <Card.Title className="course-card-title card-custom-title mb-3">
          {course.title && course.title.length > 25
            ? course.title.slice(0, 25) + ' ...'
            : course.title}
        </Card.Title>
        <p className="text-muted" style={{ height : '25px' }}>
          {course.description &&
          course.description.length > 25
            ? course.description.slice(0, 25) + ' ...'
            : course.description}
        </p>
        <Card.Text className="card-custom-provider">
          {course.provider_data?.name}
        </Card.Text>
        <Card.Text className=''>
        {course?.is_limited == 1 &&
          !course?.is_enrolled &&
          course?.is_live == 1 && (
            <span
              style={{
                background: colors.chamThirdColor,
                padding: '5px 10px',
                borderRadius: '10px',
                color: '#fff',
              }}>
              {course?.has_reach_limit == 1
                ? `Sold out seats: ${course?.remaining_number} left of ${course?.limited_number} seats`
                : `Limited seats: ${course?.remaining_number} left of ${course?.limited_number} seats`}
            </span>
          )}

        <RateAndPrice
          ratings={course.ratings}
          ratings_count={course.ratings_count}
          subscribers={course.subscribers}
        />
        </Card.Text>
        <Card.Text
          style={{color: '#0576e3', fontSize: '18px', fontWeight: '700'}}>
          {course.price ? `${course.price} ${course.user_currency}` : t('Free')}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ListCourseCard;

import logo from './style/header.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Fragment, useEffect, useState} from 'react';
import CoursesFilter from './CoursesFilter';
import './style/Courses.css';
import CoursesList from './CoursesList';
import {getCoursesList} from './Courses.service';
import {courseProvider} from './interfaces/ICourses';
import {Enums} from '../../helpers/Enums';
import ListCoursesFilter from './ListCoursesFilter';
import SearchInput from '../../components/search-input/SearchInput';


const Courses = () => {
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const courseProvider: courseProvider[] = [
    {id: 0, name: Enums.Nurabi},
    {id: 1, name: Enums.Consultants},
    {id: 2, name: Enums.teachers},
    {id: 3, name: Enums.Coaches},
  ];
  const [learnType, setLearnType] = useState<string[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<number[]>([]);
  const [languagesSelected, setLanguagesSelected] = useState<number[]>([]);
  const [courseProviderSelected, setCourseProviderSelected] = useState<
    number[]
  >([]);
  const [pricing, setPricing] = useState<string[]>([]);

  useEffect(() => {
    getCourses();
  }, [
    learnType,
    categoriesSelected,
    languagesSelected,
    pricing,
    courseProviderSelected,
    page,
    title,
  ]);

  const getCourses = () => {
    getCoursesList({
      page: page,
      load: false,
      learnType: learnType,
      title: title,
      language: languagesSelected,
      categories: categoriesSelected,
      courseProvider: courseProviderSelected,
      price: pricing,
    });
  };

  const handleSearch = (searchTerm: any) => {
    setPage(1);
    setTitle(searchTerm)
  };

  const toggleFilter = () => {
    document.getElementById('filterContainer')?.classList.toggle('hidden-filter'); 
  }

  return (
    <div>
      <Fragment>
        <div className="contact-header d-flex justify-content-center align-items-center">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs={6} className="d-flex justify-content-center align-items-center">
                <div className="breadcrumb d-flex justify-content-center">
                  <h3 className="breadcrumb-header">Courses</h3>
                  <div className="breadcrumb-details text-center">
                    <span>Home</span> <span> - </span>{' '}
                    <span>Courses</span>
                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
        </div>

        <div className="content-details">
          <Container>
            <Row className="justify-content-between top-list-filters align-items-center">
              <Col md="8">
                  <ListCoursesFilter
                    setLearnType={setLearnType}
                    learnType={learnType}
                    setCategoriesSelected={setCategoriesSelected}
                    categoriesSelected={categoriesSelected}
                    setLanguagesSelected={setLanguagesSelected}
                    languagesSelected={languagesSelected}
                    setCourseProviderSelected={setCourseProviderSelected}
                    courseProviderSelected={courseProviderSelected}
                    setPricing={setPricing}
                    pricing={pricing}
                    courseProvider={courseProvider}
                    setPage={setPage}
                  />
              </Col>

              <Col md="4">
                <div className="row courses-top-head justify-content-between align-items-center">
                  <SearchInput onSearch={handleSearch} />
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between list-filter align-items-center">

              <Col md={12}>
                <CoursesList
                  setPage={setPage}
                  page={page}
                  submitTitle={setTitle}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    </div>
  );
};

export default Courses;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import './styles/StoriesList.css'

import { BsThreeDots } from "react-icons/bs";
import { Modal } from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import Form from 'react-bootstrap/Form';
import toast, {Toaster} from 'react-hot-toast';
import { useAppDispatch } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';
import { deleteStory, getStoryInformation, sendComment, sendEmoji, sendStoryReport, showStory } from './stories.service';
import { IoIosCloseCircle } from "react-icons/io";
import { BsFillArrowRightCircleFill , BsFillArrowLeftCircleFill } from "react-icons/bs";
import { IoIosArrowDropupCircle } from "react-icons/io";


const StoriesContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const StoryWrapper = styled.div`
  width: 300px;
  height: 500px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
`;

const StoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
 
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
 
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 2px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #e2621b;
  width: ${({ progress }) => progress}%;
  transition: width 0.1s;
`;

const NavButton = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ position }) => (position === 'left' ? '10px' : 'auto')};
  right: ${({ position }) => (position === 'right' ? '10px' : 'auto')};
  cursor: pointer;
  font-size:30px

`;

const CommentButton = styled.div`
  position: absolute;
    bottom: 10%;
    left: 50%;
    cursor: pointer;
    font-size: 30px;
    transform: translateX(-50%);
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
`;


const MyStories = ({ visible, onEnd, resetStories , usersData ,userIndex }) => {
  const [currentUser, setCurrentUser] = useState(0);
  const [currentStory, setCurrentStory] = useState(0);

  const [progress, setProgress] = useState(0);
  const [loadingFile, setLoadingFile] = useState(true);

  const intervalRef = useRef(null);
  const videoRef = useRef(null);

  const HandleFontSize = (letters) => {
    
    if (letters.length > 0 && letters.length < 6) {
      return 60
    } else if (letters.length > 6 && letters.length < 18) {
      return 40
    } else if (letters.length > 18 && letters.length < 32) {
      return 15
    }else{
      return 15
    }
  };

  const [paused, setPaused] = useState(false);

  const [showReportModal, setShowReportModal] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [showComment, setShowComment] = useState(false);

  const [comment, setComment] = useState('');

  const [commentText, setCommentText] = useState('');

  const [showEmojisDetails, setShowEmojisDetails] = useState(false);

  const [storyOwnerInfo, setStoryOwnerInfo] = useState(null);

  const [actviveTab , setActiveTab ] = useState('emojis');

  const [showDeletePopup , setShowDeletePopup ] = useState(false);

  const [showDeletePopupConfirm , setShowDeletePopupConfirm ] = useState(false);

  useEffect(() => {
    if (loadingFile || paused) return;

    intervalRef.current = setInterval(() => {
      const duration = usersData[currentUser].data[currentStory].story_type == 'video' ? usersData[currentUser].data[currentStory].duration : 10 
      setProgress((prev) => {
        if (prev >= 100) {
          if (currentStory >= usersData[currentUser].data.length - 1) {
            if (currentUser >= usersData.length - 1) {
              onEnd();
              return 0;
            }
            setCurrentUser((prevUser) => prevUser + 1);
            setCurrentStory(0);
            return 0;
          }
          setCurrentStory((prevStory) => prevStory + 1);
          return 0;
        }
        return prev + (100 / (duration * 10)); // Increment progress for 10 seconds
      });
    }, 100);

    return () => clearInterval(intervalRef.current);
  }, [loadingFile,visible,paused, onEnd, usersData, currentUser, currentStory]);

  useEffect(() => {
    setLoadingFile(true);
    if (videoRef.current) {
      videoRef.current.load();
    }else {
      setLoadingFile(false);
    }
  }, [currentStory]);

  useEffect(() => {
    if (resetStories) {
      getStoryInfo(usersData[currentUser].data[currentStory].id,0)
      setCurrentUser(userIndex);
      setCurrentStory(0);
      setProgress(0);
    }
  }, [resetStories]);

  useEffect(() => {
      getStoryInfo(usersData[currentUser].data[currentStory].id,0)
      viewStory(usersData[currentUser].data[currentStory].id)
  }, [currentUser,currentStory]);

  const handleNext = () => {
    
    if (currentStory >= usersData[currentUser].data.length - 1) {
      if (currentUser >= usersData.length - 1) {
        onEnd();
      } else {
        setCurrentUser((prevUser) => prevUser + 1);
        setCurrentStory(0);
        
      }
    } else {
      setCurrentStory((prevStory) => prevStory + 1);
    }
    setProgress(0);
  };

  const handlePrev = () => {
    if (currentStory === 0) {
      if (currentUser === 0) {
        setCurrentUser(0);
        setCurrentStory(0);
      } else {
        setCurrentUser((prevUser) => prevUser - 1);
        setCurrentStory(usersData[currentUser - 1].data.length - 1);
      }
    } else {
      setCurrentStory((prevStory) => prevStory - 1);
    }
    setProgress(0);
  };
  const dispatch = useAppDispatch();

  const sendReport = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append('story_id', usersData[currentUser].data[currentStory].id);
      data.append('reason', comment);


      let result = await sendStoryReport(data);
      if (result.response) {
        dispatch(setLoading(false));
        setShowReportForm(false)
        toast.success('Done');
        setPaused(false)
        if (videoRef.current) videoRef.current.play();
      }
    } catch (e) {
      console.log({e});
    }
  };

  const sendCommentForm = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append('story_id', usersData[currentUser].data[currentStory].id);
      data.append('comment', commentText);


      let result = await sendComment(data);
      if (result.response) {
        dispatch(setLoading(false));
        setShowComment(false)
        toast.success('Done');
        setPaused(false)
        if (videoRef.current) videoRef.current.play();
      }
    } catch (e) {
      console.log({e});
    }
  };

  const getStoryInfo = async (id,openModal) => {
    
    if( openModal == 1 ){ dispatch(setLoading(true)); }
    let result = await getStoryInformation(id);
    if (result.response) {
      console.log('story info' ,result.response.data )
      setStoryOwnerInfo(result.response.data)
      if( openModal == 1 ){ setShowEmojisDetails(true) }
    }
    if( openModal == 1 ){ dispatch(setLoading(false)); }
  };

  const viewStory = async (id) => {
    
    //dispatch(setLoading(true));
    let result = await showStory(id);
    if (result.response) {
      //dispatch(setLoading(false));
    }
    
  };

  const sendEmojiForm = async (emoji) => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append('story_id', usersData[currentUser].data[currentStory].id);
      data.append('emoji', emoji);


      let result = await sendEmoji(data);
      if (result.response) {
        dispatch(setLoading(false));
        setShowComment(false)
        toast.success('Done');
        setPaused(false)
        if (videoRef.current) videoRef.current.play();
      }
    } catch (e) {
      console.log({e});
    }
  };

  const deleteStoryForm = async () => {
    dispatch(setLoading(true));
    try {
      
      let result = await deleteStory(usersData[currentUser].data[currentStory].id);
      if (result.response) {
        dispatch(setLoading(false));
        setShowDeletePopupConfirm(false)
        toast.success('Done');
        //setPaused(false)
        window.location.reload()
      }
    } catch (e) {
      console.log({e});
    }
  };

  return (
    <>
    <Toaster position="top-right" />
    <StoriesContainer visible={visible}>
      
      <StoryWrapper>
        {loadingFile && usersData[currentUser].data[currentStory].story_type != 'text' && <LoadingIndicator>Loading...</LoadingIndicator>}
        { usersData.length && usersData[currentUser].data[currentStory].story_type == 'image' ?
          <StoryImage src={usersData[currentUser].data[currentStory].data_path} alt={`story-${currentStory}`} onLoad={() => setLoadingFile(false)} />
        :
          ''
        }

        { usersData.length && usersData[currentUser].data[currentStory].story_type == 'text' ?
          <div className='w-100 h-100'>
            <div className='text-center w-100 h-100 card-img-top d-flex justify-content-center align-items-center' style=
              {{color:usersData[currentUser].data[currentStory].text_color,
                backgroundColor:usersData[currentUser].data[currentStory].background_color,
                fontSize: HandleFontSize(usersData[currentUser].data[currentStory].data),
              }}
              >
                {usersData[currentUser].data[currentStory].data} 
              </div>
              
          </div>
        :
          ''
        }

        { visible && usersData.length && usersData[currentUser].data[currentStory].story_type == 'video' ?
          <Video ref={videoRef} src={usersData[currentUser].data[currentStory].data_path} loop preload="auto" onLoadedData={ () => {
            setLoadingFile(false);
          videoRef.current.play();
          } }  />
        :
          ''
        }


        
        <ProgressBarContainer>
          {usersData[currentUser].data.map((story, index) => (
            <ProgressBar key={story.id}>
              <Progress progress={index === currentStory ? progress : (index < currentStory ? 100 : 0)} />
            </ProgressBar>
          ))}
        </ProgressBarContainer>
        <NavButton position="left" onClick={ () => handlePrev()}>
        <BsFillArrowLeftCircleFill />
        </NavButton>
        <NavButton position="right" onClick={ () => handleNext()}>
        <BsFillArrowRightCircleFill />
        </NavButton>
        <CommentButton onClick={ () => {
          console.log('usersData[currentUser].data[currentStory]' , usersData[currentUser].data[currentStory] )
          setPaused(true)
          clearInterval(intervalRef.current);
          if (videoRef.current) videoRef.current.pause();
          if(usersData[currentUser].data[currentStory].is_owner){
            getStoryInfo(usersData[currentUser].data[currentStory].id,1)
          }else{
            setShowComment(true)
          }
        } }>
        <IoIosArrowDropupCircle color='#e2621b' />
        </CommentButton>

        
        
        <div className='story-options d-flex align-items-center flex-column justify-content-between' >
          <div class="align-items-center d-flex w-100">
            <div className='d-flex align-items-center w-100'>
              <img src={ usersData[currentUser].data[currentStory].avatar ?? '/icons/manHolder.png' } alt="profile" width={50} height={50} className='rounded-circle' /> 
              <span className='mx-2 text-black font-weight-bold'>{usersData[currentUser].data[currentStory].consultant_name}</span>
            </div>
            <div className='d-flex align-items-center'>
              <BsThreeDots 
                size={30}
                onClick={() => {
                  if( usersData[currentUser].data[currentStory].is_owner ){
                    setPaused(true)
                    clearInterval(intervalRef.current);
                    if (videoRef.current) videoRef.current.pause();
                    setShowDeletePopup(true)
                  }else{
                    setPaused(true)
                    clearInterval(intervalRef.current);
                    if (videoRef.current) videoRef.current.pause();
                    setShowReportModal(true)
                  }
                }}
              />  
              <IoIosCloseCircle className='mx-2' size={20} onClick={onEnd} />
              </div>
            </div>

            
        </div>

        <div className="cursor-pointer" style={{ position: 'absolute' , left: '10px' , bottom : '20%' }}
        onClick={ () => { 
        setActiveTab('comments')  
        setShowEmojisDetails(true)
        setPaused(true)
        clearInterval(intervalRef.current);
        if (videoRef.current) videoRef.current.pause();
        } }
        >
        { usersData[currentUser].data[currentStory].is_owner && storyOwnerInfo && storyOwnerInfo.comments.length ? 
          <div className="d-flex mb-2 align-items-center" style={{ color: '#000' , background: 'gray' , borderRadius : '5px' , padding : '5px' }}>
            <img src={`${ storyOwnerInfo.comments[0].avatar ?? '/icons/manHolder.png' }`} alt="img" width={30} height={30} class="rounded-circle" /> 
            <div className='mx-2'>
              <div className='rounded'>{storyOwnerInfo.comments[0].comment}</div>
            </div>
          </div>
        : '' }
        </div>
        
      </StoryWrapper>
    </StoriesContainer>

    <Modal show={showReportModal} onHide={() => {
    setShowReportModal(false)
    setPaused(false)
    if (videoRef.current) videoRef.current.play();
    }}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="text-center pb-4 px-4">
          <img
            src="/logo.png"
            className="mx-auto mb-2"
            style={{width: '100px'}}
          />
          
          <div className="mb-3" onClick={() => {
            setShowReportModal(false)
            setShowReportForm(true)
          }}><MainButton text={'Report Story'} /></div>
          <div onClick={() => { 
            setPaused(false)
            if (videoRef.current) videoRef.current.play();
            setShowReportModal(false)
            
            }}>
            <CancelButton text={'Cancel'} />
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal show={showReportForm} onHide={() => setShowReportForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center pb-4 px-4">
            
            <h5>Report Story</h5>
           
            
            <Form.Control
              className={`form-control my-4`}
              as="textarea"
              rows={3}
              onChange={e => setComment(e.target.value)}
              placeholder="Leave Reason"
            />
            <div
              onClick={() => {
                sendReport()
              }}>
              <MainButton text={'Send'} disabled={comment == ''} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showComment} onHide={() => {
        setPaused(false)
        if (videoRef.current) videoRef.current.play();
        setShowComment(false)
        }}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center pb-4 px-4">

            <div className="text-center px-4 d-flex justify-content-between mb-4 align-items-center"
            style={{justifyContent: 'space-evenly'}}
            >
                <div className='cursor-pointer' onClick={() => { sendEmojiForm('like') } }>
                  <img src="/emojis/like.png" alt='emoji' />
                </div>

                <div className='cursor-pointer' onClick={() => { sendEmojiForm('wow') } }>
                  <img src="/emojis/wow.png" alt='emoji' />
                </div>

                <div className='cursor-pointer' onClick={() => { sendEmojiForm('love') } }>
                  <img src="/emojis/love.png" alt='emoji' /> 
                </div>

                <div className='cursor-pointer' onClick={() => { sendEmojiForm('sad') } }>
                  <img src="/emojis/sad.png" alt='emoji' /> 
                </div>

                <div className='cursor-pointer' onClick={() => { sendEmojiForm('thank') } }>
                  <img src="/emojis/thank.png" alt='emoji' /> 
                </div>

                <div className='cursor-pointer' onClick={() => { sendEmojiForm('good') } }>
                  <img src="/emojis/good.png" alt='emoji' /> 
                </div>
            </div>

            
            
            <Form.Control
              className={`form-control my-4`}
              as="textarea"
              rows={3}
              onChange={e => setCommentText(e.target.value)}
              placeholder="Comment"
            />
            <div
              onClick={() => {
                sendCommentForm()
              }}>
              <MainButton text={'Send'} disabled={commentText == ''} />
            </div>
          </div>
        </Modal.Body>
      </Modal> 

      <Modal show={showEmojisDetails} onHide={() => {
        setPaused(false)
        if (videoRef.current) videoRef.current.play();
        setShowEmojisDetails(false)
        }}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          { storyOwnerInfo ? 
            <>

              <div className="text-center pb-4 px-4 d-flex justify-content-between align-items-center">
                  <h5 className='cursor-pointer' style={{ color: actviveTab == 'emojis' ? '#e2621b' : '#000' }} onClick={ () => setActiveTab('emojis') } >Emojis ({storyOwnerInfo.emojis.length ?? 0 })</h5>
                  <h5 className='cursor-pointer' style={{ color: actviveTab == 'comments' ? '#e2621b' : '#000' }} onClick={ () => setActiveTab('comments') }>Comments ({storyOwnerInfo.comments_count})</h5>
                  <h5 className='cursor-pointer' style={{ color: actviveTab == 'views' ? '#e2621b' : '#000' }} onClick={ () => setActiveTab('views') }>Views ({storyOwnerInfo.views_count})</h5>
              </div>
              
              { actviveTab == 'emojis' ? 
              <>
                

                <div className="text-center px-4 d-flex justify-content-between mb-4 align-items-center">
                    <div>
                      <img src="/emojis/like.png" alt='emoji' /> ({ storyOwnerInfo.emojis_count.love ?? 0 })
                    </div>

                    <div>
                      <img src="/emojis/wow.png" alt='emoji' /> ({ storyOwnerInfo.emojis_count.wow ?? 0 })
                    </div>

                    <div>
                      <img src="/emojis/love.png" alt='emoji' /> ({ storyOwnerInfo.emojis_count.love ?? 0 })
                    </div>
                </div>

                <div className="text-center px-4 d-flex justify-content-between mb-2 align-items-center">
                    <div>
                      <img src="/emojis/sad.png" alt='emoji' /> ({ storyOwnerInfo.emojis_count.sad ?? 0 })
                    </div>

                    <div>
                      <img src="/emojis/thank.png" alt='emoji' /> ({ storyOwnerInfo.emojis_count.thank ?? 0 })
                    </div>

                    <div>
                      <img src="/emojis/good.png" alt='emoji' /> ({ storyOwnerInfo.emojis_count.good ?? 0 })
                    </div>
                </div>
              
                <div className='emojis px-3'>
                  { storyOwnerInfo.emojis.map( (e,key) => {
                    return (
                      <div key={key} className="d-flex mb-2 align-items-center border-bottom pb-2 mt-4" >
                        <img src={`${ e.avatar ?? '/icons/manHolder.png' }`} alt="img" width={50} height={50} class="rounded-circle" /> 
                        <div className='mx-2 flex-grow-1 d-flex align-items-center justify-content-between'>
                          <h6 className='m-0'>{e.username}</h6>
                          <img src={`/emojis/${e.emoji}.png`} alt='emoji' />
                        </div>
                      </div>
                    );
                  })}
                </div>
                
                { storyOwnerInfo.emojis.length == 0  && ( <h4 className="text-center" >No Emojis Yet</h4> ) } 
              </>
              : '' }

              { actviveTab == 'comments' ? 
              <div className='comments px-3'>
                
                {storyOwnerInfo.comments.map( (comment,key) => {
                  return (
                    <div key={key} className="d-flex mb-2 align-items-center border-bottom pb-2" >
                      <img src={`${ comment.avatar ?? '/icons/manHolder.png' }`} alt="img" width={50} height={50} class="rounded-circle" /> 
                      <div className='mx-2'>
                        <h6 className='mb-1'>{comment.username}</h6>
                        <div style={{ textAlign: 'left' }} className='rounded'>{comment.comment}</div>
                      </div>
                    </div>
                  );
                })}

                { storyOwnerInfo.comments.length == 0  && ( <h4 className="text-center" >No Comments Yet</h4> ) }
              </div>
              : '' }

              { actviveTab == 'views' ? 
              <div className='views px-3'>
                
                { storyOwnerInfo.views.map( (view,key) => {
                  return (
                    <div key={key} className="d-flex mb-2 align-items-center border-bottom pb-2" >
                      <img src={`${ view.avatar ?? '/icons/manHolder.png' }`} alt="img" width={50} height={50} class="rounded-circle" /> 
                      <div className='mx-2'>
                        <h6 className='m-0'>{view.username}</h6>
                      </div>
                    </div>
                  );
                })}

                { storyOwnerInfo.views.length == 0  && ( <h4 className="text-center" >No Views Yet</h4> ) }
              </div>
              : '' }
          

            </>
          : '' }
        </Modal.Body>
      </Modal> 

      {/*delete story*/}
      <Modal show={showDeletePopup} onHide={() => {
    setShowDeletePopup(false)
    setPaused(false)
    if (videoRef.current) videoRef.current.play();
    }}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="text-center pb-4 px-4">
          <img
            src="/logo.png"
            className="mx-auto mb-2"
            style={{width: '100px'}}
          />
          
          <div className="mb-3" onClick={() => {
            setShowDeletePopup(false)
            setShowDeletePopupConfirm(true)
          }}><MainButton text={'Delete Story'} /></div>
          <div onClick={() => { 
            setPaused(false)
            if (videoRef.current) videoRef.current.play();
            setShowDeletePopup(false)
            
            
            }}>
            <CancelButton text={'Cancel'} />
          </div>
        </div>
      </Modal.Body>
    </Modal>

     {/*delete story confirm*/}
     <Modal show={showDeletePopupConfirm} onHide={() => {
    setShowDeletePopupConfirm(false)
    setPaused(false)
    if (videoRef.current) videoRef.current.play();
    }}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="text-center pb-4 px-4">
          <img
            src="/logo.png"
            className="mx-auto mb-2"
            style={{width: '100px'}}
          />

          <h5>Story will be deleted !</h5>
          
          <div className="mb-3" onClick={() => {
            deleteStoryForm()
          }}><MainButton text={'Ok'} /></div>
          <div onClick={() => { 
            setPaused(false)
            if (videoRef.current) videoRef.current.play();
            setShowDeletePopupConfirm(false)
            
            }}>
            <CancelButton text={'Cancel'} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default MyStories;

import axios from 'axios';
import { getUserCountry } from '../../../helpers/getUserCountry';

export const BaseUrlInterceptor = (baseURL: string) => {
  axios.interceptors.request.use(config => {
    config.baseURL = baseURL;
    config.params = {
      ...config.params,
      //user_country: getUserCountry()
      
    
    }

    return config;
  });
};

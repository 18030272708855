import { Col, Container, Row } from "react-bootstrap";

const NotFound = () => {
  

  return (
   <Container>
    <Row>
      <Col md={{span: 6, offset: 3}} className="text-center pt-5 pb-5">
        <img src="/not-found.png" alt="img"  style={{maxWidth: '100%'}}/>
      </Col>
    </Row>
   </Container>
  );
};

export default NotFound;
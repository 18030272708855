import {
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import {FaCircle, FaLongArrowAltRight, FaRegCalendarAlt} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/UserTrackingSystem.module.css';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import { useAppDispatch , useAppSelector } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';
import {
  enrollFree,
  addCart,
  assignAndStartTrack,
} from '../Tracking/Trackings.service';

import { getAllPackageLevels, getmemberForAssign } from './Initiatives.service';

import {
  getTrackingsDetailsBeforeEnroll,
} from './Initiatives.service';

import {isUserLoggedIn, getUserId} from '../../auth/utiles';
import {useNavigate} from 'react-router-dom';
import './style/VideoPlayer.css';
import CancelButton from '../../UI/cancel-button/CancelButton';
import CustomCheckbox from '../Tracking/components/CustomCheckbox';
import TrackingCard from './components/TrackingCard';
import { getmemberForAssignPackages } from '../Trackings/Trackings.service';

const TrackingPackageLevels = () => {

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const [discover, setDiscover] = useState('0');

  useEffect(() => {
    const tabFromQueryParam = queryParams.get('discover');
    if (tabFromQueryParam && tabFromQueryParam == '1' ) {
      setDiscover('1')
    }
  }, []);

  const navigate = useNavigate();

  const isLoggedIn = isUserLoggedIn();

  const userId = getUserId() ?? '';

  const cartData = useAppSelector(state => state.cart.data);

  const {id} = useParams();

  const dispatch = useAppDispatch();

  const [tracking, setTracking] = useState(null);

  const [enrolled, setEnrolled] = useState(false);

  const [show, setShow] = useState(false);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [members, setMembers] = useState([]);

  const [member, setMember] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);

  const [trackings, setTrackings] = useState([]);

  const [trackingDetails, setTrackingDetails] = useState([]);

  const [showPriceLimitation, setShowPriceLimitation] = useState(false);

  const [unlimited, setUnLimited] = useState(false);

  const getData = async () => {
      dispatch(setLoading(true));
      let result = await getAllPackageLevels(id);
      let record = result.response
      if( record && record.not_available){
        navigate('/not-available')  
      }else{
        console.log('initiative dicover' , result.response.data.data )
        setTrackings(result.response.data.package_levels.data);
        setTrackingDetails(result.response.data.package_details)
      }
      dispatch(setLoading(false));
  };

  

  const existInCart = () => {
    return cartData.find(i => i.name == trackingDetails.name);
  };

  const enroll = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();

      destinationArray.map((i, index) => data.append(`users_ids[${index}]`, i.id));
      data.append('module_name', 'TrackingPackage');
      data.append('module_id', String(id));

      let result = await enrollFree(data);
      if (result.response) {
        setEnrolled(true);
        dispatch(setLoading(false));
        setShow(false);
        navigate('/user/trackings/list/')
          
      }
    } catch (e) {
      console.log({e});
    }
  };

  const addToCart = async () => {
    if (isLoggedIn) {
      dispatch(setLoading(true));
      try {
        let data = new FormData();
        for( let i=0 ; i < destinationArray.length ; i++ ){
          data.append('model_id', String(id));
          data.append('model_name', 'TrackingPackage');

          data.append(`items[${i}][model_id]`, String(id));
          data.append(`items[${i}][model_name]`, 'TrackingPackage');
          data.append(`items[${i}][user_id]`, destinationArray[i].id);

        }
        
        data.append('is_unlimited', unlimited ? 1 : 0);
        
        let result = await addCart(data);
        console.log('resultresultresultresultENROL', result);
        dispatch(setLoading(false));
        setShowAssignUsers(false)
      } catch (e) {
        console.log({e});
      }
    } else {
      navigate(`/login`);
    }
  };

  useEffect(() => {
    getData();
    getMembers();
  }, []);

  const getMembers = async () => {
    let result = await getmemberForAssignPackages(id);

   

    
    if (result.response?.data.length > 0) {

      console.log('assign users', result.response.data);

      let data = result.response.data

      //const newData = data.map(item => ({
        //...item,
        //id: item.id.toString(), // Convert id to string
      //}));
  
      let newData = data.map(item => ({ ...item, checked: false }));

      setSourceArray(newData);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log('value, checked', value, checked )
    if (checked) {
        // If checkbox is checked, add the value to the selectedValues array
        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
        // If checkbox is unchecked, remove the value from the selectedValues array
        setSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((item) => item !== value)
        );
    }

    console.log('selectedValues' , selectedValues )
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )

    };

  const Assign = async () => {
    if (member) {
      console.log('data');
      console.log('id ', id);
      console.log(
        'tracking.user_tracking_enrollment_id',
        tracking.user_tracking_enrollment_id,
      );
      console.log('member ', member);
      let data = new FormData();
      data.append('tracking_id', id);
      if (trackingDetails?.user_tracking_enrollment_id) {
        data.append(
          'user_tracking_enrollment_id',
          tracking.user_tracking_enrollment_id,
        );
      }
      data.append(`members[0]`, member);
      let result = await assignAndStartTrack(data);
      console.log('result', result);
      setShowAssignUsers(false);
    }
  };

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
      }}>
      Limited - Only you or a family member can start this regimen and you can
      not re-take it after you have finished it.
    </Popover>
  );

  const [sourceArray, setSourceArray] = useState([
    { id: 1, name: 'Item 1', checked: false },
    { id: 2, name: 'Item 2', checked: false },
    // Add more objects as needed
  ]);

  const [destinationArray, setDestinationArray] = useState([]);

  const handleCheckboxChangeItems = (id) => {
    // Find the item in the source array
    const selectedItem = sourceArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = true
      // Update checked status in the source array
      const updatedSourceArray = sourceArray.map(item =>
        item.id === id ? { ...item, checked: !item.checked } : item
      );

      // Update the state with the modified source array
      const newSourceData = sourceArray.filter(item => item.id !== id);
      setSourceArray(newSourceData);

      setDestinationArray(prevArray => [...prevArray, selectedItem]);
    }
  };

  const handleDestinationCheckboxChangeItems = (id) => {
    // Find the item in the source array
    const selectedItem = destinationArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = false

      setSourceArray(prevArray => [...prevArray, selectedItem]);

      // Update the state with the modified source array
      const newDestinationData = destinationArray.filter(item => item.id !== id);
      

      setDestinationArray(newDestinationData);
    }
  };

  return (
    <>
      <Container className="mt-5 mb-5">
      {
          trackings.length
          ?
         
          <Row>
           <Col md={12} className='d-flex justify-content-between align-items-center share-dropdown mb-4'>
              <h4>Follow-up System Levels</h4>
            </Col> 
          {trackings.map((tracking) => {
              return (
                <Col lg={4}>
                  <TrackingCard
                    tracking={tracking}
                    isPackage={tracking.tracking_type == 'multi'}
                    is_discover={discover == 1 ? 1 : 0 }
                  />
                </Col>
              );
            })}
            </Row> 

          :
          <h2 className='text-center mt-5'>No Records Found</h2>
          }
        <Row>
          <Col md={12} className={`${classes.TrackingDetails} p-0`}>
            <div>
            
              <div>
                {(trackingDetails?.price && trackingDetails?.selected_enrolled_user_id == null) || ( trackingDetails?.price && discover == 1)  ? (
                  <MainButton
                    text={ existInCart() ? 'View Cart' : "Add To Cart" }   
                    onClick={() => { 
                      if( existInCart() ){
                        navigate('/cart')
                      }else{
                        if( trackingDetails?.has_unlimited_price == 1 ){
                          
                          setShowPriceLimitation(true)
                        }else{
                          setShowAssignUsers(true);
                        }
                      }
                     }}
                  />
                ) : (
                  ''
                )}

                {(trackingDetails?.price == 0 && trackingDetails?.selected_enrolled_user_id == null) ||  ( trackingDetails?.price == 0 && discover == 1) ? (
                  <div  onClick={() => setShowAssignUsers(true)}>
                  <MainButton text="Enroll Now" />
                </div>
                ) : (
                  ''
                )}
                
              </div>
            </div>
          </Col>

          
        </Row>
      </Container>
      

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        
        <Modal.Body>
          

         
         
        
          {
            sourceArray.length
            ?
            <h5 className="secondry-color mt-4 mb-3">
              Choose one or more family member
            </h5>
            :
            
            <h5 className="secondry-color mt-4 mb-3">
            No member family found as all member are assigned to this initiative
          </h5>
          }  


          
          {/*new array*/}
          {
              sourceArray.map((item,i) => (
              <div className="d-flex justify-content-between align-items-center mb-3" key={item.id}>
                  <div>
                      <img 
                      src={item.avatar ?? '/icons/manHolder.png' }
                      alt='image'
                      style={{
                          width: '60px',
                          borderRadius: '50%',
                          height: '60px',
                          margin:'0 20px 0 0',
                      }}
                      />
                      <span>{item.username}</span>
                  </div>

                  <div>


                  <div>
                      
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChangeItems(item.id)}
                        
                      />
                  </div>

                  

                  </div>    
              </div>
              ))
          }



     

      {
        destinationArray.length
        ?
        <h5 className="secondry-color mt-4 mb-3">
        Selected family members
      </h5>
        :
        ''
      }
     
      {destinationArray.map(item => (
       <div className="d-flex justify-content-between align-items-center mb-3" key={item.id}>
       <div>
           <img 
           src={item.avatar ?? '/icons/manHolder.png' }
           alt='image'
           style={{
               width: '60px',
               borderRadius: '50%',
               height: '60px',
               margin:'0 20px 0 0',
           }}
           />
           <span>{item.username}</span>
       </div>

       <div>


       <div>
           
           <input
             type="checkbox"
             checked={item.checked}
             onChange={() => handleDestinationCheckboxChangeItems(item.id)}
             
           />
       </div>

       

       </div>    
   </div>
      ))}
        {/*new array*/}
        <hr />
        {
          trackingDetails?.price && destinationArray.length
          ?
          <div className='d-flex justify-content-between' style={{ 'fontWeight' : 'bold' }}>
            <p>Total</p>
            { unlimited ?
              <p>{trackingDetails?.unlimited_price * destinationArray.length} {trackingDetails?.user_currency}</p>
              :
              <p>{trackingDetails?.price * destinationArray.length} {trackingDetails?.user_currency}</p>
              }
          </div>
          :
          ''
        }
        
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={ trackingDetails?.price ? 'Add To Cart' :  'Enroll Now' }
              onClick={() => {
                if( trackingDetails?.price ){
                  addToCart()
                }else{
                  enroll()
                }
              }}
              disabled={destinationArray.length == 0}
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>

      {/*choose price limitation pop up*/}
      <Modal
        show={showPriceLimitation}
        onHide={() => setShowPriceLimitation(false)}
        centered>
        <Modal.Body>
           
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={`Limited ( ${trackingDetails?.price} ${trackingDetails?.user_currency} )`}
              onClick={() => {
                setUnLimited(false)
                setShowPriceLimitation(false)
                setShowAssignUsers(true)
              }}
            />
          </div>

          <div className="w-100">
            <MainButton
              text={`Unlimited ( ${trackingDetails?.unlimited_price} ${trackingDetails?.user_currency} )`}
              onClick={() => {
                setUnLimited(true)
                setShowPriceLimitation(false)
                setShowAssignUsers(true)
              }}
            />
          </div>

          <div onClick={() => setShowPriceLimitation(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrackingPackageLevels;

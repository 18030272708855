import moment from 'moment';
import {colors} from '../constants/colors';

export const getDaysInRange = (periods: any) => {
  const days: any[] = [];

  periods.forEach((period: any) => {
    const start = moment(period.start);
    const end = moment(period.end);

    // Iterate over all the days between the start and end dates
    for (let date = start; date <= end; date.add(1, 'days')) {
      days.push({
        date: moment(date).locale('en').format('YYYY-MM-DD'),
        containerStyle: {backgroundColor: colors.chamThirdColor},
      });
    }
  });

  return days;
};

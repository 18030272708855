import { Col, Container, Row } from 'react-bootstrap';
import TrackingCard from './components/TrackingCard';
import { useAppDispatch } from '../../hooks/hooks';
import { useEffect, useState } from 'react';
import { setLoading } from '../../store/userSlice';
import { getTrackingsLevels } from './Trackings.service';
import { useParams } from 'react-router-dom';

const UserTrackingSystems = () => {

    const {id} = useParams();
    const dispatch = useAppDispatch();

    const [trackings, setTrackings] = useState([]);
    
    

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        dispatch(setLoading(true));
        let result = await getTrackingsLevels(id!);
        if (result.response) {
            setTrackings(result.response.data.data);
            
        }
        dispatch(setLoading(false));
    };

   
  
  return (
  
        <Container>
            <h1 className="page-title" style={{ margin: '0 1rem' }} >Follow-up System Levels</h1>
            
            <Row>
                {trackings.map(tracking => {
                return <Col lg={6} >
                            <TrackingCard tracking={tracking} />
                        </Col>;
                })}  
            </Row>
        </Container>
    
  );
}

export default UserTrackingSystems;
import Button from 'react-bootstrap/Button';
import classes from './style/Quiz.module.css';
import {useEffect, useState} from 'react';
import UserQuizResult from './components/UserQuizResult';
import {getQuizData, submitQuiz} from './Quiz.services';
import {useLocation, useParams} from 'react-router';
import {Col, Container, Row} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {Report} from './interface/IQuiz';
import {CourseType} from '../Courses/interfaces/ICourses';
import {useAppDispatch} from '../../hooks/hooks';
import {finishLesson} from '../Courses/Courses.service';

const Quiz = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const lessonId = location.state.lessonId;
  let {id} = useParams();
  const [item, setItem] = useState<any>();
  const [activeQuestion, setActiveQuestion] = useState<number>(-1);
  const [selectedAnswer, setSelectedAnswer] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [report, setReport] = useState<Report | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [answeredSeelcted, setAnsweredSeelcted] = useState<boolean>(false);

  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  useEffect(() => {
    getQuiz();
  }, []);

  const getQuiz = async () => {
    setLoading(true);
    let result = await getQuizData(id);
    if (result.response) {
      setItem(result.response.data);
    }
    setLoading(false);
  };
  const onClickNext = () => {
    setAnsweredSeelcted(false)
    setSelectedAnswerIndex(null);
    setResult(prev =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
          }
        : {...prev, wrongAnswers: prev.wrongAnswers + 1},
    );
    if (activeQuestion !== item?.questions?.length - 1) {
      setActiveQuestion(prev => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
      submit();
    }
  };

  const onAnswerSelected = (answer: any, index: any) => {
    setAnsweredSeelcted(true)
    let quiz = {...item};
    quiz?.questions[activeQuestion]?.answers.map((o: any, p: any) => {
      if (p == index) {
        if (!o.selected && item?.questions[activeQuestion].type == 'select') {
          o.selected = true;

          setCorrectAnswers(correctAnswers + 1);
        } else if (
          o.selected == true &&
          item?.questions[activeQuestion].type == 'select'
        ) {
          o.selected = false;
          setCorrectAnswers(correctAnswers - 1);
        } else {
          o.selected = true;
        }
      } else if (item?.questions[activeQuestion].type != 'select')
        o.selected = false;
    });
    setItem(quiz);
    setSelectedAnswerIndex(index);
    if (answer.is_correct == 1) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };
  const endlesson = async () => {
    let data = new FormData();
    data.append(`lesson_id`, lessonId);
    await finishLesson(data);
  };
  const submit = async () => {
    let data = new FormData();
    item?.questions.map((i: any, key: any) => {
      data.append(`questions[${key}][question_id]`, i.id);
    });
    console.log('item/', item);

    item?.questions.map((i: any, key: any) => {
      i.answers.map((o: any, p: any) => {
        o.selected && data.append(`questions[${key}][answers_id][${p}]`, o.id);
      });
    });

    data.append(`quizze_id`, String(id));

    let result = await submitQuiz(data);

    if (result.response) {
      endlesson();
      result.response.answers_report['passing_percentage'] = item?.passing_percentage
      setReport(result.response.answers_report);
    }
  };

  const addLeadingZero = (number: number) =>
    number > 9 ? number : `0${number}`;
  return (
    <div>
      {activeQuestion == -1 ? (
        loading ? (
          <LoadingSpinner />
        ) : (
          <section className={`section text-center ${classes.personalityTest}`}>
            <div>
              <h1 className="main-black-title">Welcome to {item?.title}</h1>

              <Row className="mt-4">
                <Col
                  md={2}
                  className="m-auto"
                  onClick={() => setActiveQuestion(0)}>
                  <MainButton text="Start Test" />
                </Col>
              </Row>
            </div>
          </section>
        )
      ) : !showResult ? (
        <div>
          <Container>
            <section className={`section ${classes.personalityTest}`}>
              <div>
                <h3 className="mb-5">
                  {item?.questions[activeQuestion]?.question}
                </h3>
              </div>

              <div className={classes.Questions}>
                { item?.questions[activeQuestion]?.type == 'radio' && item?.questions[activeQuestion]?.answers?.map(
                  (answer: any, index: any) => (
                    
                     <p
                     
                      onClick={() => onAnswerSelected(answer, index)}
                      key={`${answer.id} ${index}`}
                      className={`${classes.Question} position-relative`}>
                      <input
                        type="radio"
                        id={`answer-${index}`}
                        name="radio-group"
                        
                      />
                      <label htmlFor={`answer-${index}`} className='answer-label answer-label-radio'>
                        {answer?.answer}
                      </label>
                    </p>
                    
                  ),
                )}

                { item?.questions[activeQuestion]?.type == 'select' && item?.questions[activeQuestion]?.answers?.map(
                  (answer: any, index: any) => (
                    
                     <p
                      key={`${answer.id} ${index}`}
                      className={`${classes.Question} position-relative`}>
                      <input
                        type="checkbox"
                        id={`answer-${index}`}
                        name="check-group"
                        onChange={() => onAnswerSelected(answer, index)}
                      />
                      <label htmlFor={`answer-${index}`} className='answer-label'>
                        {answer?.answer}
                      </label>
                    </p>
                    
                  ),
                )}

              </div>
            </section>
          </Container>

          <div
            className={`d-flex justify-content-between align-items-center ${classes.QuestionFooter}`}>
            <p className="m-0">
              {activeQuestion + 1} Of {item?.questions?.length} Questions
            </p>
            <div className="d-flex align-items-center cursor-pointer">
              <p
                onClick={onClickNext}
                style={{
                  color: '#0576e3',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  margin: '0 3rem'
                }}>
                Skip
              </p>

              <Button
                onClick={onClickNext}
                disabled={answeredSeelcted == false }
                className={classes.NextButton}
                variant="primary">
                {activeQuestion === item?.questions?.length - 1
                  ? 'Finish'
                  : 'Continue'}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {showResult && (
        <div>
          <span className="d-none">{result.score}</span>
          <UserQuizResult report={report} title={item.title} />
        </div>
      )}
    </div>
  );
};

export default Quiz;

import {APIURL} from '../../services/http/api';
import {HttpService} from '../../services/http/http.service';
import {EApiParams} from '../../services/http/apiParams.enum';
import {urlParamReplace} from '../../helpers/UrlParamReplace';

const http = new HttpService();

export const getBankQuestions = async (data: FormData) => {
  const api = APIURL.bankQ.getBankQuestions;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const LikeQuestion = async (data: FormData, id: number) => {
  const api = APIURL.bankQ.like;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const WatchQuestion = async (id: string | number | undefined) => {
  const api = APIURL.bankQ.watch;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

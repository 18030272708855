import DatePicker from 'react-multi-date-picker';
import classes from '../style/MultiRangeCalendar.module.css';
import {getDaysArray} from '../../../helpers/getDaysArray';
import {MultiRangeCalendarProp} from '../interface/IAvailabilty';
import moment from 'moment';

const MultiRangeCalendar = ({
  setDays,
  selectedDays,
}: MultiRangeCalendarProp) => {
  let minDate = moment().add('1', 'days').format('YYYY-MM-DD');
  let maxDate = moment().add('6', 'month').format('YYYY-MM-DD');

  return (
    <div className={classes.MultiRangeCalendar}>
      <div
        className={`d-flex justify-content-between align-items-center cursor-pointer ${classes.selectDaysParagrah}`}>
        <div className="d-flex justify-content-between align-items-center cursor-pointer">
          <img src="/icons/event.png"  className='cursor-pointer'/>
          {getDaysArray(selectedDays).length >= 1 ? (
            <span className='cursor-pointer'> {getDaysArray(selectedDays).length} Days Selected </span>
          ) : (
            <span className='cursor-pointer'>Select The Start And End Date</span>
          )}
        </div>
        <span
          className={classes.Clear}
          onClick={() => {
            setDays([]);
          }}>
          Clear
        </span>
      </div>
      <DatePicker
        value={selectedDays}
        minDate={minDate}
        maxDate={maxDate}
        onChange={dateObjects => {
          setDays(dateObjects);
        }}
        range
        multiple
      />
    </div>
  );
};

export default MultiRangeCalendar;

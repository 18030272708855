export enum Enums {
  free = 'free',
  paid = 'paid',
  Live = 'Live',
  Recorded = 'Recorded',
  recorded = 'recorded',
  live = 'live',
  Nurabi = 'Nualim',
  Consultants = 'Consultants',
  teachers = 'teachers',
  Coaches = 'mentors',
}

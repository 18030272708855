import classes from './CircleProgress.module.css'

const CircleNumber = ({value,progressColor='#0576e3',inviteNumber , background='#fff', titleColor= '#afafaf' }) => {

    let color = '#0576e3'
    if( progressColor ){
      color = progressColor
    }else{
      color = value > 49.5 ? 'green' : '#0576e3'
    }
    

  return (

    <div className={classes.Card}>
        <div className={classes.Percent}>
        <svg style={{ background: background , borderRadius: '50%' }}>
            <circle cx="35" cy="35" r="30"></circle>
            <circle cx="35" cy="35" r="30" style={{'--percent': (value/inviteNumber) * 100 ,  stroke:color}}></circle>
        </svg>
        <div className={classes.Number}>
            <h3 style={{ color: titleColor  }}>{value}/{inviteNumber}</h3>
        </div>
        </div>
    </div>
   
  );
}

export default CircleNumber;

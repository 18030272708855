import { Card, Col, Container, Row } from "react-bootstrap";
import './styles/AddStory.css'
import { CgFormatText } from "react-icons/cg";
import { CiImageOn } from "react-icons/ci";
import { IoVideocamOutline } from "react-icons/io5";

import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../hooks/hooks";
import { setLoading } from "../../store/userSlice";
import { sendStory } from "./stories.service";
import { useNavigate } from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast';



const AddStory = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [type, setType ] = useState('text');
    const [text, setText ] = useState('');
    const [textColorType, setTextColorType ] = useState('text');

    const [textColor, setTextColor ] = useState('');
    const [backgroundColor, setBackgroundColor ] = useState('');

    const [storyFile, setStoryFile ] = useState(null);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [disablePost, setDisablePost ] = useState(false);

    const [filePreview, setFilePreview] = useState('');

    useEffect(() => {
      if (!storyFile) {
        setFilePreview('');
        return;
      }
      const objectUrl = URL.createObjectURL(storyFile);
      setFilePreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }, [storyFile]);
    


    const colorOptions = [ 
      {value: '#FB5456' },
      {value: '#A5252F' },
      {value: '#07B2BD' },
      {value: '#0A7477' },
      {value: '#FDD219' },
      {value: '#282E33' },
      {value: '#6A747D' }
    ]

    const addStoryData = async () => {
      if( type == 'text' ){
        if( text && text != '' ){
          dispatch(setLoading(true));
          try {
            let data = new FormData();
      
            data.append('story_type', type)
            data.append('data', text);
            data.append('background_color', backgroundColor != '' ? backgroundColor : '#F1FAFB' );
            data.append('text_color', textColor != '' ? textColor : '#000' );
      
      
            let result = await sendStory(data);
            if (result.response) {
              dispatch(setLoading(false));
              navigate('/')
            }
          } catch (e) {
            console.log({e});
          }
        }else{
          toast.error('please enter the data')
        }
      }else{
        dispatch(setLoading(true));
        let data = new FormData();
        data.append('story_type', type)
        data.append('data', storyFile ?? '' )
        data.append('background_color', backgroundColor != '' ? backgroundColor : '#F1FAFB' );
        data.append('text_color', textColor != '' ? textColor : '#000' );
  
  
        let result = await sendStory(data);
        if (result.response) {
          dispatch(setLoading(false));
          navigate('/')
        }
      }
    };
    
      const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop();
        const imgExts = ['png','PNG','jpg','JPG','jpeg','JPEG','webp','WEBP','svg','SVG']
        const vidExts = ['mp4','MP4','mpg','mpeg','avi','wmv','mov','rm','ram','OGG','ogg','webm','WEBM']

        if (!imgExts.includes(fileExtension) && type == 'image') {
          toast.error('file type must be image')
          setDisablePost(true)
        } else if(!vidExts.includes(fileExtension) && type == 'video') {
          setDisablePost(true)
          toast.error('file type must be video')
        }
        else{
          setDisablePost(false)
          setStoryFile(file)
        }

       
      };

      const handleFileClick = () => {
          fileInputRef.current?.click();
      };
    
  
  return (
    <>
      <Toaster position="top-right" />
      <div className="stories-list my-5 pb-5">
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 3 }} md={4}>
                <Card>
                    <div 
                    className="px-4 py-3 add-story-actions d-flex justify-content-between align-items-center cursor-pointer"
                    >
                        <p onClick={() => { navigate('/') } }>Cancel</p>
                        <p>Add Story</p>
                        
                        <button className="post border-0" onClick={addStoryData} disabled={disablePost}>
                        Post
                        </button>
                    </div>
                    <div 
                    className="d-flex justify-content-center align-items-center story-container"
                    style={{ background: backgroundColor != '' ? backgroundColor : '#F1FAFB' }}
                    >

                        { type == 'text' ?
                        <textarea
                        placeholder="Add Story"
                        className={`story-text`}
                        rows={20}
                        style={{ color: textColor != '' ? textColor : '#000' }}
                        onChange={e => {
                            setText(e.target.value);
                        }}>
                        
                      </textarea>
                        : '' }


                        
                        <div className="d-flex flex-column choose-type">
                            <CgFormatText onClick={ () => {setType('text'); setStoryFile(null)} } color={type == 'text' ? '#df787b' : '#000' } size={50} />
                            <CiImageOn onClick={ () => {setType('image'); setStoryFile(null)} }  color={type == 'image' ? '#df787b' : '#000' } size={50} />
                            <IoVideocamOutline onClick={ () => {setType('video'); setStoryFile(null)} }  color={type == 'video' ? '#df787b' : '#000' } size={50} />
                        </div>

                        { type == 'text' ?  
                        <div className="d-flex justify-content-center align-items-center color-options flex-wrap">
                          {colorOptions.map( (item,key) => {
                            return (
                              <div 
                              key={key} className="color"
                              style={{ background: item.value }}
                              onClick={ () => {
                                if( textColorType == 'text' ){
                                  setTextColor(item.value)
                                }else{
                                  setBackgroundColor(item.value)
                                }
                              } }
                              >

                              </div>
                            );
                          })}
                        </div>
                        : '' }

                        { type == 'image' || type == 'video' ?  
                        
                        <>
                         {filePreview && type == 'video' ?
                          <div>
                            <video src={filePreview} controls width="350"  className="rounded"/>
                          </div>
                            :
                            ''  
                          }

                          {filePreview && type == 'image' ?
                          <div>
                            <img src={filePreview} alt="Preview" style={{ width: '200px' }} />
                          </div>
                            :
                            ''  
                          }

                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                        
                        {!filePreview ?
                          <img
                            src={type == 'image' ? '/photo.png' : '/upload.png' }
                            alt="Selected"
                            onClick={handleFileClick}
                            style={{ maxWidth: '300px', height: 'auto', cursor: 'pointer' }}
                          />
                          :
                          ''
                        }
                        
                        </>
                        
                        : '' }



                    </div>

                    { type == 'text' ?
                    <div className="px-4 py-3 text-color-type d-flex align-items-center">
                        <p className="text-center"
                        style={{ color:textColorType == 'text' ? '#df787b' : '#b3b3b3' }}
                        onClick={ () => setTextColorType('text') }
                        >
                          Text Color
                        </p>
                        <p className="text-center"
                        style={{ color:textColorType == 'background' ? '#df787b' : '#b3b3b3' }}
                        onClick={ () => setTextColorType('background') }
                        >
                          Background Color
                        </p>
                    </div>
                    : '' }
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AddStory;

import { useState } from 'react';
import MyStories from './MyStories';
import styled from 'styled-components';

const ShowStoriesButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

function MyMainStories() {
    
    const [showStories, setShowStories] = useState(false);
    const [resetStories, setResetStories] = useState(false);

    const handleShowStories = () => {
    setResetStories(true);
    setShowStories(true);
    };

    const handleStoriesEnd = () => {
    setShowStories(false);
    setResetStories(false);
    };

  return (
    <div className="App">
      <ShowStoriesButton onClick={handleShowStories}>Show Stories</ShowStoriesButton>
      <MyStories visible={showStories} onEnd={handleStoriesEnd} resetStories={resetStories} />
    </div>
  );
}

export default MyMainStories;

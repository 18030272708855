import axios from 'axios';

export const TokenInterceptor = async () => {
  axios.interceptors.request.use(async config => {
    if (config.headers) {
      config.headers['authorization'] =
        config.headers['authorization'] ??
        `Bearer ${localStorage.getItem('token')}`;
    }

    return config;
  });

  axios.interceptors.response.use(response => {
    // console.log(`🚀 ~ TokenInterceptor ~ response`, response);
    return response;
  });
};

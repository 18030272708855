import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import TrackingDetailsCard from './TrackingDetailsCard';
import ActivitiesTab from './components/ActivitiesTab';
import RankingTab from '../Tracking/components/RankingTab';
import PersonalityAnalysisCard from '../Quiz/components/PersonalityAnalysisCard';
import ResultProgress from '../Tracking/components/ResultProgress';
import AreaChart from '../Tracking/components/AreaChart';
import {FaCircle} from 'react-icons/fa';
import {
  assignAndStartTrack,
  getTrackingsDetailsAfterEnroll,
  getmemberForAssign,
} from '../Tracking/Trackings.service';
import moment from 'moment';
import {TrackingType} from './interfaces/ITrackings';
import axios from 'axios';
import MainButton from '../../UI/main-button/MainButton';
import Modal from 'react-bootstrap/Modal';
import CancelButton from '../../UI/cancel-button/CancelButton';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import Card from 'react-bootstrap/Card';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {getTrackData, getDataAfterEnroll} from './Initiatives.service';
import InitiativeDetailsCard from './InitiativeDetailsCard';
import {useNavigate} from 'react-router-dom';
import {getDomain} from '../../helpers/getDomain';
import PopoverUi from './components/PopoverUi';
import { getLanguage,setLanguage } from '../../components/navigation-header-v2/getLang';
import {useTranslation} from 'react-i18next';
import { getHistory } from './Trackings.service';
import i18n from 'i18next';
import TrackingCard from './components/TrackingCard';

const TrackingDetailsAfterEnroll = () => {

  const {t} = useTranslation();

  
  

  const [originalLang, setOriginalLang] = useState(getLanguage());

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {id , userId } = useParams();


  const [trackId, setTrackId] = useState(id);
  

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const image = queryParams.get('image')

  const package_price = queryParams.get('package_price') || null

  const package_unlimited_price = queryParams.get('package_unlimited_price') || null

  

  

  
  //const [item, setItem] = useState(location.state.item);
  

  // const start_date = location.state.start_date;
  // const end_date = location.state.end_date;
  // const trackingDetails = location.state.tracking;

  //const userId = item?.selected_enrolled_user_id ?? localStorage.getItem('id');
  
  let token = localStorage.getItem('token');
  const [data, setData] = useState<TrackingType | undefined>();
  const [daysProgress, setDaysProgress] = useState<any>([]);
  const [report, setReport] = useState<any>();

  const [daySelected, setDaySelected] = useState<string | undefined>();

  const [members, setMembers] = useState<any>([]);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [member, setMember] = useState(null);

  const [selectedUsername, setSelectedUsername] = useState(null);

  const [selectedUserAvatar, setSelectedUserAvatar] = useState(null);

  const [selectedUserId, setSelectedUserId] = useState(null);

  
  const [historyList, setHistoryList] = useState<any>([]);

  

  const [  showCardDetails,   setShowCardDetails] = useState(false);


  // Check if a specific query parameter exists
  const hasHistory = queryParams.has('tracking_enrollment_history_id');

  const trackHistory = hasHistory ? queryParams.get('tracking_enrollment_history_id') : null

  const today = moment().locale('en').format('YYYY-MM-DD');

  const [activityStart, setActivityStart] = useState(null);

  

  

  const getAssignedUserVal = (e: any) => {
    console.log(e.target.value);
    setMember(e.target.value);
  };

  const redirectWithNewId = () => {
    setShowAssignUsers(false);
    let url = `/user/trackings/${id}/details/enrolled/${member}?image=${image}`
    if( package_price ){ url += `&package_price=${package_price}` }
    if( package_unlimited_price ){ url += `&package_unlimited_price=${package_unlimited_price}` }
    window.location.replace(url)

    //getReport();
    //getData(null);
  };

  const series = [
    {
      name: 'Network',
      data: daysProgress,
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    fill: {
      opacity: 0.8,
      type: 'pattern',
      pattern: {
        style: ['verticalLines', 'horizontalLines'],
        width: 5,
        height: 6,
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    theme: {
      palette: 'palette1',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: '',
      },
    },
  };

  useEffect(() => {
   
    getReport();
    getData(null);
    
    //return () => {
      //i18n.changeLanguage(originalLang)
      //setLanguage(originalLang)
    //};
  }, []);

  const getData = async (date: string | null) => {
    dispatch(setLoading(true));
    let currentUserId = userId ?? localStorage.getItem('id');
    
    let result = await getTrackData(id, currentUserId , trackHistory );
    console.log('rerewrerere', result.response.data);

    let today = moment().locale('en').format('YYYY-MM-DD');
    let start = moment(result.response.data.start_date).locale('en');
    setActivityStart(result.response.data.start_date)
    let end = moment(result.response.data.end_date).locale('en');
    let check =
      moment(today).isBetween(start, end) || moment(today).isSame(end);

    let theDate = daySelected
      ? daySelected
      : check
      ? moment().locale('en').format('YYYY-MM-DD')
      : moment(result.response.data.start_date)
          .locale('en')
          .format('YYYY-MM-DD');
    console.log('theDate', theDate);
    console.log(
      'result.response.data.start_date',
      result.response.data.start_date,
    );
    console.log('result.response.data.end_date', result.response.data.end_date);

    setDaySelected(date ?? theDate);
    console.log('date', date);

    if (result.response) {
      console.log('track result.response', result.response); 
      getHistoryTracks(null)
      try {
        var selectedId = null;
        if (member) {
          selectedId = member;
        } else {
          selectedId = userId;
        }
        let result = await getDataAfterEnroll(
          id,
          date
            ? date
            : theDate
            ? theDate
            : moment().locale('en').format('YYYY-MM-DD'),
          selectedId,
          trackHistory
        );
        if (result.response) {
          
          /*
          if( result.response.data.lang == 'ar' ){
            i18n.changeLanguage('ar')
            setLanguage('ar')
          }else{
            i18n.changeLanguage('en')
            setLanguage('en')
          }
          */
          
          dispatch(setLoading(false));
          setData(result.response.data);
          setMembers(result.response.data.enrolled_members);
          console.log('selected user name', result.response.data);
          setSelectedUsername(result.response.data.selected_user_name);
          setSelectedUserAvatar(result.response.data.selected_user_avatar);
          setSelectedUserId(result.response.data.selected_enrolled_user_id);
          setMember(result.response.data.selected_enrolled_user_id);
          setShowCardDetails(true)
        }
      } catch (error) {
        // console.log('gugu', error);
      }
      
    }
  };

 

  const getReport = async () => {
    let api = `${getDomain()}/api/trackingsystem/report/${id}?current_user_id=${userId}`;
    if( hasHistory ){
      api += `&tracking_enrollment_history_id=${trackHistory}`
    }

    try {
      let result = await axios({
        url: api,
        method: 'get',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('reports', result?.data?.data);
      if (result.data.status) {
        setReport(result?.data?.data);
        let modfiedArray = [];
        for (const key in result?.data?.data.progress_per_days) {
          const value = result?.data?.data.progress_per_days[key];
          modfiedArray.push({
            x: `${moment(key).format('MMM DD YYYY')}`,
            y: Math.round(value),
          });
        }
        console.log('modfiedArray', modfiedArray);
        setDaysProgress(modfiedArray);
      }
    } catch (error) {}
  };
  

  const StartTrack = async () => {
    dispatch(setLoading(true));
    try {
      let selectedId = null;
      if (member) {
        selectedId = member;
      } else {
        selectedId = userId;
      }

      let data = new FormData();

      data.append('tracking_id', String(id));
      data.append('start_tracking', '1');
      data.append('members[0]', String(selectedId));

      let result = await assignAndStartTrack(data);
      if (result.response) {
        if (result.response.code == 202) {
          console.log('result.response.msg', result.response.msg);
          dispatch(setLoading(false));
        } else {
          setDaySelected(moment().locale('en').format('YYYY-MM-DD'));
          getData(moment().locale('en').format('YYYY-MM-DD'));
        }
      } else dispatch(setLoading(false));
    } catch (e) {
      console.log({e});
    }
  };

  const getHistoryTracks = async (idParam:any) => {
    dispatch(setLoading(true));
    
    let getUserId = localStorage.getItem('id')
    

    let currentUserId = idParam
      ? idParam
      : data?.selected_enrolled_user_id
      ? data?.selected_enrolled_user_id
      : userId
      ? userId
      : getUserId;

     
    try {
      let result = await getHistory(trackId, currentUserId);
      if( result.response ){
        setHistoryList(result.response.data);
        console.log('getHistory', result.response.data);
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      // console.log('gugu', error);
    }
  };

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };

  return (
    <div>
      <img src="/track-bg2.jpg" alt="img" style={{width: '100%'}} />
      <Container>
        <Row className="d-flex justify-content-between mb-5">
          <Col xs={12} className="mt-3 mb-3">
            { showCardDetails && (<TrackingDetailsCard 
            tracking={data!}
            getData={getData}
            setShowAssignUsers={i => {
              setShowAssignUsers(true)
            }}
            />) }
          </Col>

          <Col xs={12} lg={12} className="mt-3 mb-5">
            <h3 className='track-desc-title mb-3'>
              Description
            </h3>
            
            { data?.description && (
            <div className="track-desc-content">
              <div>{showAll ? data?.description! : data?.description!.slice(0, 350)}
              {!showAll && data.description!.length > 350 && <span className='track-see-more cursor-pointer' onClick={handleShowMore}>See More</span>}
              {showAll && <span className='track-see-more cursor-pointer' onClick={handleShowLess}>See Less</span>}
              </div>
            </div>
            ) }
          </Col>

          <Col xs={12}>
            <div className='containers-tab main-tabs'>
              <Tabs
                defaultActiveKey={'activities'}
                id="uncontrolled-tab-example"
                className="custom-web-tabs">
                <Tab eventKey="activities" title={<span>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_87_168)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7321 1.86006C14.3641 1.10206 15.5951 1.62006 15.4951 2.60206L14.7891 9.50006H20.5001C20.6901 9.50013 20.8762 9.55434 21.0365 9.65633C21.1968 9.75833 21.3248 9.90389 21.4054 10.076C21.4859 10.248 21.5158 10.4395 21.4915 10.628C21.4672 10.8164 21.3897 10.9941 21.2681 11.1401L11.2681 23.1401C10.6361 23.8981 9.40511 23.3801 9.50511 22.3981L10.2111 15.5001H4.50011C4.3101 15.5 4.12404 15.4458 3.96372 15.3438C3.8034 15.2418 3.67545 15.0962 3.59487 14.9242C3.51429 14.7521 3.4844 14.5606 3.5087 14.3721C3.53301 14.1837 3.61051 14.0061 3.73211 13.8601L13.7321 1.86006ZM6.63511 13.5001H11.9831C12.039 13.5001 12.0943 13.5118 12.1454 13.5345C12.1965 13.5571 12.2423 13.5903 12.2798 13.6317C12.3173 13.6732 12.3457 13.722 12.3631 13.7752C12.3806 13.8283 12.3867 13.8844 12.3811 13.9401L11.8281 19.3451L18.3651 11.5001H13.0171C12.9612 11.5001 12.9059 11.4883 12.8548 11.4657C12.8037 11.443 12.758 11.4098 12.7205 11.3684C12.683 11.3269 12.6546 11.2781 12.6371 11.225C12.6197 11.1719 12.6135 11.1157 12.6191 11.0601L13.1721 5.65606L6.63511 13.5001Z" fill="#FAFAFA"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_87_168">
                      <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    {t('Activities')}</span>}>
                { data?.start_date && (
                  <ActivitiesTab
                    tracking={{
                      ...data!,
                      tracking_enrollment_history_id:trackHistory
                    }}
                    daySelected={daySelected}
                    userId={userId!}
                    setDaySelected={i => {
                      getData(i);
                      setDaySelected(i);
                    }}
                  />
                )}
                </Tab>

                <Tab eventKey="ranking" title={<span>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.7" clip-path="url(#clip0_87_162)">
                      <path d="M16.0001 7.50002C16.245 7.50005 16.4814 7.58998 16.6644 7.75274C16.8475 7.91549 16.9644 8.13977 16.9931 8.38302L17.0001 8.50002V18.121L18.8281 16.293C19.0076 16.1118 19.2496 16.006 19.5046 15.9973C19.7596 15.9887 20.0082 16.0778 20.1996 16.2465C20.391 16.4152 20.5108 16.6506 20.5343 16.9046C20.5578 17.1587 20.4833 17.4121 20.3261 17.613L20.2421 17.707L16.7071 21.243C16.5349 21.4152 16.3058 21.5186 16.0627 21.5339C15.8197 21.5492 15.5795 21.4753 15.3871 21.326L15.2931 21.243L11.7571 17.707C11.5777 17.5271 11.4736 17.2856 11.4658 17.0316C11.4581 16.7777 11.5473 16.5303 11.7153 16.3397C11.8833 16.1492 12.1176 16.0297 12.3705 16.0056C12.6235 15.9815 12.8761 16.0546 13.0771 16.21L13.1721 16.293L15.0001 18.12V8.50002C15.0001 8.2348 15.1054 7.98045 15.2929 7.79291C15.4805 7.60538 15.7348 7.50002 16.0001 7.50002ZM8.70705 3.75702L12.2421 7.29302C12.3376 7.38527 12.4137 7.49561 12.4662 7.61762C12.5186 7.73962 12.5462 7.87084 12.5473 8.00362C12.5485 8.1364 12.5232 8.26808 12.4729 8.39097C12.4226 8.51387 12.3483 8.62552 12.2544 8.71941C12.1606 8.81331 12.0489 8.88756 11.926 8.93784C11.8031 8.98812 11.6714 9.01342 11.5387 9.01227C11.4059 9.01112 11.2747 8.98353 11.1526 8.93112C11.0306 8.87871 10.9203 8.80253 10.8281 8.70702L9.00005 6.87902V16.499C9.00005 16.7642 8.8947 17.0186 8.70716 17.2061C8.51962 17.3937 8.26527 17.499 8.00005 17.499C7.73484 17.499 7.48048 17.3937 7.29295 17.2061C7.10541 17.0186 7.00005 16.7642 7.00005 16.499V6.88002L5.17005 8.70702C4.98145 8.88918 4.72885 8.98997 4.46665 8.98769C4.20446 8.98542 3.95364 8.88025 3.76823 8.69484C3.58283 8.50943 3.47766 8.25862 3.47538 7.99642C3.4731 7.73422 3.5739 7.48162 3.75605 7.29302L7.29205 3.75702C7.47958 3.56955 7.73389 3.46423 7.99905 3.46423C8.26422 3.46423 8.51953 3.56955 8.70705 3.75702Z" fill="black"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_87_162">
                      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                      </svg>
                    {t('Ranking')}</span>}>
                  <RankingTab userId={userId} trackType='tracking' />
                </Tab>

                { activityStart && (
                <Tab eventKey="reports" 
                title={<span>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.7" clip-path="url(#clip0_87_156)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.5C5 5.23478 4.89464 4.98043 4.70711 4.79289C4.51957 4.60536 4.26522 4.5 4 4.5C3.73478 4.5 3.48043 4.60536 3.29289 4.79289C3.10536 4.98043 3 5.23478 3 5.5V19.5C3 19.7652 3.10536 20.0196 3.29289 20.2071C3.48043 20.3946 3.73478 20.5 4 20.5H20C20.2652 20.5 20.5196 20.3946 20.7071 20.2071C20.8946 20.0196 21 19.7652 21 19.5C21 19.2348 20.8946 18.9804 20.7071 18.7929C20.5196 18.6054 20.2652 18.5 20 18.5H5V5.5ZM9 12.5C9 12.2348 8.89464 11.9804 8.70711 11.7929C8.51957 11.6054 8.26522 11.5 8 11.5C7.73478 11.5 7.48043 11.6054 7.29289 11.7929C7.10536 11.9804 7 12.2348 7 12.5V15.5C7 15.7652 7.10536 16.0196 7.29289 16.2071C7.48043 16.3946 7.73478 16.5 8 16.5C8.26522 16.5 8.51957 16.3946 8.70711 16.2071C8.89464 16.0196 9 15.7652 9 15.5V12.5ZM12 6.5C12.2652 6.5 12.5196 6.60536 12.7071 6.79289C12.8946 6.98043 13 7.23478 13 7.5V15.5C13 15.7652 12.8946 16.0196 12.7071 16.2071C12.5196 16.3946 12.2652 16.5 12 16.5C11.7348 16.5 11.4804 16.3946 11.2929 16.2071C11.1054 16.0196 11 15.7652 11 15.5V7.5C11 7.23478 11.1054 6.98043 11.2929 6.79289C11.4804 6.60536 11.7348 6.5 12 6.5ZM17 10.5C17 10.2348 16.8946 9.98043 16.7071 9.79289C16.5196 9.60536 16.2652 9.5 16 9.5C15.7348 9.5 15.4804 9.60536 15.2929 9.79289C15.1054 9.98043 15 10.2348 15 10.5V15.5C15 15.7652 15.1054 16.0196 15.2929 16.2071C15.4804 16.3946 15.7348 16.5 16 16.5C16.2652 16.5 16.5196 16.3946 16.7071 16.2071C16.8946 16.0196 17 15.7652 17 15.5V10.5Z" fill="black"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_87_156">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                  </defs>
                  </svg>

                    {t('Reports')}</span>}
                disabled={data?.has_started_tracking == 0}>
                  <Row>
                    <Col sm="6">
                      <ResultProgress
                        report={report?.completed.toFixed(1)}
                        title={t('Completed')}
                        color={'#39b970'}
                        backColor={'#39b970'}
                      />
                    </Col>

                    <Col sm="6">
                      <ResultProgress
                        report={report?.remaining.toFixed(1)}
                        title={t('Remaining')}
                        color={'red'}
                        backColor={'#0576e3'}
                      />
                    </Col>

                    <Col md="12">
                      {daysProgress.length > 0 ? (
                        <Card className="text-center main-card">
                          <Card.Body className="mb-4 mt-3">
                            <Chart
                              options={options}
                              series={series}
                              type="area"
                              height={350}
                            />
                          </Card.Body>
                        </Card>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Tab>
                ) }

                <Tab eventKey="about" title={<span>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.7" clip-path="url(#clip0_87_150)">
                    <path d="M9.99984 2.16663C14.6023 2.16663 18.3332 5.89746 18.3332 10.5C18.3332 15.1025 14.6023 18.8333 9.99984 18.8333C5.39734 18.8333 1.6665 15.1025 1.6665 10.5C1.6665 5.89746 5.39734 2.16663 9.99984 2.16663ZM9.99984 3.83329C8.23173 3.83329 6.53603 4.53567 5.28579 5.78591C4.03555 7.03616 3.33317 8.73185 3.33317 10.5C3.33317 12.2681 4.03555 13.9638 5.28579 15.214C6.53603 16.4642 8.23173 17.1666 9.99984 17.1666C11.7679 17.1666 13.4636 16.4642 14.7139 15.214C15.9641 13.9638 16.6665 12.2681 16.6665 10.5C16.6665 8.73185 15.9641 7.03616 14.7139 5.78591C13.4636 4.53567 11.7679 3.83329 9.99984 3.83329ZM9.9915 8.83329C10.4565 8.83329 10.8332 9.20996 10.8332 9.67496V13.945C10.992 14.0367 11.1162 14.1783 11.1864 14.3477C11.2566 14.5172 11.2689 14.7051 11.2214 14.8823C11.1739 15.0595 11.0693 15.2161 10.9238 15.3277C10.7783 15.4394 10.5999 15.4999 10.4165 15.5H10.0082C9.89764 15.5 9.78819 15.4782 9.68608 15.4359C9.58396 15.3936 9.49118 15.3316 9.41302 15.2534C9.33487 15.1753 9.27287 15.0825 9.23057 14.9804C9.18827 14.8783 9.1665 14.7688 9.1665 14.6583V10.5C8.94549 10.5 8.73353 10.4122 8.57725 10.2559C8.42097 10.0996 8.33317 9.88764 8.33317 9.66662C8.33317 9.44561 8.42097 9.23365 8.57725 9.07737C8.73353 8.92109 8.94549 8.83329 9.1665 8.83329H9.9915ZM9.99984 6.33329C10.2208 6.33329 10.4328 6.42109 10.5891 6.57737C10.7454 6.73365 10.8332 6.94561 10.8332 7.16663C10.8332 7.38764 10.7454 7.5996 10.5891 7.75588C10.4328 7.91216 10.2208 7.99996 9.99984 7.99996C9.77882 7.99996 9.56686 7.91216 9.41058 7.75588C9.2543 7.5996 9.1665 7.38764 9.1665 7.16663C9.1665 6.94561 9.2543 6.73365 9.41058 6.57737C9.56686 6.42109 9.77882 6.33329 9.99984 6.33329Z" fill="#4F4F4F"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_87_150">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                    </defs>
                    </svg>

                    {t('About')}</span>}>
                  <div className='mb-4'>
                    <h4>{t('About System')}</h4>
                    <p className={`text-font-muted ${ data?.lang == 'ar' ? 'ar-dir' : '' }`}>{data?.description}</p>
                    
                  </div>

                  <div>
                    <h4>{t('Provided By')}</h4>
                    <p className={`text-font-muted ${ data?.lang == 'ar' ? 'ar-dir' : '' }`}>{data?.provider_name}</p>
                  </div>

                  <div>
                    <h4>{t('Fields')}</h4>

                    {data?.fields &&
                      data?.fields.map((d: any, i: number) => (
                        <>
                          <p className={`${ data?.lang == 'ar' ? 'ar-dir' : '' }`} style={{margin: '10px 0 5px'}}>
                            <FaCircle color="fb5456" />
                            <span style={{margin: '0 10px'}}>
                              {d.name ?? ''}
                            </span>
                          </p>

                          {d.goals && (
                            <div className="goals" style={{marginLeft: '20px'}}>
                              {d.goals.map((goal: any, index: number) => {
                                return (
                                  <div style={{margin: '0 10px'}} className={`${ data?.lang == 'ar' ? 'ar-dir' : '' }`}>
                                    <FaCircle color="#9f9f9f" size={12} />
                                    <span style={{margin: '0 10px'}}>
                                      {goal.name ?? ''}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </Tab>

                { !hasHistory && data ?               
                <Tab eventKey="history" title={t('History')}>
                {historyList.map((track: TrackingType) => {
                  
                  return (
                    <Col lg={4} className='p-0'>
                            <TrackingCard
                              tracking={{
                                ...track,
                                
                                name: data?.name!,
                                image: image!,
                                level_count: data?.level_count,
                                provider_name: data?.provider_name!,
                                short_description: data?.short_description!,
                                enrolled_members: [],
                                lang: data?.lang,
                                selected_enrolled_user_id: data?.selected_enrolled_user_id,
                                price: data?.price!,
                                user_currency: data?.user_currency!
                              }}
                              isPackage={track.tracking_type == 'multi'}
                              enrolled={true}
                            />
                          </Col>
                        );
                      })}
                </Tab>
                : '' }
              </Tabs>
            </div>

            {data?.has_started_tracking == 0 && (
              <MainButton
                text="Start Now"
                style={{marginTop: 30}}
                onClick={() => {
                  StartTrack();
                }}
              />
            )}

          </Col>
        </Row>
      </Container>

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className={classes.AuctionType}>
            <ul className="relative mb-3 w-full flex flex-wrap items-stretch justify-center p-0 hideScroll">
              {members.map((e: any, i: any) => (
                <li className="text-center" key={i}>
                  <input
                    type="radio"
                    name="test"
                    id={`name-${i}`}
                    value={e.id}
                    onChange={getAssignedUserVal}
                    disabled={e?.id == data?.selected_enrolled_user_id}
                    checked={e?.id == member}
                  />

                  <label htmlFor={`name-${i}`}>
                    <div>
                      <img
                        src={e.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '50px',
                          height: '50px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                    <h6>{e.username}</h6>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={t('Switch Now')}
              disabled={member == data?.selected_enrolled_user_id}
              onClick={() => {
                redirectWithNewId();
              }}
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text={t('Cancel')} />
          </div>
        </Modal.Footer>
      </Modal>

      
    </div>
  );
};

export default TrackingDetailsAfterEnroll;

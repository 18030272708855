import {Col, Container, Row} from 'react-bootstrap';
import TrackingCard from './components/TrackingCard';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getTracks} from './Trackings.service';
import {setLoading} from '../../store/userSlice';
import {useDispatch} from 'react-redux';
import {TrackingType} from './interfaces/ITrackings';
import ItemsPagination from '../../UI/pagination/ItemsPagination';

const Tracks = () => {
  const location = useLocation().state;
  const dispatch = useDispatch();
  const [trackings, setTrackings] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    //setPage(page)
    dispatch(setLoading(true));
    let result = await getTracks(location.item.id, page, location.tab);
    if (result.response) {
      setTrackings(result.response.data);
      SetLastPage(result.response.meta.last_page);
    }
    dispatch(setLoading(false));
  };

  return (
    <Container>
      <h1 className="page-title" style={{margin: '0 1rem'}}>
        Package Levels
      </h1>

      <Row>
        {trackings.map((track: TrackingType) => {
          return (
            <Col lg={6} className="d-flex">
              <TrackingCard tracking={track} />
            </Col>
          );
        })}
      </Row>
      <ItemsPagination
        pages={page}
        current={lastPage}
        changePage={i => setPage(i)}
      />
    </Container>
  );
};

export default Tracks;

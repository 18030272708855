import {APIURL} from '../../services/http/api';
import {HttpService} from '../../services/http/http.service';
import {EApiParams} from '../../services/http/apiParams.enum';
import {urlParamReplace} from '../../helpers/UrlParamReplace';

const http = new HttpService();


export const getHomeModules = async (lang: number | string , time_zone:string ) => {
  const api = APIURL.Home.getHomeModules;
  const finalApi = urlParamReplace(api, {[EApiParams.lang]: lang , [EApiParams.time_zone]: time_zone  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};
import {Container} from 'react-bootstrap';
import classes from './style/Survey.module.css';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {
  Questionnaire,
  SubmittedQuestionnaire,
} from '../Consultants/interfaces/IQuestionnaire';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {SubmitSurvey, getSurvey} from './Survey.services';
import Select from './components/Select';
import MultiSelect from './components/MultiSelect';
import Essay from './components/Essay';
import {NavLink} from 'react-router-dom';

const Survey = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState<Questionnaire>();
  const [submited, setSubmited] = useState<SubmittedQuestionnaire[]>([]);
  const [surveyId, setSurveyId] = useState(null);

  const {id} = useParams();

  useEffect(() => {
    dispatch(setLoading(true));
  }, []);

  const getData = async (id: any) => {
    let result = await getSurvey(id);
    console.log('resultresult2', result);
    if (result?.response?.data !== null) {
      console.log('Questionnare', result?.response?.data);
      setSurveyId(result?.response?.data?.id);
      let surveyData = result?.response?.data;

      let arr = [];
      for (let i = 0; i < surveyData?.questionnaire_questions?.length; i++) {
        arr.push({
          id: surveyData?.questionnaire_questions[i]?.id,
          question: surveyData?.questionnaire_questions[i]?.question,
          answer: [],
          type: surveyData?.questionnaire_questions[i]?.type,
          listAnswers: surveyData?.questionnaire_questions[i]?.options,
        });
      }
      setSubmited(arr);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData(id!);
  }, []);

  const Submit = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append(`questionnaire_id`, String(surveyId));
      let k = -1;
      submited!
        .filter(item => item.type == 'select' && item.answer)
        .map((i, key) => {
          k = ++k;
          data.append(`answers[${k}][questionnaire_question_id]`, String(i.id));
          data.append(`answers[${k}][question_option_id][0]`, String(i.answer));
        });
      submited!
        .filter(item => item.type == 'multi select' && item?.answer)
        .map((i, key) => {
          k = ++k;
          data.append(`answers[${k}][questionnaire_question_id]`, String(i.id));
          if (Array.isArray(i.answer)) {
            i?.answer?.map((item: number, key: number) => {
              data.append(
                `answers[${k}][question_option_id][${key}]`,
                String(item),
              );
            });
          }
        });
      submited!
        .filter(item => item.type == 'essay' && item.answer)
        .map((i, key) => {
          k = ++k;
          data.append(`answers[${k}][questionnaire_question_id]`, String(i.id));
          data.append(`answers[${k}][answer]`, String(i.answer));
        });

      let result = await SubmitSurvey(data);

      if (result.response.code == 200) {
        dispatch(setLoading(false));

        navigate('/show/user/profile');
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };
  return (
    <Container className="mb-5">
      <div className={`section ${classes.SurveyTest}`}>
        <div className="QuestionContainer mb-5">
          {submited?.map((i, index) => {
            return (
              <>
                <div>
                  <h3 className="mb-4">
                    {index + 1}- {i.question}
                  </h3>
                </div>

                {i.type == 'select' && (
                  <div className={classes.Questions}>
                    <Select
                      allData={submited}
                      index={index}
                      options={i.listAnswers}
                      selected={(i: string | number | number[]) => {
                        let arr = [...submited];
                        arr[index].answer = i;
                        setSubmited(arr);
                      }}
                    />
                  </div>
                )}
                {i.type == 'multi select' && (
                  <div className={classes.Questions}>
                    <MultiSelect
                      allData={submited}
                      index={index}
                      options={i.listAnswers}
                      selected={(item: string | number | number[]) => {
                        let arr: any[] = [...submited];
                        let answers: any[] | number = [...arr[index].answer];
                        if (answers.includes(item)) {
                          answers = answers.filter(i => i != item);
                        } else {
                          answers.push(item);
                        }
                        arr[index].answer = answers;
                        setSubmited(arr);
                      }}
                    />
                  </div>
                )}
                {i.type == 'essay' && (
                  <div className={classes.Questions}>
                    <Essay
                      selected={(i: string | number | number[]) => {
                        let arr = [...submited];
                        arr[index].answer = i;
                        setSubmited(arr);
                      }}
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>

      <div className="mb-3">
        <MainButton text={'Submit Result'} onClick={Submit} />
      </div>

      <NavLink to={'/show/user/profile'}>
        <CancelButton text="Skip" />
      </NavLink>
    </Container>
  );
};

export default Survey;

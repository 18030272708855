import {Fragment, useEffect, useState} from 'react';
import '../../Courses/style/Courses.css';
import {useParams} from 'react-router-dom';
import {getConsultantCoursesList} from '../Consultants.service';
import CourseItem from '../../Courses/components/CourseItem';
import ItemsPagination from '../../../UI/pagination/ItemsPagination';
import {useAppDispatch} from '../../../hooks/hooks';
import {setLoading} from '../../../store/userSlice';


const ConsultantProfileCourses = ({id}:any) => {
  
  const dispatch = useAppDispatch();
  const [courses, setCourses] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    dispatch(setLoading(true));

    let result = await getConsultantCoursesList(page, id!);

    if (result.response) {
      setCourses(result.response.data);
      SetLastPage(result.response.meta.lastPage);
    }

    dispatch(setLoading(false));
  };

  return (
    <Fragment>
      <div className="courses-list">
        {courses.map(course => {
          return <CourseItem course={course} />;
        })}
      </div>
      {courses.length !== 0 && (
        <div className="pagination-container">
          <ItemsPagination
            pages={lastPage}
            current={page}
            changePage={page => {
              setPage(page);
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
export default ConsultantProfileCourses;

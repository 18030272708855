import {FaUsers} from 'react-icons/fa';
import './SourceViews.css';

function SourceViews({numbers}) {
  return (
    <div className="course-views d-flex align-items-center">
      <FaUsers size={25} className="course-views-icon" /> {numbers}
    </div>
  );
}

export default SourceViews;

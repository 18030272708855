import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {useState} from 'react';

// import './TimePicker.css';

const TimePickerContainer = ({timeSelected, closed}) => {
  function handleChange(value) {
    console.log('value' , value )
    setChossenTime(value)
    timeSelected(value.unix);
  }

  // Set the default date and time
  const defaultDate = new Date();
  defaultDate.setHours(12); // Set the desired default hour
  defaultDate.setMinutes(0); // Set the desired default minute
  defaultDate.setSeconds(0); // Set the desired default minute

  const [chossenTime, setChossenTime] = useState(defaultDate);


  return (
    <div className='d-flex'>
    <DatePicker
    //format="MM/DD/YYYY HH:mm" // Adjust the format to include both date and time
    
      value={chossenTime}
      disableDayPicker
      placeholder="Select Time"
      onChange={handleChange}
      format="hh:mm A"
      plugins={[<TimePicker
      onKeyDown={ (e) => { e.preventDefault() } }
      onKeyUp={ (e) => { e.preventDefault() } }
      hideSeconds 
      mStep={30}
      />]}
      inputClass="custom-input"
      onClose={() => {
        closed();
      }}
    />
    <button onClick={ () => {
      closed();

    } } className='submit-date'>Submit</button>
    </div>
  );
};

export default TimePickerContainer;

import './style/Consultants.css';
import {Container, Row, Col} from 'react-bootstrap';
import ConsultantFilter from './components/ConsultantFilter';
import ConsultantsList from './components/ConsultantsList';
import {Fragment, useEffect, useState} from 'react';
import logo from './style/header.png';
import {getConsultantsList} from './Consultants.service';
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import { isUserLoggedIn } from '../../auth/utiles';
import { useTranslation} from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Consultants = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let type =  'consultations';
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [name, setName] = useState<string>('');
  const [languagesSelected, setLanguagesSelected] = useState<number[]>([]);
  const [fieldsSelected, setFieldsSelected] = useState<{[key: string]: any[]}>(
    {},
  );
  const [selectedDays, setSelectedDays] = useState<any[] | null>([]);
  const [selectedTime, setSelectedTime] = useState<number | null>();
  const [typesSelected, setTypesSelected] = useState<string[]>([]);
  

  useEffect(() => {

    const Logged = isUserLoggedIn();
    if( Logged ){
      getConsultants();
    }else{
      navigate(`/login`, {state: {location: location.pathname }})
    }
  }, [
    page,
    name,
    type,
    languagesSelected,
    fieldsSelected,
    selectedTime,
    selectedDays,
    typesSelected,
  ]);

  const getConsultants = () => {
    getConsultantsList({
      page: page,
      load: false,
      type:
        typesSelected.length != 0
          ? typesSelected
          : type == 'teachers'
          ? ['teacher']
          : ['consultant', 'organization' , 'teacher'],
      name: name,
      language: languagesSelected,
      fields: fieldsSelected,
      selectedDays: selectedDays,
      selectedTime: selectedTime,
    });
  };
  return (
    <Fragment>
      <div className="header">
        <Container>
          <Row className="d-flex justify-content-between">
            <Col xs={6} className="d-flex">
              <div className="breadcrumb d-flex justify-content-center">
                <h3 className="breadcrumb-header">
                {type == 'teachers' ? t('Teachers') : t('Consultations')}
                </h3>
                <div className="breadcrumb-details">
                  <NavLink to={'/'}><span>{t('Home')}</span></NavLink> <span> - </span>{' '}
                  <span className="active-page">
                    {' '}
                    {type == 'teachers' ? t('Teachers') : t('Consultations')}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={6}>
              <img src={logo} alt="courses" className='top-head-consultant' />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="content-details">
        <Container>
          <Row className="d-flex justify-content-between">
            <ConsultantFilter
              type={type}
              setLang={setLanguagesSelected}
              setField={setFieldsSelected}
              setDays={setSelectedDays}
              setTimes={setSelectedTime}
              setTypes={setTypesSelected}
            />

            <Col xs={12} md={9}>
              <ConsultantsList
                setPage={page => setPage(page)}
                page={page}
                submitTitle={name => {
                  setName(name);
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Consultants;

import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import APIURL from './env-url';
import {to} from 'await-to-js';
import {
  BaseUrlInterceptor,
  ErrorHandlingInterceptor,
  TokenInterceptor,
} from './interceptors';
import { getDomain } from '../../helpers/getDomain';
/**
 * Manipulate the HTTP requests for the whole app
 * handle the main POST, GET, UPDATE, DELETE methods
 */
export class HttpService {
  private readonly baseUrl = getDomain();
  constructor() {
    this.interceptors();
  }
  /**
   * Initialize axios interceptors
   *
   * @memberof HttpService
   */
  interceptors() {
    BaseUrlInterceptor(this.baseUrl);
    TokenInterceptor();
    ErrorHandlingInterceptor();
  }
  /**
   * @description Post request
   * @param url - the end point url
   * @param data - request payload
   * @param headers - request headers
   * @return Promise of response, comes from the end point
   */
  async post(
    url: string,
    data: any,
    config?: AxiosRequestConfig<any> | undefined,
  ): Promise<any> {
    return await to(axios.post(url, data, config));
  }
  /**
   * @description Get request
   * @param url - the end point url
   * @return Promise of response, comes from the end point
   */
  async get(
    url: string,
    config?: AxiosRequestConfig<any> | undefined,
  ): Promise<any> {
    return await to(axios.get(url, config));
  }
  /**
   * @description GET request as a promise
   * @param  {string} url
   * @param  {AxiosRequestConfig<any>|undefined} config?
   * @returns Promise
   */
  getPromise(url: string, config?: AxiosRequestConfig<any> | undefined) {
    return axios.get(url, config);
  }
  /**
   * @description PUT request
   * @param url - the end point url
   * @param data - request payload
   * @return Promise of response, comes from the end point
   */
  async put(url: string, data?: any): Promise<any> {
    return await to(axios.put(url, data));
  }
  /**
   * @description PATCH request
   * @param url - the end point url
   * @param data - request payload
   * @return Promise of response, comes from the end point
   */
  async patch(url: string, data?: any): Promise<any> {
    return await to(axios.patch(url, data));
  }
  /**
   * @description DELETE request
   * you can bass a parameter (data) in the url separated by '/'
   * @param url - the end point url
   * @param id - request payload
   * @return Promise of response, comes from the end point
   */
  async delete(url: string, id?: string): Promise<any> {
    id = id ? `/${id}` : '';
    return await to(axios.delete(`${url}${id}`));
  }
}
